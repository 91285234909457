/**
 * ユーザーログ関連の処理です。
 * @class userLog.ts
 * @constructor
 */

import { getFirebaseUserInfo } from "../firebase/firebase";
import { getUnixTime } from "./util";
import { postRequest } from "./axiosAction";

// ユーザーログの型
interface USERLOG {
  access_time_unixtime: number;
  application_name: string;
  user_name: string;
  function_name: string;
  content: string;
  device: string;
}

export const userLogging = async (
  applicationName: string = "",
  functionName: string = "",
  content: string = "",
): Promise<void> => {
  if (getFirebaseUserInfo().uid === undefined) {
    console.log("Not logged in");
    return;
  }
  const usersLog: USERLOG = {
    access_time_unixtime: getUnixTime(),
    application_name: applicationName,
    user_name: getFirebaseUserInfo().uid,
    function_name: functionName,
    content,
    device,
  };
  const url: string =
    process.env.REACT_APP_API_URL + "common-buiness-accesslog/client";
  await postRequest(url, usersLog, false);
};

const setDeviceType = (): string => {
  const ua = navigator.userAgent;
  const loerUa = navigator.userAgent.toLowerCase();
  if (ua.indexOf("iPhone") > -1) {
    return "iphone";
  } else if (ua.indexOf("Android") > -1 && ua.indexOf("Mobile") > -1) {
    return "android";
  } else if (ua.indexOf("iPad") > -1) {
    return "ipad";
  } else if (/ipad|macintosh/.test(loerUa) && "ontouchend" in document) {
    return "ipad";
  } else if (ua.indexOf("Android") > -1) {
    return "android";
  } else {
    return "pc";
  }
};

// デバイス種別を取得する
const device = setDeviceType();
