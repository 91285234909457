import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectZoInfoList } from "../SummarySearchSlice";

import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getAllDo,
  getDo,
  getAllZone,
  getZone,
  getAllExistingSejStores,
  getExistingSejStore,
} from "../../../../data/existingSejStores";

// ZONE別の型
export interface ZONE_RECORD {
  // 各アプリケーション共通項目
  zone_code: string;
  zone_name: string;
}

// ZONEの型のオブジェクトの集まり
export interface INTERFACE_ZONE_DATA {
  [index: string]: ZONE_RECORD;
}

// ZONEの型
export interface INTERFACE_ZONE {
  zone_data: INTERFACE_ZONE_DATA;
  count: number;
}

// DOの初期値
const initialZone: INTERFACE_ZONE = {
  // TODO dummy data
  // zone_data: {},
  zone_data: {
    "001": { zone_code: "001", zone_name: "北海道" },
    "004": { zone_code: "004", zone_name: "東北" },
    "007": { zone_code: "007", zone_name: "福島・茨城" },
    "010": { zone_code: "010", zone_name: "新潟・北陸" },
    "012": { zone_code: "012", zone_name: "群馬・栃木" },
    "013": { zone_code: "013", zone_name: "埼玉" },
    "036": { zone_code: "036", zone_name: "兵庫・京滋・奈良" },
    "045": { zone_code: "045", zone_name: "鉄道・法人" },
  },
  count: 0,
};

const zoneData = {
  // ZONE
  zone: initialZone,
};

// ZONE情報の全量の情報を返す
// export const getAllZone = () => {
//   return zoneData.zone;
// };

// // zoneCodeのあるZONEのデータを返す
// export const getZone = (zoneCode: string): ZONE_RECORD => {
//   return zoneData.zone.zone_data[zoneCode];
// };

const PaperDiv = styled("div")<{ props?: any }>(({ props }) => props.paper);
const ListPaperDiv = styled("div")<{ props?: any }>(
  ({ props }) => props.listPaper,
);
const SearchButton = styled("button")<{ props?: any }>(
  ({ props }) => props.searchButton,
);
const SelectButtonDiv = styled("div")<{ props?: any }>(
  ({ props }) => props.selectButton,
);

const SearchZO: React.FC<{
  styles: any;
  disabled: boolean;
  firstView: boolean;
  toggleStatus: boolean;
  searchMode: number;
  onClickFunc: any;
  deleteFunc: any;
}> = ({
  styles,
  disabled,
  firstView,
  toggleStatus,
  searchMode,
  onClickFunc,
  deleteFunc,
}) => {
  const [open, setOpen] = React.useState(false);
  // 検索文字列
  const [searchWord, setSearchWord] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // TODO ユーザ権限実装時に使用
  // const user = useSelector(selectUser);
  const user = { zoCode: ["001", "004", "007"] };
  const selectZoList = useSelector(selectZoInfoList);

  const MAX_SEARCH_LIST = 150;
  const zoMaster = getAllZone().zone_data;

  const [zoList, setZoList] = React.useState([""]);
  // 検索文字列が更新された際に動く
  useEffect(() => {
    // TODO ユーザ権限実装時に使用
    // ボタンのリストを更新
    // if (getAuthTenpoCheck(user.jobCategory).search < onlyAll) {
    //   setZoList(userZoButton());
    // }
    // else {
    //   setZoList(allZoButton());
    // }

    setZoList(allZoButton());
  }, [searchWord]);

  function userZoButton() {
    let zoList: any[] = [""];
    let zoCode: any = "";
    let zoName: any = "";
    let hitCount: number = 0;

    for (let i = 0; i < user.zoCode.length; i++) {
      zoCode = user.zoCode[i];
      if (zoCode === "") return zoList;
      zoName = zoMaster[user.zoCode[i]].zone_name;
      // 検索文字列にマッチしたものだけリストに入れる
      if (zoCode.match(searchWord) || zoName.match(searchWord)) {
        zoList.push(
          <Button
            key={i}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => {
              onClickFunc(user.zoCode[i], zoMaster[user.zoCode[i]].zone_name);
              setOpen(false);
            }}
          >
            <SelectButtonDiv props={styles}>
              {user.zoCode[i]}：{zoMaster[user.zoCode[i]].zone_name}
            </SelectButtonDiv>
          </Button>,
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    return zoList;
  }

  function allZoButton() {
    let zoList: any[] = [""];
    let hitCount: number = 0;
    for (let key in zoMaster) {
      if (key === "undefined" || searchWord === "") {
        zoList.push(
          <Button
            key={key}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => {
              onClickFunc(zoMaster[key].zone_code, zoMaster[key].zone_name);
              setOpen(false);
            }}
          >
            <SelectButtonDiv props={styles}>
              {zoMaster[key].zone_code}：{zoMaster[key].zone_name}
            </SelectButtonDiv>
          </Button>,
        );
      }
      // 検索文字列にマッチしたものだけリストに入れる
      else if (
        zoMaster[key].zone_code.match(searchWord) ||
        zoMaster[key].zone_name.match(searchWord)
      ) {
        zoList.push(
          <Button
            key={key}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => {
              onClickFunc(zoMaster[key].zone_code, zoMaster[key].zone_name);
              setOpen(false);
            }}
          >
            <SelectButtonDiv props={styles}>
              {zoMaster[key].zone_code}：{zoMaster[key].zone_name}
            </SelectButtonDiv>
          </Button>,
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    return zoList;
  }

  return (
    <>
      <SearchButton
        onClick={() => {
          handleOpen();
          onClickFunc("", "");
        }}
        disabled={disabled}
        style={{ WebkitAppearance: "none" }}
        props={styles}
      >
        <SelectButtonDiv props={styles}>{"ＺＯ名"}</SelectButtonDiv>
      </SearchButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <PaperDiv props={styles}>
          <TextField
            sx={styles.searchTextField}
            value={searchWord}
            label="ＺＯコード、ＺＯ名で探す"
            variant="outlined"
            size="medium"
            fullWidth={true}
            disabled={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length <= 100) {
                setSearchWord(e.target.value);
              }
            }}
          />
          <ListPaperDiv props={styles}>{zoList}</ListPaperDiv>
        </PaperDiv>
      </Modal>
      {/* <List dense={dense}> */}
      <List sx={styles.selectList}>
        {selectZoList.map((value: any, index: number) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  deleteFunc(value.zoCode, value.zoName);
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText primary={value.zoName} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default SearchZO;
