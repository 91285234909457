import React, { useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Typography from "@mui/material/Typography";

import { Grid, Button, Radio, FormControlLabel } from "@mui/material";
import { CircularProgress } from "@mui/material";
import Header from "../common/Header";
import { timer } from "../common/utils";
import { apiRequest } from "../../apis/util";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserInfo, setUserInfo } from "../../data/userInfo";
import InfoIcon from "@mui/icons-material/Info";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import APIErrorModal from "../common/APIErrorModal";
import { useDispatch, useSelector } from "react-redux";
import { resetInputCheckReqestInfo } from "../organisms/search/SummarySearchSlice";
import { selectUser, setUserAuth } from "../../features/userSlice";

/* ---------------------------------------------------------------------------------- */

const setVhVariable = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};
window.addEventListener("resize", setVhVariable);
window.addEventListener("load", setVhVariable);

const circularProgress = css`
  position: absolute;
  top: 35%;
  left: 45%;
  opacity: 0.8;
  color: #ccc;
  // opacity: 0.5;
`;
const titleArea = css`
  height: calc(var(--vh, 1vh) * 5);
  width: 95vw;
`;
const title = css`
  margin: 5px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
`;

const contentsAreaAll = css`
  height: calc(var(--vh, 1vh) * 78);
  width: 90vw;
  overflow: hidden;
`;

const button = css`
  width: 500px;
  height: 80px;
  display: block;
  margin: 20px;
  font-size: 200%;
  font-weight: 600;
  justifycontent: center;
  alignitems: center;
  // line-height: 0px;
`;
/* ---------------------------------------------------------------------------------- */

const Home: React.FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  const [tenpoCode, setTenpoCode] = useState<string | null>("");
  const [tenpoName, setTenpoName] = useState<string>("");
  const [getWidthLeft, setGetWidthLeft] = useState("");
  const [date, setDate] = useState("");
  const [windowHeight, setWindowHeight] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [timeout, setTimeout] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [TooltipOpen, setTooltipOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [errorAPI, setErrorAPI] = useState(false);
  const modalClose = () => setErrorAPI(false);

  const search = useLocation().search;
  const windowName = "現金仕入システム";

  //ログイン時に取得されたユーザ情報
  const userInfo: any = useSelector(selectUser);

  // const isVenderUser = () => {
  //   return (user==="JFIC" ?true:false)
  // };
  dispatch(resetInputCheckReqestInfo());

  useEffect(() => {
    window.setTimeout(dispMsg, timer);
    setWindowHeight(window.innerHeight * 0.905);
  }, []);

  useEffect(() => {
    if (!timeout) {
      window.setTimeout(dispMsg, timer);
    }
  }, [timeout]);

  const defaultTheme = createTheme();
  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            textAlign: "left",
          },
        },
      },
    },
  });

  const dispMsg = () => {
    setTimeout(true);
  };

  //権限を仮置きで付与
  const [value, setValue] = React.useState(userInfo.userAuth);
  const items = [
    "OFC",
    "DM",
    "ZM",
    "MD",
    "CMD/SMD",
    "OFC:申請判断",
    "外部業者",
    "QC:表示",
    "QC上長:表示",
    "QC:微生物",
  ];

  const saveItem = () => {
    dispatch(setUserAuth(value));
  };

  //入力されたログイン情報から取得する
  const isRegistUser = value === "0" ? false : true;

  return timeout ? (
    <>
      <Header windowName={windowName} />

      {/*全体-START*/}
      <Grid container alignItems="center" justifyContent="center">
        {/* タイトルエリア-START */}
        <Grid container css={titleArea}>
          <Grid item xs={12}>
            <Typography css={title}>
              該当業務のボタンを選択してください。
            </Typography>
          </Grid>
        </Grid>
        {/* タイトルエリア-END */}

        {/* ボタンエリア-START */}
        <Grid
          container
          css={contentsAreaAll}
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Button
            variant="contained"
            css={button}
            style={{ background: "#99AFFF", color: "white" }}
            onClick={() => {
              saveItem();
              navigate("/requestDb");
            }}
          >
            検索/確認
          </Button>

          {isRegistUser !== true && (
            <Button
              disabled={isRegistUser}
              variant="contained"
              css={button}
              sx={{
                color: "white",
                background: "#00CC5B",
                "&:hover": {
                  background: "#00CC5B",
                },
              }}
              onClick={() => {
                saveItem();
                navigate("/request");
              }}
            >
              申請
            </Button>
          )}

          {/* {isVenderUser() ? (
            <Button
              variant="contained"
              css={button}
              style={{ background: "#949494", color: "white" }}
              onClick={() => {
                navigate("/test");
              }}
            >
              外部業者様
            </Button>
          ) : (
            ""
          )} */}
        </Grid>

        {/* 
        <Grid container justifyContent="space-around" alignItems="center">
          デモサイト用権限設定
          {items.map((item, index) => (
            <Grid item key={item}>
              <FormControlLabel
                control={
                  <Radio
                    checked={value === index.toString()}
                    onChange={() => setValue(index.toString())}
                    value={index.toString()}
                    name="radio-button-demo"
                    inputProps={{ "aria-label": item }}
                  />
                }
                label={item}
              />
            </Grid>
          ))}
        </Grid>
        */}

        {/* ボタンエリア-END */}
      </Grid>
      {/*全体-END*/}
    </>
  ) : (
    <div>
      <CircularProgress css={circularProgress} size={120} />
      <Header windowName={windowName} />
    </div>
  );
};
export default Home;
