/* ---------------------------------------------------------------------------------- */
import React, { useCallback, useEffect, useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import { Grid, Box, Button, CardMedia, Modal } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Header from "../common/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  inputCheckReqestItemInfo,
  inputCheckReqestItemInfoList,
} from "../organisms/search/SummarySearchSlice";
import { postRequest } from "../../apis/axiosAction";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/system";

/* ---------------------------------------------------------------------------------- */

const setVhVariable = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
window.addEventListener('resize', setVhVariable);
window.addEventListener('load', setVhVariable);


const titleArea = css`
  height: calc(var(--vh, 1vh) * 5); 
  width: 95vw;
`;
const title = css`
  margin: 5px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
`;

const contentsAreaAll = css`
  height: calc(var(--vh, 1vh) * 78); 
  width: 90vw;
  overflow: hidden;
`;

const contentsAreaButton = css`
  height: calc(var(--vh, 1vh) * 8); 
  width: 90vw;
`;

const content = css`
  margin: 10px 20px;
  color: #f00;
`;

const radioItem = css`
  margin: 5px 40px;
  color: #f00;
`;

const radioItemLabel = css`
  font-weight: normal;
  font-size: 1.5rem;
  color: #000;
`;

const center = css`
  text-align: center;
`;

const image = css`
  width: 45%;
  height: auto;
`;

const text = css`
  color: red;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

/* ---------------------------------------------------------------------------------- */
const Request: React.FC<{}> = () => {
  const windowName = "現金仕入システム";

  const navigate = useNavigate();
  //前後ページに推移したときに値を保持するために初期値として設定
  const selectItem: any = useSelector(inputCheckReqestItemInfoList);
  const [preCheckDailyItem, setPreCheckDailyItem] = useState<any>(
    selectItem.dailyItem,
  );
  const [preCheckImportItem, setPreCheckImportItem] = useState<any>(
    selectItem.importItem,
  );
  const [preCheckPBItem, setPreCheckPBItem] = useState<any>(selectItem.BPitem);

  const handleChange =
    (setValue: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    };

  // 登録ボタンの表示制御（他社コンビニのPB品「preCheckPBItem：9」の場合は申請不可）
  const isRegistButtonVisible =
    (preCheckDailyItem === "1" || preCheckDailyItem === "2") &&
    (preCheckImportItem === "1" || preCheckImportItem === "2") &&
    preCheckPBItem === "1";

  const dispatch = useDispatch();
  // 登録情報をStateに保存
  const saveItemList = () => {
    dispatch(
      inputCheckReqestItemInfo({
        dailyItem: preCheckDailyItem,
        importItem: preCheckImportItem,
        BPitem: preCheckPBItem,
      }),
    );
  };

  const clearList = () => {
    dispatch(
      inputCheckReqestItemInfo({
        dailyItem: "",
        importItem: "",
        BPitem: "",
      }),
    );
    setPreCheckDailyItem("");
    setPreCheckImportItem("");
    setPreCheckPBItem("");
  };
  /* ---------------------------------------------------------------------------------- */
  const refs = Array.from({ length: 1 }, () =>
    React.createRef<HTMLDialogElement>(),
  );

  // ダイアログを開く処理
  const handleOpenDialog = useCallback(
    (index: number) => {
      refs[index]?.current?.showModal();
    },
    [refs],
  );

  // ダイアログを閉じる処理
  const handleCloseDialog = useCallback(
    (index: number) => {
      refs[index]?.current?.close();
    },
    [refs],
  );
  // ダイアログ内のクリックイベントハンドラーに渡す処理
  const handleClickInDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
    },
    [],
  );

  /* ---------------------------------------------------------------------------------- */

  return (
    <>
      <Header windowName={windowName} />

      <Grid container alignItems="center" justifyContent="center">
        {/*全体-START*/}
        <Grid container alignItems="center" justifyContent="center">
          {/* タイトルエリア-START */}
          <Grid container css={titleArea}>
            <Grid item xs={12}>
              <Typography css={title}>現金仕入フォーム</Typography>
            </Grid>
          </Grid>
          {/* タイトルエリア-END */}

          {/*  入力エリア-START */}
          <Grid container css={contentsAreaAll}>
            <Grid item xs={12}>
              <Grid>
                <Typography variant="h5" css={content}>
                  申請前にまずは以下の項目について確認をお願いします
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid css={center}>
                <div
                  role="button"
                  onClick={() => handleOpenDialog(0)}
                  onKeyDown={() => handleOpenDialog(0)}
                  className="image-box"
                  tabIndex={0}
                >
                  <img
                    src={`${window.location.origin}/image/sample.png`}
                    alt="説明"
                    css={image}
                    //style={{ height: "100%", width: "100%" }}
                  />
                </div>
                <dialog
                  ref={refs[0]}
                  className="image-dialog"
                  onClick={() => handleCloseDialog(0)}
                >
                  <div onClick={handleClickInDialog} className="contents">
                    <img
                      src={`${window.location.origin}/image/sample.png`}
                      alt=""
                      width="100%"
                    />
                  </div>
                </dialog>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid xs={6}>
                <Typography variant="h5" css={content}>
                  1.今回申請する商品は
                </Typography>

                <FormControl>
                  <RadioGroup
                    row
                    key={preCheckDailyItem}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleChange(setPreCheckDailyItem)}
                    defaultValue={preCheckDailyItem}
                  >
                    <Grid
                      xs={12}
                      css={radioItem}
                      style={{
                        border: "1px solid gray",
                        borderColor: "#d9d9d9",
                      }}
                    >
                      <Grid style={{ padding: "5px 0px 5px 10px" }}>
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={<span css={radioItemLabel}>デイリー品</span>}
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label={
                            <span css={radioItemLabel}>デイリー品ではない</span>
                          }
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>

                <FormControl>
                  <RadioGroup
                    row
                    key={preCheckImportItem}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleChange(setPreCheckImportItem)}
                    defaultValue={preCheckImportItem}
                  >
                    <Grid
                      xs={12}
                      css={radioItem}
                      style={{
                        border: "1px solid gray",
                        borderColor: "#d9d9d9",
                      }}
                    >
                      <Grid style={{ padding: "5px 0px 5px 10px" }}>
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={<span css={radioItemLabel}>輸入食品</span>}
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label={
                            <span css={radioItemLabel}>輸入食品ではない</span>
                          }
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid xs={6}>
                <Typography variant="h5" css={content}>
                  2.他社コンビニ商品ではないか？
                </Typography>

                <FormControl>
                  <RadioGroup
                    row
                    key={preCheckPBItem}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleChange(setPreCheckPBItem)}
                    defaultValue={preCheckPBItem}
                  >
                    <Grid
                      xs={12}
                      css={radioItem}
                      style={{
                        border: "1px solid gray",
                        borderColor: "#d9d9d9",
                      }}
                    >
                      <Grid style={{ padding: "5px 0px 5px 10px" }}>
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label={
                            <span css={radioItemLabel}>
                              他社コンビニのPB商品ではない
                            </span>
                          }
                        />
                        <FormControlLabel
                          value="9"
                          control={<Radio />}
                          label={
                            <span css={radioItemLabel}>
                              他社コンビニのPB商品
                            </span>
                          }
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={8}></Grid>
            </Grid>
          </Grid>
          {/*  入力エリア-END */}

          {/* ボタンエリア-START */}
          <Grid container css={contentsAreaButton}>
            <Grid container xs={12}>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  style={{ width: "90%", margin: "2px" }}
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  戻る
                </Button>
              </Grid>

              <Grid
                container
                xs={6}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography variant="caption" align="right" css={text}>
                  全ての確認に問題が無ければデータの登録ボタンが表示されます→
                  <br />
                  （注意）他社コンビニのPB商品が選択されている場合は申請不可となります
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Button
                  disabled={!isRegistButtonVisible}
                  variant="contained"
                  style={{ width: "90%", margin: "2px" }}
                  onClick={() => {
                    saveItemList();
                    navigate("/requestCheck");
                  }}
                >
                  登録チェック
                </Button>
              </Grid>

              <Grid item xs={2}>
                <Button
                  variant="contained"
                  style={{ width: "90%", margin: "2px" }}
                  onClick={() => {
                    clearList();
                  }}
                >
                  クリア
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* ボタンエリア-END */}
        </Grid>
        {/*全体-END*/}
      </Grid>
    </>
  );
};
export default Request;
