/* ---------------------------------------------------------------------------------- */
import React, { useEffect } from "react";
import { ChangeEvent } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Modal,
  Box,
  Checkbox,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Header from "../common/Header";
import RequestItemCameraUpload from "./RequestItemCameraUpload";
import RequestInputTenpoModal from "./RequestInputTenpoModal";
import { useDispatch, useSelector } from "react-redux";

import {
  inputCheckReqestItemInfoList,
  checkListItem,
  selectTenpoInfoList,
  selectItemInfoList,
  deleteTenpoInfo,
} from "../organisms/search/SummarySearchSlice";
import { postRequest } from "../../apis/axiosAction";
import { initializeExistingSejStores } from "../../data/existingSejStores";
import { selectImageToken, selectUser } from "../../features/userSlice";
import { uploadPhotoGoogleCloudStorage } from "../../apis/photoAction";
import { v4 as uuidv4 } from "uuid";
import CashPurchaseDialog from "./CashPurchaseDialog";

/* ---------------------------------------------------------------------------------- */
const setVhVariable = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
window.addEventListener('resize', setVhVariable);
window.addEventListener('load', setVhVariable);

const titleArea = css`
  height: calc(var(--vh, 1vh) * 5);
  width: 95vw;
`;
const title = css`
  margin: 5px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
`;

const contentsAreaAll = css`
  height: calc(var(--vh, 1vh) * 80);
  width: 95vw;
  overflow: hidden;
`;

const contentsAreaButton = css`
  height: calc(var(--vh, 1vh) * 3);
  width: 95vw;
`;

const contentsArea1 = css`
  box-sizing: border-box;
  height: 10%;
  width: 100%;
  padding: 2px;
  border: 1px solid #d9d9d9;
`;
const contentsArea2 = css`
  box-sizing: border-box;
  height: 20%;
  width: 100%;
  padding: 2px;
  border: 1px solid #d9d9d9;
`;
const contentsArea3 = css`
  // box-sizing: border-box;
  height: 50%;
  width: 100%;
  padding: 2px;
  // border: 1px solid #d9d9d9;
`;

const content = css`
  margin: 5px;
  color: #000;
  font-size: 17px;
`;

const text = css`
  color: red;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #d9d9d9",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const center = css`
  text-align: center;
`;

const image = css`
  width: 90%;
  height: auto;
`;

const LogStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  // minWidth: "40%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

//データ表示用の配列
let result: any = {};

/* ---------------------------------------------------------------------------------- */
const RequestInput: React.FC<{}> = () => {
  const windowName = "現金仕入システム";

  const navigate = useNavigate();

  const [uploadFileList, setUploadFileList] = React.useState<string[]>([]);
  const [uploadFile, setUploadFile] = React.useState("");

  const [employeeName, setEmployeeName] = React.useState("");
  const [doName, setDoName] = React.useState("");
  //前画面で入力された項目の取得
  const checkRequestItem: any = useSelector(inputCheckReqestItemInfoList);
  const checkItemInfo: any = useSelector(checkListItem);
  let selectTenpoList = useSelector(selectTenpoInfoList);
  //編集時に保持した値の受け渡し
  const itemInfo: any = useSelector(selectItemInfoList);

  //ログイン時に取得されたユーザ情報
  const userInfo: any = useSelector(selectUser);
  const imageToken = useSelector(selectImageToken);

  useEffect(() => {
    // 連携データ存在チェック（直リンク及びリロード判定）
    if (
      userInfo.doValue[0] === "" ||
      userInfo.employeeName === "" ||
      (checkRequestItem.dailyItem === "" &&
        checkRequestItem.importItem === "" &&
        checkRequestItem.BPitem === "")
    ) {
      // 連携データがない＝直リンク及びリロード時は業務フロートップへ遷移する。
      navigate("/request");
      return;
    }
    setDoName(userInfo.doValue[0].split("：")[1]);
    setEmployeeName(userInfo.employeeName);
    // CSVファイルから店舗、DO、ZO情報読み込み
    initializeExistingSejStores(imageToken);
  }, []);

  const tenpoCode = selectTenpoList.map(
    (item: any) => '"' + item.tenpoCode + '"',
  );
  const tenpoName = selectTenpoList.map(
    (item: any) => '"' + item.tenpoName + '"',
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [logOpen, setLogOpen] = React.useState(false);
  const handleLogOpen = () => setLogOpen(true);
  const handleLogClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      setLogOpen(false);
    }
  };

  /* ～～～～～～～～～～～～～～～～～～～～～～～～～～～～～～ */
  const NoImage = ""; //`${window.location.origin}` + "/image/noimage.png";

  const imageList = {
    image1Url: itemInfo.image1Url,
    image2Url: itemInfo.image2Url,
    image3Url: itemInfo.image3Url,
    image4Url: itemInfo.image4Url,
    image5Url: itemInfo.image5Url,
  };

  const [imageState, setImageState] = React.useState(imageList);

  // アップロード選択時：ファイルのアップロードとURL取得
  const handleUploadPhoto = (
    event: ChangeEvent<HTMLInputElement>,
    fileUploadeURL: any,
    updateLoadingStatus: any,
    itemNumber: number,
  ) => {
    updateLoadingStatus(true);

    // ファイル名の末尾にYYYYMMDDHHMMSSを追加（重複回避）
    let tmpImageFile: any = event.target.files![0];

    // 現在時刻のタイムゾーンを日本時間(UTC+9)に変換して設定
    let date = new Date();
    date.setHours(date.getHours() + 9);
    let dateStr = date.toISOString().replace(/T|Z|:|-|\.\d{3}/g, ""); // YYYYMMDDHHMMSS形式変換
    dateStr = dateStr.slice(0, 14); // ミリ秒を削除

    // 元のファイル名と拡張子を取得
    let originalName = tmpImageFile.name;
    let dotIndex = originalName.lastIndexOf(".");
    let fileName = originalName.slice(0, dotIndex);
    let fileExt = originalName.slice(dotIndex);

    // 新しいファイル名を設定
    let newFileName = `${fileName}-${dateStr}-${uuidv4()}${fileExt}`;

    let imageFile = new File([tmpImageFile], newFileName, {
      type: tmpImageFile.type,
    });

    const directory = "cash-purchase-photo/";
    uploadPhotoGoogleCloudStorage(imageFile, directory, newFileName).then(
      (filepath: any) => {
        updateLoadingStatus(false);
        setUploadFile(filepath);
        fileUploadeURL(filepath);
        upload(itemNumber, filepath);
      },
    );

  };

  const upload = async (itemNumber: number, downloadURL: string) => {
    // ファイルがアップロードされている場合、データリストにURLを保存する
    if (downloadURL != null) {
      // 選択値を表データに反映
      let tmpUploadFileList = uploadFileList.slice(0, uploadFileList.length);
      tmpUploadFileList[itemNumber] = downloadURL;
      setUploadFileList(tmpUploadFileList);

      if (itemNumber === 0) {
        setImageState((prevState) => ({
          ...prevState,
          image1Url: tmpUploadFileList[0],
        }));
      } else if (itemNumber === 1) {
        setImageState((prevState) => ({
          ...prevState,
          image2Url: tmpUploadFileList[1],
        }));
      } else if (itemNumber === 2) {
        setImageState((prevState) => ({
          ...prevState,
          image3Url: tmpUploadFileList[2],
        }));
      } else if (itemNumber === 3) {
        setImageState((prevState) => ({
          ...prevState,
          image4Url: tmpUploadFileList[3],
        }));
      } else if (itemNumber === 4) {
        setImageState((prevState) => ({
          ...prevState,
          image5Url: tmpUploadFileList[4],
        }));
      }
    }
  };

  //-------------------------------------------------------
  //商品入力情報を保持
  const item = {
    telNo: itemInfo.telNo,
    itemName: itemInfo.itemName,
    makerName: itemInfo.makerName,
    recycle: itemInfo.recycle,
    costPrice: itemInfo.costPrice,
    sellPrice: itemInfo.sellPrice,
    limitDay: itemInfo.limitDay,
  };

  const [state, setState] = React.useState(item);
  const dispatch = useDispatch();

  const handleRecycleChange = (event: any) => {
    setState((prevState) => ({ ...prevState, recycle: event.target.value }));
  };

  // 入力チェック
  //チェックボックスの情報保持
  const [checked1, setChecked1] = React.useState(false);
  const handleChangeChack1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked1(event.target.checked);
  };
  const [checked2, setChecked2] = React.useState(false);
  const handleChangeChack2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked2(event.target.checked);
  };

  // 登録ボタンの表示制御（）
  const isRegistButtonVisible = //true;
    selectTenpoList.length === 0 ||
    state.telNo === "" ||
    state.itemName === "" ||
    state.makerName === "" ||
    state.recycle === "" ||
    state.costPrice === "" ||
    state.sellPrice === "" ||
    imageState.image1Url === NoImage ||
    imageState.image2Url === NoImage ||
    imageState.image5Url === NoImage ||
    (imageState.image3Url === NoImage && checked1 === false) ||
    (imageState.image4Url === NoImage && checked2 === false);

  function clearItem() {
    setState(item);
    setImageState(imageList);
    setChecked1(false);
    setChecked2(false);
    selectTenpoList.map((item: any) => {
      dispatch(
        deleteTenpoInfo({
          tenpoInfo: {
            tenpoCode: item.tenpoCode,
            tenpoName: "",
            tenpoTel: "",
          },
        }),
      );
    });
  }

  /* ～～～～～～～～～～～～～～～～～～～～～～～～～～～～～～ */
  const [apiData, setApiData] = React.useState<[]>([]);
  const [loading, setLoading] = React.useState(false); //API読み込みを保持するState

  // メッセージ用ダイアログ設定
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);
  // ダイアログ閉じる
  const dialogClose = () => {
    setDialogFlg(false);
  };

  function inputData() {
    if (checked1 === true) {
      setImageState((prevState) => ({
        ...prevState,
        image3Url: "",
      }));
    }
    if (checked2 === true) {
      setImageState((prevState) => ({
        ...prevState,
        image4Url: "",
      }));
    }

    let apiParams = {
      query:
        "mutation { request(input:" +
        '{id:"' +
        itemInfo.id +
        '",' +
        'docLastId:"",' +
        'createUser:"' +
        userInfo.mailAddress +
        '", ' +
        'updateUser:"' +
        userInfo.mailAddress +
        '", ' +
        'ofcId:"' +
        userInfo.ofcId +
        '", ' +
        'employeeNo:"' +
        userInfo.employeeNo +
        '", ' +
        'employeeName:"' +
        userInfo.employeeName +
        '", ' +
        'telNo:"' +
        state.telNo +
        '", ' +
        'mailaddress:"' +
        userInfo.mailAddress +
        '", ' +
        'doCode:"' +
        userInfo.doCode[0] +
        '", ' +
        'doName:"' +
        userInfo.doValue[0].split("：")[1] +
        '", ' +
        'zoCode:"' +
        userInfo.zoCode[0] +
        '", ' +
        'zoName:"' +
        userInfo.zoValue[0].split("：")[1] +
        '", ' +
        'confirmStep:"' +
        itemInfo.confirmStep +
        '", ' +
        'checkDaily:"' +
        checkRequestItem.dailyItem +
        '",' +
        'checkImport:"' +
        checkRequestItem.importItem +
        '", ' +
        'checkPb:"' +
        checkRequestItem.BPitem +
        '", ' +
        'check1:"' +
        checkItemInfo.check1 +
        '",' +
        'check2:"' +
        checkItemInfo.check2 +
        '",' +
        'check3:"' +
        checkItemInfo.check3 +
        '", ' +
        'check4:"' +
        checkItemInfo.check4 +
        '", ' +
        'check5:"' +
        checkItemInfo.check5 +
        '", ' +
        'check6:"' +
        checkItemInfo.check6 +
        '", ' +
        'check7:"' +
        checkItemInfo.check7 +
        '", ' +
        'check8:"' +
        checkItemInfo.check8 +
        '", ' +
        'check9:"' +
        checkItemInfo.check9 +
        '", ' +
        'productName:"' +
        state.itemName +
        '", ' +
        'makerName:"' +
        state.makerName +
        '", ' +
        "tenpoCode:[" +
        tenpoCode +
        "], " +
        "tenpoName:[" +
        tenpoName +
        "], " +
        'recycleMark:"' +
        state.recycle +
        '", ' +
        "costPrice:" +
        state.costPrice +
        ", sellingPrice:" +
        state.sellPrice +
        ", " +
        "bestBeforeDays:" +
        state.limitDay +
        ", " +
        'image1:"' +
        imageState.image1Url +
        '", ' +
        'image2:"' +
        imageState.image2Url +
        '", ' +
        'image3:"' +
        imageState.image3Url +
        '", ' +
        'image4:"' +
        imageState.image4Url +
        '", ' +
        'image5:"' +
        imageState.image5Url +
        '"' +
        "})} ",
    };
    const url = process.env.REACT_APP_API_URL + "graphql";
    setLoading(true);
    postRequest(url, apiParams) // ここには実際のAPIのURLを指定します。
      .then((response) => {
        // throw new Error("This is a forced error for testing purposes.");
        setApiData(response.data.data); // 取得したデータをステートにセット
        setLoading(false);
      })
      .catch((error) => {
        console.error(error); // エラーハンドリング
        setLoading(false);
        setLogOpen(false);
        setDialogMessage([String(error)]);
        setDialogFlg(true);
      });
  }

  apiData && (result = apiData);

  return (
    <>
      <Header windowName={windowName} />
      <Grid container alignItems="center" justifyContent="center">
        {/* タイトルエリア-START */}
        <Grid container css={titleArea}>
          <Typography variant="h3" css={title}>
            現金仕入表示確認フォーム
          </Typography>
          <IconButton
            style={{ color: "black", padding: "3px" }}
            onClick={() => {
              handleOpen();
            }}
          >
            <HelpIcon fontSize="medium" />
          </IconButton>
        </Grid>
        {/* タイトルエリア-END */}

        {/*入力エリアエリア-START */}
        <Grid container css={contentsAreaAll} direction="column">
          {/* 1行目 */}
          <Grid container css={contentsArea1} alignItems="center">
            <Grid item>
              <Typography css={content}>DO名：{doName}</Typography>
            </Grid>

            <Grid item>
              <Typography css={content}>OFC名：{employeeName}</Typography>
            </Grid>

            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography css={content}>電話番号：</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    label="電話番号"
                    value={state.telNo}
                    variant="outlined"
                    required
                    fullWidth={true}
                    inputProps={{
                      maxLength: 11,
                    }}
                    size="small"
                    type="text"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (value.length <= 11 && /^[0-9]*$/g.test(value)) {
                        setState((prevState) => ({
                          ...prevState,
                          telNo: value,
                        }));
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <RequestInputTenpoModal />
            </Grid>

            <Grid item>
              <Typography css={content}>：：</Typography>
            </Grid>

            {selectTenpoList.slice(0, 1).map((value: any) => (
              <Grid item>
                <Typography css={content}>
                  {value.tenpoName} {selectTenpoList.length > 1 ? "、" : ""}
                </Typography>
              </Grid>
            ))}
            {selectTenpoList.length > 1 && "他"}
          </Grid>

          {/* 2行目 */}
          <Grid container css={contentsArea1} alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography css={content}>商品名：</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    label="商品名"
                    value={state.itemName}
                    variant="outlined"
                    required
                    fullWidth={true}
                    size="small"
                    onChange={(event) => {
                      if (event.target.value.length <= 100) {
                        setState((prevState) => ({
                          ...prevState,
                          itemName: event.target.value,
                        }));
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography css={content}>メーカー名：</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    label="メーカー名"
                    value={state.makerName}
                    variant="outlined"
                    required
                    fullWidth={true}
                    size="small"
                    onChange={(event) => {
                      if (event.target.value.length <= 100) {
                        setState((prevState) => ({
                          ...prevState,
                          makerName: event.target.value,
                        }));
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography css={content}>
                    商品にリサイクルマークの有無*：
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl>
                    <Select
                      name="checkItem_9"
                      size="small"
                      value={state.recycle}
                      onChange={(e) => {
                        handleRecycleChange(e);
                      }}
                      autoWidth
                    >
                      <MenuItem value={"0"}>有</MenuItem>
                      <MenuItem value={"1"}>無</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* 3行目 */}
          <Grid container css={contentsArea2} alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography css={content}>原価：</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    label="原価※円不要"
                    value={state.costPrice}
                    variant="outlined"
                    required
                    fullWidth={true}
                    size="small"
                    type="text"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (
                        value.length <= 8 &&
                        /^[0-9]*\.?[0-9]*$/g.test(value)
                      ) {
                        setState((prevState) => ({
                          ...prevState,
                          costPrice: value,
                        }));
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography css={content}>売価：</Typography>
                </Grid>
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    label="売価※円不要"
                    value={state.sellPrice}
                    variant="outlined"
                    required
                    fullWidth={true}
                    size="small"
                    type="text"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (
                        value.length <= 8 &&
                        /^[0-9]*\.?[0-9]*$/g.test(value)
                      ) {
                        setState((prevState) => ({
                          ...prevState,
                          sellPrice: value,
                        }));
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography css={content}>
                    製造から賞味期限までの日数（賞味期限1ヶ月以内の場合メーカーへ聞き取り）：
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    label="日数"
                    value={state.limitDay}
                    variant="outlined"
                    // required
                    fullWidth={true}
                    size="small"
                    type="text"
                    onChange={(event) => {
                      const value = event.target.value;
                      if (
                        value.length <= 4 &&
                        /^[0-9]*\.?[0-9]*$/g.test(value)
                      ) {
                        setState((prevState) => ({
                          ...prevState,
                          limitDay: value,
                        }));
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography css={content}>日</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* 入力エリア-END */}

          {/* 画像アップロードエリア-START */}
          <Grid container css={contentsArea3}>
            {/* 画面左：チェックエリア */}
            {/* 1行目 */}
            <Grid
              item
              xs={2.4}
              style={{
                border: "1px solid gray",
                boxSizing: "border-box",
                borderColor: "#d9d9d9",
              }}
            >
              <Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "10px",
                    borderBottom: "1px solid gray",
                    boxSizing: "border-box",
                  }}
                >
                  <Box display="flex" justifyContent="center">
                    <Typography fontSize={13}>①商品全体画像*</Typography>
                  </Box>
                </Grid>
                <FormControl fullWidth>
                  <RequestItemCameraUpload
                    itemNumber={0}
                    onHandleUploadPhoto={handleUploadPhoto}
                    onUploadFile={upload}
                    title={"①商品全体画像"}
                    image={imageState.image1Url}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              item
              xs={2.4}
              style={{
                border: "1px solid gray",
                boxSizing: "border-box",
                borderColor: "#d9d9d9",
              }}
            >
              <Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "10px",
                    borderBottom: "1px solid gray",
                    boxSizing: "border-box",
                  }}
                >
                  <Box display="flex" justifyContent="center">
                    <Typography fontSize={13}>
                      ②商品全体画像（裏面/分割）*
                    </Typography>
                  </Box>
                </Grid>
                <FormControl fullWidth>
                  <RequestItemCameraUpload
                    itemNumber={1}
                    onHandleUploadPhoto={handleUploadPhoto}
                    onUploadFile={upload}
                    title={"②商品全体画像（裏面/分割）"}
                    image={imageState.image2Url}
                  />
                </FormControl>
              </Grid>
            </Grid>

            {/* 2行目 */}
            <Grid
              item
              xs={2.4}
              style={{
                border: "1px solid gray",
                boxSizing: "border-box",
                borderColor: "#d9d9d9",
              }}
            >
              <Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "10px",
                    borderBottom: "1px solid gray",
                    boxSizing: "border-box",
                  }}
                >
                  <Box display="flex" justifyContent="center">
                    <Typography fontSize={13}>③賞味期限表示画像</Typography>
                  </Box>
                </Grid>
                <FormControl fullWidth>
                  <RequestItemCameraUpload
                    itemNumber={2}
                    onHandleUploadPhoto={handleUploadPhoto}
                    onUploadFile={upload}
                    title={"③賞味期限表示画像"}
                    image={imageState.image3Url}
                  />
                </FormControl>

                <Box display="flex" justifyContent="center">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked1}
                          onChange={handleChangeChack1}
                        />
                      }
                      label={
                        <Typography fontSize={12} color="red">
                          ない場合はチェックを入れてください
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
              </Grid>
            </Grid>

            <Grid
              item
              xs={2.4}
              style={{
                border: "1px solid gray",
                boxSizing: "border-box",
                borderColor: "#d9d9d9",
              }}
            >
              <Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "10px",
                    borderBottom: "1px solid gray",
                    boxSizing: "border-box",
                  }}
                >
                  <Box display="flex" justifyContent="center">
                    <Typography fontSize={13}>④栄養成分表示画像</Typography>
                  </Box>
                </Grid>
                <FormControl fullWidth>
                  <RequestItemCameraUpload
                    itemNumber={3}
                    onHandleUploadPhoto={handleUploadPhoto}
                    onUploadFile={upload}
                    title={"④栄養成分表示画像"}
                    image={imageState.image4Url}
                  />
                </FormControl>
                <Box display="flex" justifyContent="center">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked2}
                          onChange={handleChangeChack2}
                        />
                      }
                      label={
                        <Typography fontSize={12} color="red">
                          ない場合はチェックを入れてください
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
              </Grid>
            </Grid>

            {/* 画面右：画像&ボタンエリア */}
            <Grid
              item
              xs={2.4}
              style={{
                border: "1px solid gray",
                boxSizing: "border-box",
                borderColor: "#d9d9d9",
              }}
            >
              <Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    margin: "10px",
                    borderBottom: "1px solid gray",
                    boxSizing: "border-box",
                  }}
                >
                  <Box display="flex" justifyContent="center">
                    <Typography fontSize={13}>
                      ⑤原材料名表示部分画像*
                    </Typography>
                  </Box>
                </Grid>
                <FormControl fullWidth>
                  <RequestItemCameraUpload
                    itemNumber={4}
                    onHandleUploadPhoto={handleUploadPhoto}
                    onUploadFile={upload}
                    title={"⑤原材料名表示部分画像"}
                    image={imageState.image5Url}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* 画像アップロードエリア-END */}
        </Grid>

        {/* ボタンエリア-START */}
        <Grid container css={contentsAreaButton}>
          <Grid container xs={12}>
            <Grid item xs={2}>
              <Button
                variant="contained"
                style={{ width: "90%", margin: "2px" }}
                onClick={() => {
                  navigate("/requestNotice");
                }}
              >
                戻る
              </Button>
            </Grid>

            <Grid
              container
              xs={6}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Typography variant="caption" align="right" css={text}>
                全ての確認に問題が無ければデータの登録ボタンが表示されます→
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Button
                disabled={isRegistButtonVisible}
                variant="contained"
                style={{ width: "90%", margin: "2px" }}
                onClick={() => {
                  handleLogOpen();
                  inputData();
                }}
              >
                登録
              </Button>
            </Grid>

            <Grid item xs={2}>
              <Button
                variant="contained"
                style={{ width: "90%", margin: "2px" }}
                onClick={() => {
                  clearItem();
                }}
              >
                クリア
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* ボタンエリア-END */}
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid item xs={12}>
            <Grid css={center}>
              <img
                src={`${window.location.origin}/image/RequestNotice1.jpg`}
                alt="説明"
                css={image}
              />
            </Grid>
          </Grid>

          <Typography>
            ①商品現物の表示画像を貼付 ※HPの画像は不可
            <br />
            ②文字が確実に読める解像度で貼付
            <br />
            ③賞味期限は、実際に印字されたものと印字箇所がわかる画像にする
            <br />
            ④瓶や缶など丸い容器形状のものは分割して複数枚を貼付けする
            <br />
            ⑤酒類の必要表示「未成年飲酒禁止」を確認し画像を貼付けする
            <br />
            ⑥リサイクルマークの有無を確認
          </Typography>
        </Box>
      </Modal>

      {/* ダイアログ */}
      <CashPurchaseDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogClose}
        doNo={dialogClose}
        isAlert={true}
      />

      <Modal
        open={logOpen}
        onClose={handleLogClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={LogStyle}>
          {loading ? (
            <CircularProgress size={36} />
          ) : (
            <>
              <Grid container alignContent="center">
                <Grid item xs={12}>
                  {result.request === "error" ? (
                    <Typography fontSize={16} component="h2">
                      登録時にエラーが発生しました。
                      <br />
                      データの確認をお願いします。
                    </Typography>
                  ) : (
                    <>
                      <Typography variant="h4">登録完了しました</Typography>
                      <Typography variant="h4" style={{ whiteSpace: "nowrap" }}>
                        申請ID：{result.request}
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4} justifyContent="center" alignItems="center">
                  <Button
                    variant="contained"
                    style={{
                      width: "80%",
                      margin: "2px",
                      backgroundColor: "#C4C4C4",
                      color: "black",
                    }}
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    閉じる
                  </Button>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};
export default RequestInput;
