import { postRequest } from "./axiosAction";
import { getApiParam } from "./params";
import { DateTime } from "luxon";
import { useDispatch } from "react-redux";
import { setIsDeviceType } from "../features/userSlice";

// OFCダッシュボードのAPI呼び出し
export const apiRequest = async (params: any) => {
  const graphql_api_url: string =
    process.env.REACT_APP_OFC_DASHBOARD_GRAPHQL! + "/graphql";
  let res: any = {};
  try {
    res = await postRequest(graphql_api_url, getApiParam(params));
  } catch (error) {
    console.log("error", error);
    throw error;
  }
  return res;
};

//=============================================================================
//  制御、その他
//=============================================================================
/**
 * 別タブ遷移
 * @param url : url文字列
 */
export const openWebSite = (url: string) => {
  window.open(url, "_blank", "noreferrer noopener");
};

// デバイス・OSの判定を行う
export const useDeviceType = () => {
  const ua = navigator.userAgent;
  const loerUa = navigator.userAgent.toLowerCase();
  const dispatch = useDispatch();
  if (ua.indexOf("iPhone") > -1) {
    dispatch(
      setIsDeviceType({
        deviceType: true,
        ios: true,
        iphone: true,
      }),
    );
  } else if (ua.indexOf("Android") > -1 && ua.indexOf("Mobile") > -1) {
    dispatch(
      setIsDeviceType({
        deviceType: true,
        ios: false,
        iphone: false,
      }),
    );
  } else if (ua.indexOf("iPad") > -1) {
    dispatch(
      setIsDeviceType({
        deviceType: true,
        ios: true,
        iphone: false,
      }),
    );
  } else if (/ipad|macintosh/.test(loerUa) && "ontouchend" in document) {
    // OS判定がMacOSX向けになっているもの
    dispatch(
      setIsDeviceType({
        deviceType: true,
        ios: true,
        iphone: false,
      }),
    );
  } else if (ua.indexOf("Android") > -1) {
    dispatch(
      setIsDeviceType({
        deviceType: true,
        ios: false,
        iphone: false,
      }),
    );
  } else {
    dispatch(
      setIsDeviceType({
        deviceType: false,
        ios: false,
        iphone: false,
      }),
    );
  }
};

//=============================================================================
//  時間・日付
//=============================================================================
/**
 * UnixTimeの数値を返す
 * @returns {number} UNIXTIME  例:1639381897
 */
export const getUnixTime = (): number => {
  // Dateオブジェクトを作成し、UNIXタイムスタンプを取得する (ミリ秒単位)
  const unixTimestamp = new Date().getTime();
  // UNIXタイムスタンプを取得する (秒単位 - PHPのtime()と同じ)
  return Math.floor(unixTimestamp / 1000);
};

export const getNowYMD = (): string => {
  var dt = new Date();
  var y = dt.getFullYear();
  var m = ("00" + (dt.getMonth() + 1)).slice(-2);
  var d = ("00" + dt.getDate()).slice(-2);
  var result = y + m + d;
  return result;
};

/**
 *  曜日の取得
 *
 */
export const getDayOfWeekText = (datetime: DateTime | null) => {
  if (!datetime) {
    return;
  }
  let weekDayArray: string[] = [];
  weekDayArray = ["月", "火", "水", "木", "金", "土", "日"];
  const dayOfWeek = datetime.weekday;
  return weekDayArray[dayOfWeek - 1];
};

/**
 *
 */
export function limitLengthAndLine(
  comment: string,
  maxLength: number,
  maxLine: number,
) {
  if (comment && Number(comment.length) > maxLength) {
    comment = comment.substring(0, maxLength);
  }

  if (comment.split(/\r|\n|\r\n/g).length > maxLine) {
    let commentArray = comment.split(/\r|\n|\r\n/g);
    let temp = "";
    for (let i = 0; i < commentArray.length; i++) {
      if (i < maxLine - 1) {
        temp = temp + commentArray[i] + "\n";
      } else {
        temp = temp + commentArray[i];
      }
    }
    comment = temp;
  }
  return comment;
}
