import React from "react";
import { useNavigate } from "react-router-dom";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Box,
  AppBar,
  Typography,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import HamburgerMenu from "./HamburgerMenu";

import HomeIcon from "@mui/icons-material/Home";

const appBar = css`
  min-height: 8vh;
  color: black;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const appBarElement = css`
  min-height: 8vh;
  display: flex;
  align-items: center;
  justify-content: end;
`;

const appBarElement2 = css`
  min-height: 8vh;
  display: flex;
  align-items: center;
  justify-content: start;
`;

const headerText = css`
  color: black;
  font-size: 20px;
  font-weight: normal;
`;

// TODO 不要箇所削除
const Header: React.FC<{
  windowName: string;
  showOpview?: boolean;
}> = ({ windowName, showOpview = true }) => {
  const navigate = useNavigate();

  return (
    <Box>
      <AppBar position="static" css={appBar}>
        <Grid container>
          <Grid xs={0.5} css={appBarElement}>
            <HamburgerMenu />
          </Grid>

          <Grid xs={0.5} css={appBar}>
            <IconButton
              style={{ color: "black" }}
              onClick={() => {
                navigate("/home");
              }}
            >
              <HomeIcon />
            </IconButton>
          </Grid>

          <Grid xs={0.5} css={appBarElement}>
            <img
              src={`${window.location.origin}/image/sejlogo.png`}
              alt="SEJアイコン"
              style={{ height: "7vh" }}
            />
          </Grid>

          <Grid item xs={4.5} css={appBarElement2}>
            <Typography variant="h5" component="p" css={headerText}>
              {windowName}
            </Typography>
            <Typography
              variant="h5"
              component="p"
              css={headerText}
            ></Typography>
          </Grid>

          <Grid xs={6} css={appBarElement}></Grid>

          {/* <Grid
            container
            item
            xs={2}
            alignItems="center"
            justifyContent="center"
          >
            <Button
              variant="contained"
              style={{ width: "90%", margin: "2px" }}
              onClick={() => {
                navigate("/requestDb");
              }}
            >
              申請データベース確認
            </Button>
          </Grid> */}
        </Grid>
      </AppBar>
    </Box>
  );
};

export default Header;
