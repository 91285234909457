import { postRequest } from "../apis/axiosAction";

export const userInfo: any = {
  employee_no: "",
  job_category: "",
  do_code: [],
  zo_code: [],
  tenpo_code: [],
};

// ユーザー情報を返す
export const getUserInfo = () => {
  return userInfo;
};

// ユーザー情報を格納
export const setUserInfo = (json: any) => {
  userInfo.employee_no = json.employee_no ? json.employee_no : "";
  userInfo.job_category = json.job_category ? json.job_category : "";
  userInfo.do_code = json.do_code ? json.do_code : [];
  userInfo.zo_code = json.zo_code ? json.zo_code : [];
  userInfo.tenpo_code = json.tenpo_code ? json.tenpo_code : [];
};

// ユーザー情報があるかどうかを返す
export const isUserInfo = () => {
  return userInfo.employee_no !== null && userInfo.employee_no !== "";
};

// 初期処理
export const initialOperation = async () => {
  // ユーザー情報APIの呼び出し（デジタルツールのfirestoreのデータ）
  // await postRequest(
  //   process.env.REACT_APP_COMMON_API!,
  //   {
  //     "api-name": "user-info",
  //   },
  //   false,
  // )
  //   .then((response: any) => {
  //     const json = JSON.parse(response.data.dataList[0]);
  //     setUserInfo(json);
  //     return true;
  //   })
  //   .catch((error: any) => {
  //     console.error("Error App initialOperation1: ", error);
  //     throw error;
  //   });
};
