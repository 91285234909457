/* ---------------------------------------------------------------------------------- */
import React from "react";
/** @jsxImportSource @emotion/react */
import {
  Grid,
  Button,
  Modal,
  Typography,
  Checkbox,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import SearchTenpo from "../organisms/search/searchTenpo/SearchTenpo";
import SearchZO from "../organisms/search/searchZO/SearchZO";
import SearchDO from "../organisms/search/searchDO/SearchDO";
import {
  addZoInfo,
  deleteZoInfo,
  addDoInfo,
  deleteDoInfo,
  addTenpoInfo,
  deleteTenpoInfo,
} from "../organisms/search/SummarySearchSlice";
import { useDispatch } from "react-redux";
import DatePicker from "../common/DatePicker";
import { DateTime } from "luxon";
import { useEffect } from "react";

const stylesSearchTenpo = {
  paper: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "80%",
    backgroundColor: "#fff",
    border: "2px solid #000",
    bgcolor: "background.paper",
    boxShadow: "0px 3px 5px -1px rgba(0,0,0,0,2)",
    padding: "16px 32px 24px",
    p: 4,
    overflowY: "auto",
  },
  listPaper: {
    height: "87%",
    overflow: "auto",
    padding: "0px 0px",
    marginTop: "25px",
  },
  searchButton: {
    width: "90%",
    maxWidth: "600px",
    marginLeft: "15px",
  },
  searchTextField: {
    padding: "0px 2px",
  },
  selectButton: {
    width: "100%",
    height: "38px",
    textAlign: "left",
    marginLeft: "10px",
    marginTop: "2px",
    fontSize: "24px",
  },
  button: {
    backgroundColor: "#fff",
    "&:hover": {
      background: "#d9d9d9",
    },
    height: "40px",
    width: "40px",
    padding: "10px",
  },
  selectList: {
    width: "auto",
    minWidth: "500px",
    marginLeft: "15px",
  },
  disabled: {},
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  // overflowY: "auto",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const RequestDbSearchModal: React.FC<{
  requestSearch: any;
  searchParam: any;
}> = ({ requestSearch, searchParam }) => {
  const dispatch = useDispatch();

  // 検索条件用
  const [id, setId] = React.useState(searchParam.id);
  const [employeeNo, setEmployeeNo] = React.useState(searchParam.employeeNo);
  const [productName, setProductName] = React.useState(searchParam.productName);
  const [makerName, setMakerName] = React.useState(searchParam.makerName);
  const [statusStep, setStatusStep] = React.useState(searchParam.statusStep);
  const [statusCode, setStatusCode] = React.useState(searchParam.statusCode);
  const [statusCheckComplete, setStatusCheckComplete] = React.useState(
    searchParam.statusCheckComplete,
  );
  const [statusCheckDelete, setStatusCheckDelete] = React.useState(
    searchParam.statusCheckDelete,
  );
  const [category, setCategory] = React.useState(searchParam.category);
  const [createdDateFrom, setCreatedDateFrom] = React.useState<DateTime | null>(
    searchParam.createdDateFrom,
  );
  const [createdDateTo, setCreatedDateTo] = React.useState<DateTime | null>(
    searchParam.createdDateTo,
  );
  const handleBaseStartDate = (date: DateTime | null) => {
    setCreatedDateFrom(date);
  };
  const handleBaseEndDate = (date: DateTime | null) => {
    setCreatedDateTo(date);
  };
  const [tenpoCode, setTenpoCode] = React.useState<string[]>(
    searchParam.tenpoCode,
  );
  const [doCode, setDoCode] = React.useState<string[]>(searchParam.doCode);
  const [zoCode, setZoCode] = React.useState<string[]>(searchParam.zoCode);

  const [confirmStep, setConfirmStep] = React.useState(searchParam.confirmStep);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const [summaryLowerLimitDay, setSummaryLowerLimitDay] =
  //   React.useState<DateTime>(DateTime.now());
  // const [summaryUpperLimitDay, setSummaryUpperLimitDay] =
  //   React.useState<DateTime>(DateTime.now());

  // ステータスプルダウンの切り替え用
  const [statusOptions, setStatusOptions] = React.useState<
    { label: string; value: string }[]
  >([]);

  React.useEffect(() => {
    if (statusStep === "0") {
      setStatusOptions([
        { label: "未確認", value: "0" },
        { label: "申請", value: "1" },
        { label: "申請完了", value: "2" },
        { label: "差戻", value: "8" },
        { label: "中止", value: "9" },
      ]);
    } else if (
      statusStep === "1" ||
      statusStep === "2" ||
      statusStep === "3" ||
      statusStep === "4"
    ) {
      setStatusOptions([
        { label: "未確認", value: "0" },
        { label: "リスクあり", value: "1" },
        { label: "問題なし", value: "2" },
        { label: "差戻", value: "8" },
      ]);
    } else if (statusStep === "5") {
      setStatusOptions([
        { label: "未確認", value: "0" },
        { label: "申請実施", value: "1" },
        { label: "微生物検査結果提出", value: "2" },
        { label: "食品検査同意書提出", value: "3" },
        { label: "差戻", value: "8" },
        { label: "申請中止", value: "9" },
      ]);
    } else if (statusStep === "6") {
      setStatusOptions([
        { label: "未確認", value: "0" },
        { label: "表示適合", value: "1" },
        { label: "表示不適合", value: "2" },
        { label: "差戻", value: "8" },
      ]);
    } else if (statusStep === "7") {
      setStatusOptions([
        { label: "未確認", value: "0" },
        { label: "表示適合", value: "1" },
        { label: "表示不適合", value: "9" },
        { label: "差戻", value: "8" },
      ]);
    } else if (statusStep === "8") {
      setStatusOptions([
        { label: "未確認", value: "0" },
        { label: "リスクあり", value: "1" },
        { label: "問題なし", value: "2" },
        { label: "確認不要", value: "3" },
        { label: "差戻", value: "8" },
      ]);
    } else if (statusStep === "9") {
      setStatusOptions([
        { label: "未確認", value: "0" },
        { label: "検査あり", value: "1" },
        { label: "検査なし", value: "2" },
        { label: "検査結果不合格", value: "9" },
        { label: "検査結果合格", value: "4" },
        { label: "食品検査同意書OK", value: "5" },
        { label: "食品検査同意書NG", value: "6" },
        { label: "差戻", value: "8" },
      ]);
    }
  }, [statusStep]);

  // 現在の確認担当：担当プルダウンの変更をハンドリング
  const handleConfirmStepChange = (event: any) => {
    setConfirmStep(event.target.value);
  };

  // ステータス検索：担当プルダウンの変更をハンドリング
  const handleConfirmationChange = (event: any) => {
    setStatusStep(event.target.value);
  };

  // ステータス検索：ステータスプルダウンの変更をハンドリング
  const handleStatusChange = (event: any) => {
    setStatusCode(event.target.value);
  };

  // カテゴリプルダウンの変更をハンドリング
  const handleCategoryChange = (event: any) => {
    setCategory(event.target.value);
  };

  // 店舗入力時処理
  const saveTenpoButton = (
    argTenpoCode: string,
    argTenpoName: string,
    argTenpoTel: string,
  ): void => {
    if (!argTenpoCode) return;
    dispatch(
      addTenpoInfo({
        tenpoInfo: {
          tenpoCode: argTenpoCode,
          tenpoName: argTenpoName,
          tenpoTel: argTenpoTel,
        },
      }),
    );
    setTenpoCode((prevTenpoCodes) => [...prevTenpoCodes, argTenpoCode]);
  };

  // 店舗削除時処理
  const deleteTenpoBottun = (argTenpoCode: string): void => {
    if (!argTenpoCode) return;
    dispatch(
      deleteTenpoInfo({
        tenpoInfo: {
          tenpoCode: argTenpoCode,
          tenpoName: "",
          tenpoTel: "",
        },
      }),
    );
    // 削除が押された対象を削除
    setTenpoCode((prevTenpoCodes) =>
      prevTenpoCodes.filter((code) => code !== argTenpoCode),
    );
  };

  useEffect(() => {}, [tenpoCode]); // tenpoCodeが更新されるたびに実行される

  // ZO入力時処理
  const saveZoBottun = (argZoCode: string, argZoName: string): void => {
    if (!argZoCode) return;
    dispatch(
      addZoInfo({
        zoInfo: {
          zoCode: argZoCode,
          zoName: argZoName,
        },
      }),
    );
    setZoCode((prevTenpoCodes) => [...prevTenpoCodes, argZoCode]);
  };

  // ZO削除時処理
  const deleteZoBottun = (argZoCode: string): void => {
    if (!argZoCode) return;
    dispatch(
      deleteZoInfo({
        zoInfo: {
          zoCode: argZoCode,
          zoName: "",
        },
      }),
    );
    // 削除が押された対象を削除
    setZoCode((prevTenpoCodes) =>
      prevTenpoCodes.filter((code) => code !== argZoCode),
    );
  };

  useEffect(() => {}, [zoCode]); // zoCodeが更新されるたびに実行される

  // DO入力時処理
  const saveDoBottun = (argDoCode: string, argDoName: string): void => {
    if (!argDoCode) return;
    dispatch(
      addDoInfo({
        doInfo: {
          doCode: argDoCode,
          doName: argDoName,
        },
      }),
    );
    setDoCode((prevTenpoCodes) => [...prevTenpoCodes, argDoCode]);
  };

  // DO削除時処理
  const deleteDoBottun = (argDoCode: string): void => {
    if (!argDoCode) return;
    dispatch(
      deleteDoInfo({
        doInfo: {
          doCode: argDoCode,
          doName: "",
        },
      }),
    );
    // 削除が押された対象を削除
    setDoCode((prevTenpoCodes) =>
      prevTenpoCodes.filter((code) => code !== argDoCode),
    );
  };

  useEffect(() => {}, [doCode]); // doCodeが更新されるたびに実行される

  function clearData() {
    // 各stateに対して初期値を設定
    setId("");
    setEmployeeNo("");
    setProductName("");
    setMakerName("");
    setStatusStep("");
    setStatusCode("");
    setStatusCheckComplete("0");
    setStatusCheckDelete("0");
    setCategory("");
    setCreatedDateFrom(null);
    setCreatedDateTo(null);
    tenpoCode.forEach((code: any) => {
      deleteTenpoBottun(code);
    });
    setTenpoCode([]);
    doCode.forEach((code: any) => {
      deleteDoBottun(code);
    });
    setDoCode([]);
    zoCode.forEach((code: any) => {
      deleteZoBottun(code);
    });
    setZoCode([]);
    setConfirmStep("");
  }

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <>
      <Button
        variant="contained"
        style={{ width: "100%", margin: "10px 5px 10px 20px" }}
        onClick={handleOpen}
      >
        条件選択
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        //className={classes.modalPane}
        // sx={{
        //   position: "absolute",
        //   paddingTop: "2%",
        //   paddingLeft: "15%",
        //   width: "70%",
        //   minWidth: "600px",
        //   height: "70%",
        // }}
      >
        <Box sx={style}>
          <Grid
            container
            //className={classes.searchArea}
            sx={{
              border: "solid #d3d3d3",
              flex: 1,
              margin: "auto",
              alignItems: "center",
              width: "70vw",
              backgroundColor: "#fff",
              paddingBottom: "15px",
              overflowY: "auto",
              maxHeight: "70vh",
            }}
          >
            {/*検索欄*/}
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{
                color: "#FFFFFF",
                backgroundColor: "#37AB9D",
                fontWeight: "bolder",
                textAlign: "center",
                height: "40px",
              }}
            >
              条件変更
            </Grid>

            <Grid container>
              {/*L1*/}

              <>
                <Grid container>
                  <Grid
                    style={{
                      paddingTop: "15px",
                      paddingLeft: "15px",
                      fontWeight: "bolder",
                    }}
                  >
                    申請ID
                  </Grid>
                </Grid>

                <Grid
                  container
                  //  className={classes.searchItem}
                  sx={{
                    marginTop: "5px",
                    marginLeft: "10px",
                    marginBottom: "10px",
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box sx={{ minWidth: 120 }}>
                    <TextField
                      size="small"
                      variant="outlined"
                      value={id}
                      onChange={(event) => {
                        if (event.target.value.length <= 10) {
                          setId(event.target.value);
                        }
                      }}
                    />
                  </Box>
                </Grid>
              </>

              <Grid
                container
                style={{
                  borderTop: "1px solid #aaa",
                }}
              >
                <Grid
                  style={{
                    paddingTop: "15px",
                    paddingLeft: "15px",
                    fontWeight: "bolder",
                  }}
                >
                  OFC社員ID
                </Grid>
              </Grid>
              <Grid
                container
                //className={classes.searchItem}
                sx={{
                  marginTop: "5px",
                  marginLeft: "10px",
                  marginBottom: "10px",
                }}
                alignItems="center"
                justifyContent="center"
              >
                {" "}
                <Box sx={{ minWidth: 120 }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={employeeNo}
                    onChange={(event) => {
                      if (event.target.value.length <= 100) {
                        setEmployeeNo(event.target.value);
                      }
                    }}
                  />
                </Box>
              </Grid>

              {/*L3*/}
              <Grid
                container
                style={{
                  borderTop: "1px solid #aaa",
                }}
              >
                <Grid
                  style={{
                    paddingTop: "15px",
                    paddingLeft: "15px",
                    fontWeight: "bolder",
                  }}
                >
                  商品名
                </Grid>
              </Grid>
              <Grid
                container
                //className={classes.searchItem}
                sx={{
                  marginTop: "5px",
                  marginLeft: "10px",
                  marginBottom: "10px",
                }}
                alignItems="center"
                justifyContent="center"
              >
                {" "}
                <Box sx={{ minWidth: 120 }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={productName}
                    onChange={(event) => {
                      if (event.target.value.length <= 100) {
                        setProductName(event.target.value);
                      }
                    }}
                  />
                </Box>
              </Grid>

              <Grid
                container
                style={{
                  borderTop: "1px solid #aaa",
                }}
              >
                <Grid
                  style={{
                    paddingTop: "15px",
                    paddingLeft: "15px",
                    fontWeight: "bolder",
                  }}
                >
                  メーカー名
                </Grid>
              </Grid>
              <Grid
                container
                //className={classes.searchItem}
                sx={{
                  marginTop: "5px",
                  marginLeft: "10px",
                  marginBottom: "10px",
                }}
                alignItems="center"
                justifyContent="center"
              >
                {" "}
                <Box sx={{ minWidth: 120 }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={makerName}
                    onChange={(event) => {
                      if (event.target.value.length <= 100) {
                        setMakerName(event.target.value);
                      }
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                borderTop: "1px solid #aaa",
              }}
            >
              <Grid
                style={{
                  paddingTop: "15px",
                  paddingLeft: "15px",
                  fontWeight: "bolder",
                }}
              >
                現在の確認担当
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              sx={{
                marginTop: "5px",
                marginLeft: "10px",
                marginBottom: "10px",
              }}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Grid container direction="row" spacing={3} alignItems="center">
                  <Grid item>
                    <Typography>担当：</Typography>
                  </Grid>
                  <Grid item>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <Select
                          value={confirmStep}
                          onChange={handleConfirmStepChange}
                          size="small"
                        >
                          <MenuItem value={""}>未選択</MenuItem>
                          <MenuItem value={"0"}>OFC</MenuItem>
                          <MenuItem value={"1"}>DM</MenuItem>
                          <MenuItem value={"2"}>ZM</MenuItem>
                          <MenuItem value={"3"}>MD</MenuItem>
                          <MenuItem value={"4"}>CMD/SMD</MenuItem>
                          <MenuItem value={"6"}>外部業者</MenuItem>
                          <MenuItem value={"7"}>QC</MenuItem>
                          <MenuItem value={"8"}>QC上長</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                borderTop: "1px solid #aaa",
              }}
            >
              <Grid
                style={{
                  paddingTop: "15px",
                  paddingLeft: "15px",
                  fontWeight: "bolder",
                }}
              >
                ステータス検索
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              sx={{
                marginTop: "5px",
                marginLeft: "10px",
                marginBottom: "10px",
              }}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Grid container direction="row" spacing={3} alignItems="center">
                  <Grid item>
                    <Typography>担当：</Typography>
                  </Grid>
                  <Grid item>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <Select
                          value={statusStep}
                          onChange={handleConfirmationChange}
                          size="small"
                        >
                          <MenuItem value={""}>未選択</MenuItem>
                          <MenuItem value={"0"}>OFC</MenuItem>
                          <MenuItem value={"1"}>DM</MenuItem>
                          <MenuItem value={"2"}>ZM</MenuItem>
                          <MenuItem value={"3"}>MD</MenuItem>
                          <MenuItem value={"4"}>CMD/SMD</MenuItem>
                          <MenuItem value={"5"}>OFC：申請判断</MenuItem>
                          <MenuItem value={"6"}>外部業者：表示確認</MenuItem>
                          <MenuItem value={"7"}>QC：表示確認</MenuItem>
                          <MenuItem value={"8"}>QC上長：表示確認</MenuItem>
                          <MenuItem value={"9"}>QC：微生物検査</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>ステータス：</Typography>
                  </Grid>
                  <Grid item>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <Select
                          value={statusCode}
                          onChange={handleStatusChange}
                          size="small"
                        >
                          {statusOptions.map((option) => (
                            <MenuItem value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>{" "}
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                >
                  <Grid item>
                    <Grid container alignItems="center">
                      <Checkbox
                        {...label}
                        // checkedがtrueのとき、statusCheckCompleteは1、falseのときは0に設定
                        checked={statusCheckComplete === 1}
                        onChange={(e) =>
                          // チェックボックスが選択されていれば1、されていなければ0に設定
                          setStatusCheckComplete(e.target.checked ? 1 : 0)
                        }
                      />
                      <Typography>申請が完了したデータを表示する</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center">
                      <Checkbox
                        {...label}
                        // checkedがtrueのとき、statusCheckCompleteは1、falseのときは0に設定
                        checked={statusCheckDelete === 1}
                        onChange={(e) =>
                          // チェックボックスが選択されていれば1、されていなければ0に設定
                          setStatusCheckDelete(e.target.checked ? 1 : 0)
                        }
                      />
                      <Typography>削除されたデータを表示する</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                borderTop: "1px solid #aaa",
              }}
            >
              <Grid
                style={{
                  paddingTop: "15px",
                  paddingLeft: "15px",
                  fontWeight: "bolder",
                }}
              >
                カテゴリー
              </Grid>
            </Grid>
            <Grid
              container
              //className={classes.searchItem}
              sx={{
                marginTop: "5px",
                marginLeft: "10px",
                marginBottom: "10px",
              }}
              alignItems="center"
              justifyContent="center"
            >
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Select
                    value={category}
                    onChange={handleCategoryChange}
                    size="small"
                  >
                    <MenuItem value={"01"}>ビール</MenuItem>
                    <MenuItem value={"02"}>日本酒</MenuItem>
                    <MenuItem value={"03"}>洋酒・ワイン・雑酒</MenuItem>
                    <MenuItem value={"04"}>米飯</MenuItem>
                    <MenuItem value={"05"}>調理パン</MenuItem>
                    <MenuItem value={"06"}>麺類・その他</MenuItem>
                    <MenuItem value={"07"}>デリカテッセン</MenuItem>
                    <MenuItem value={"08"}>デザート</MenuItem>
                    <MenuItem value={"09"}>牛乳・乳飲料</MenuItem>
                    <MenuItem value={"10"}>乳製品</MenuItem>
                    <MenuItem value={"11"}>玉子</MenuItem>
                    <MenuItem value={"12"}>生活デイリー</MenuItem>
                    <MenuItem value={"13"}>パンぺストリー</MenuItem>
                    <MenuItem value={"14"}>菓子</MenuItem>
                    <MenuItem value={"15"}>加工食品</MenuItem>
                    <MenuItem value={"16"}>調味料・乾物</MenuItem>
                    <MenuItem value={"17"}>米穀</MenuItem>
                    <MenuItem value={"18"}>し好品</MenuItem>
                    <MenuItem value={"19"}>珍味</MenuItem>
                    <MenuItem value={"20"}>缶詰</MenuItem>
                    <MenuItem value={"21"}>冷凍食品</MenuItem>
                    <MenuItem value={"22"}>アイスクリーム</MenuItem>
                    <MenuItem value={"23"}>ソフトドリンク</MenuItem>
                    <MenuItem value={"24"}>栄養ドリンク</MenuItem>
                    <MenuItem value={"25"}>健康栄養食品</MenuItem>
                    <MenuItem value={"26"}>その他</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid
              container
              style={{
                borderTop: "1px solid #aaa",
              }}
            >
              <Grid
                style={{
                  paddingTop: "15px",
                  paddingLeft: "15px",
                  fontWeight: "bolder",
                }}
              >
                申請日
              </Grid>
            </Grid>
            <Grid
              container
              //className={classes.searchItem}
              sx={{
                marginTop: "5px",
                marginLeft: "10px",
                marginBottom: "10px",
              }}
              alignItems="center"
              justifyContent="center"
            >
              <Grid xs={1}></Grid>
              <Grid item xs={5}>
                <Grid container justifyContent="flex-end">
                  <DatePicker
                    selectedDate={createdDateFrom}
                    dateFunc={(date: DateTime | null) => {
                      handleBaseStartDate(date);
                    }}
                    format="yyyy/MM/dd"
                  />
                  <Button onClick={() => handleBaseStartDate(null)}>
                    クリア
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <Grid container justifyContent="center">
                  ～
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid container justifyContent="flex-start">
                  <DatePicker
                    selectedDate={createdDateTo}
                    dateFunc={(date: DateTime | null) => {
                      handleBaseEndDate(date);
                    }}
                    format="yyyy/MM/dd"
                  />
                  <Button onClick={() => handleBaseEndDate(null)}>
                    クリア
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                borderTop: "1px solid #aaa",
              }}
            >
              <Grid
                style={{
                  paddingTop: "15px",
                  paddingLeft: "15px",
                  fontWeight: "bolder",
                }}
              >
                店舗選択
              </Grid>
            </Grid>

            <Grid
              container
              //className={classes.searchItem}
              sx={{
                marginTop: "5px",
                marginLeft: "10px",
                marginBottom: "10px",
              }}
              alignItems="center"
              justifyContent="center"
            >
              <SearchTenpo
                styles={stylesSearchTenpo}
                disabled={false}
                firstView={false}
                toggleStatus={false}
                searchMode={0}
                onClickFunc={(
                  tenpoCode: string,
                  tenpoName: string,
                  tenpoTel: string,
                ) => {
                  saveTenpoButton(tenpoCode, tenpoName, tenpoTel);
                }}
                deleteFunc={(
                  tenpoCode: string,
                  tenpoName: string,
                  tenpoTel: string,
                ) => {
                  deleteTenpoBottun(tenpoCode);
                }}
              />
            </Grid>

            <Grid
              container
              style={{
                borderTop: "1px solid #aaa",
              }}
            >
              <Grid
                style={{
                  paddingTop: "15px",
                  paddingLeft: "15px",
                  fontWeight: "bolder",
                }}
              >
                DO検索
              </Grid>
            </Grid>

            <Grid
              container
              //className={classes.searchItem}
              sx={{
                marginTop: "5px",
                marginLeft: "10px",
                marginBottom: "10px",
              }}
              alignItems="center"
              justifyContent="center"
            >
              <SearchDO
                styles={stylesSearchTenpo}
                disabled={false}
                firstView={false}
                toggleStatus={false}
                searchMode={0}
                onClickFunc={(doCode: string, doName: string) => {
                  saveDoBottun(doCode, doName);
                }}
                deleteFunc={(doCode: string, doName: string) => {
                  deleteDoBottun(doCode);
                }}
              />
            </Grid>

            <Grid
              container
              style={{
                borderTop: "1px solid #aaa",
              }}
            >
              <Grid
                style={{
                  paddingTop: "15px",
                  paddingLeft: "15px",
                  fontWeight: "bolder",
                }}
              >
                ZO検索
              </Grid>
            </Grid>
            <Grid
              container
              //className={classes.searchItem}
              sx={{
                marginTop: "5px",
                marginLeft: "10px",
                marginBottom: "10px",
              }}
              alignItems="center"
              justifyContent="center"
            >
              <SearchZO
                styles={stylesSearchTenpo}
                disabled={false}
                firstView={false}
                toggleStatus={false}
                searchMode={0}
                onClickFunc={(zoCode: string, zoName: string) => {
                  saveZoBottun(zoCode, zoName);
                }}
                deleteFunc={(zoCode: string, zoName: string) => {
                  deleteZoBottun(zoCode);
                }}
              />
            </Grid>

            {/* 3月以降に実装予定 }
            <Grid
              container
              style={{
                borderTop: "1px solid #aaa",
              }}
            >
              <Grid
                style={{
                  paddingTop: "15px",
                  paddingLeft: "15px",
                  fontWeight: "bolder",
                }}
              >
                地区MDエリア
              </Grid>
            </Grid>
            <Grid
              container
              //className={classes.searchItem}
              sx={{
                marginTop: "5px",
                marginLeft: "10px",
                marginBottom: "10px",
              }}
              alignItems="center"
              justifyContent="center"
            >
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <Select value={test} onChange={handleChange} size="small">
                    <MenuItem value={951601}>地区MD統括部（北海道）</MenuItem>
                    <MenuItem value={951602}>地区MD統括部（東北）</MenuItem>
                    <MenuItem value={951603}>地区MD統括部（北関東）</MenuItem>
                    <MenuItem value={951613}>地区MD統括部（首都圏）</MenuItem>
                    <MenuItem value={951627}>
                      地区MD統括部（甲信越・北陸）
                    </MenuItem>
                    <MenuItem value={951630}>地区MD統括部（東海）</MenuItem>
                    <MenuItem value={951635}>地区MD統括部（関西）</MenuItem>
                    <MenuItem value={951637}>
                      地区MD統括部（中国・四国）
                    </MenuItem>
                    <MenuItem value={951640}>地区MD統括部（九州）</MenuItem>
                    <MenuItem value={951650}>地区MD統括部（沖縄）</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            { */}
          </Grid>
          <Grid
            container
            //  className={classes.searchItem}
            sx={{
              marginTop: "5px",
              marginLeft: "10px",
              marginBottom: "10px",
            }}
            alignItems="center"
            justifyContent="center"
          >
            <Grid container justifyContent="center">
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <Button
                  //   className={classes.excuteButton}
                  sx={{
                    color: "#ffffff",
                    backgroundColor: "#4dc0b2",
                    fontSize: "20px",
                    marginLeft: "4vw",
                    "&:hover": {
                      backgroundColor: "#4ab5a1",
                    },
                  }}
                  variant="contained"
                  color="primary"
                  // disabled={!executeButtonFlg}
                  onClick={() => {
                    requestSearch(
                      id,
                      employeeNo,
                      productName,
                      makerName,
                      statusStep,
                      statusCode,
                      statusCheckComplete,
                      statusCheckDelete,
                      category,
                      createdDateFrom,
                      createdDateTo,
                      tenpoCode,
                      doCode,
                      zoCode,
                      confirmStep,
                    );
                    handleClose();
                  }}
                >
                  実行
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  //   className={classes.excuteButton}
                  sx={{
                    color: "#ffffff",
                    backgroundColor: "#4dc0b2",
                    fontSize: "20px",
                    marginLeft: "4vw",
                    "&:hover": {
                      backgroundColor: "#4ab5a1",
                    },
                  }}
                  variant="contained"
                  color="primary"
                  // disabled={!executeButtonFlg}
                  onClick={() => {
                    clearData();
                  }}
                >
                  クリア
                </Button>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
export default RequestDbSearchModal;
