import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import SearchTenpo from "../organisms/search/searchTenpo/SearchTenpo";
import {
  addTenpoInfo,
  deleteTenpoInfo,
} from "../organisms/search/SummarySearchSlice";
import { useDispatch } from "react-redux";

// import { selectTenpoInfoList } from "../organisms/search/SummarySearchSlice";

const stylesSearchTenpo = {
  paper: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "80%",
    backgroundColor: "#fff",
    border: "2px solid #000",
    bgcolor: "background.paper",
    boxShadow: "0px 3px 5px -1px rgba(0,0,0,0,2)",
    padding: "16px 32px 24px",
    p: 4,
    overflowY: "auto",
  },
  listPaper: {
    height: "87%",
    overflow: "auto",
    padding: "0px 0px",
    marginTop: "25px",
  },
  searchButton: {
    width: "90%",
    maxWidth: "600px",
    marginLeft: "15px",
  },
  searchTextField: {
    padding: "0px 2px",
  },
  selectButton: {
    width: "100%",
    height: "38px",
    textAlign: "left",
    marginLeft: "10px",
    marginTop: "2px",
    fontSize: "24px",
  },
  button: {
    backgroundColor: "#fff",
    "&:hover": {
      background: "#d9d9d9",
    },
    height: "40px",
    width: "40px",
    padding: "10px",
  },
  selectList: {
    width: "auto",
    minWidth: "500px",
    marginLeft: "15px",
  },
  disabled: {},
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const RequestInputTenpoModal: React.FC<{}> = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const [tenpoCode, setTenpoCode] = React.useState("");

  // 店舗入力時処理
  const saveTenpoButton = (
    argTenpoCode: string,
    argTenpoName: string,
    argTenpoTel: String,
  ): void => {
    if (!argTenpoCode) return;
    dispatch(
      addTenpoInfo({
        tenpoInfo: {
          tenpoCode: argTenpoCode,
          tenpoName: argTenpoName,
          tenpoTel: argTenpoTel,
        },
      }),
    );
    setTenpoCode(argTenpoCode);
  };

  // 店舗削除時処理
  const deleteTenpoBottun = (argTenpoCode: string): void => {
    if (!argTenpoCode) return;
    dispatch(
      deleteTenpoInfo({
        tenpoInfo: {
          tenpoCode: argTenpoCode,
          tenpoName: "",
          tenpoTel: "",
        },
      }),
    );
  };

  return (
    <>
      <Button
        variant="contained"
        style={{ width: "100%", margin: "10px 5px 10px 20px" }}
        onClick={handleOpen}
      >
        店舗選択*
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid item xs={12}>
            <Typography variant="h3">店舗選択</Typography>
          </Grid>

          <Grid
            container
            style={{
              borderTop: "1px solid #aaa",
              marginBottom: "20px",
              padding: "10px",
            }}
          >
            <Grid item xs={1.5}>
              {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                店舗を選択
              </Typography> */}
              店舗を選択
            </Grid>
            <Grid item xs={10.5}>
              <SearchTenpo
                styles={stylesSearchTenpo}
                disabled={false}
                firstView={false}
                toggleStatus={false}
                searchMode={1}
                onClickFunc={(
                  tenpoCode: string,
                  tenpoName: string,
                  tenpoTel: string,
                ) => {
                  saveTenpoButton(tenpoCode, tenpoName, tenpoTel);
                }}
                deleteFunc={(
                  tenpoCode: string,
                  tenpoName: string,
                  tenpoTel: string,
                ) => {
                  deleteTenpoBottun(tenpoCode);
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: "10vh", marginBottom: "20px", padding: "10px" }}
          >
            <Grid item xs={2}>
              <Button
                variant="contained"
                style={{ width: "70%", margin: "2px" }}
                onClick={handleClose}
              >
                閉じる
              </Button>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={2}>
              {/* <Button
                variant="contained"
                style={{ width: "70%", margin: "2px" }}
                onClick={() => {
                  inputError();
                }}
              >
                決定
              </Button> */}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
export default RequestInputTenpoModal;
