/* ---------------------------------------------------------------------------------- */
import React, { useState, useEffect, useCallback } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Checkbox,
  FormControl,
  Button,
  CardMedia,
  Box,
  Modal,
} from "@mui/material";
import Header from "../common/Header";
import { RequestDbSearchModal } from "./RequestDbSearchModal";
import { styled } from "@mui/system";
import { userLogging } from "../../apis/userLog";

import { postRequest } from "../../apis/axiosAction";
import { useSelector, useDispatch } from "react-redux";
import { initializeExistingSejStores } from "../../data/existingSejStores";
import {
  getAllDo,
  getDo,
  getAllZone,
  getZone,
  getAllExistingSejStores,
  getExistingSejStore,
} from "../../data/existingSejStores";
import { selectImageToken, selectUser } from "../../features/userSlice";
import { getImageFile } from "../common/utils";
import CashPurchaseDialog from "./CashPurchaseDialog";
import { openWebSite } from "../../apis/util";
import { category } from "./RequestComentModal";
import {
  searchItemData,
  inputSearchData,
} from "../organisms/search/SummarySearchSlice";
import { DateTime } from "luxon";

/* ---------------------------------------------------------------------------------- */
/*
const dataRow = [
  {
    id: "a000001-20240123101901",
    employee_no: "a000001",
    employee_name: "OFC 太郎",
    create_date: "2024/1/23",
    do_code: "000001",
    do_name: "テストＤＯ",
    zo_code: "001",
    zo_name: "テストＺＯ",
    request_step: "1",
    request_data_json: {
      "0": {
        name: "ofc太郎",
        mailaddress: "ofc-tarou@sej.7andi.co.jp",
        status: "未確認",
        comment: "abcdefg",
        creaet_date: "2024/1/23",
        creaet_user: "test",
        update_date: "2024/1/23",
        update_user: "test",
        display_name: "OFC",
      },
      "1": {
        name: "dm太郎",
        mailaddress: "db-tarou@sej.7andi.co.jp",
        status: "未確認",
        comment: "abcdefg",
        creaet_date: "2024/1/23",
        creaet_user: "test",
        update_date: "2024/1/23",
        update_user: "test",
        display_name: "DM",
      },
    },
    product_name: "テスト商品1",
    maker_name: "テストメーカー1",
    request_tenpo: ["000001", "000002"],
    image1: "/image/sampleImage1.png",
  },

  {
    id: "a000002-20240124090000",
    employee_no: "a000001",
    employee_name: "OFC 太郎",
    create_date: "2024/1/23",
    do_code: "000001",
    do_name: "テストＤＯ",
    zo_code: "001",
    zo_name: "テストＺＯ",
    request_step: "2",
    request_data_json: {
      "0": {
        name: "ofc太郎",
        mailaddress: "ofc-tarou@sej.7andi.co.jp",
        status: "未確認",
        comment: "abcdefg",
        creaet_date: "2024/1/23",
        creaet_user: "test",
        update_date: "2024/1/23",
        update_user: "test",
        display_name: "OFC",
      },
      "1": {
        name: "dm太郎",
        mailaddress: "db-tarou@sej.7andi.co.jp",
        status: "未確認",
        comment: "abcdefg",
        creaet_date: "2024/1/23",
        creaet_user: "test",
        update_date: "2024/1/23",
        update_user: "test",
        display_name: "DM",
      },
      "2": {
        name: "zm太郎",
        mailaddress: "db-tarou@sej.7andi.co.jp",
        status: "未確認",
        comment: "abcdefg",
        creaet_date: "2024/1/24",
        creaet_user: "test",
        update_date: "2024/1/24",
        update_user: "test",
        display_name: "ZM",
      },
    },
    product_name: "テスト商品2",
    maker_name: "テストメーカー2",
    request_tenpo: ["000001", "000002"],
    image1: "/image/sampleImage2.png",
  },

  {
    id: "a000001-20240124101122",
    employee_no: "a000001",
    employee_name: "OFC 太郎",
    create_date: "2024/1/23",
    do_code: "000001",
    do_name: "テストＤＯ",
    zo_code: "001",
    zo_name: "テストＺＯ",
    request_step: "1",
    request_data_json: {
      "0": {
        name: "ofc太郎",
        mailaddress: "ofc-tarou@sej.7andi.co.jp",
        status: "差戻",
        comment: "abcdefg",
        creaet_date: "2024/1/23",
        creaet_user: "test",
        update_date: "2024/1/23",
        update_user: "test",
        display_name: "OFC",
      },
      "1": {
        name: "dm太郎",
        mailaddress: "db-tarou@sej.7andi.co.jp",
        status: "差戻",
        comment: "abcdefg",
        creaet_date: "2024/1/23",
        creaet_user: "test",
        update_date: "2024/1/23",
        update_user: "test",
        display_name: "DM",
      },
    },
    product_name: "テスト商品3",
    maker_name: "テストメーカー3",
    request_tenpo: ["000001", "000002"],
    image1: "/image/sampleImage3.png",
  },

  {
    id: "a000001-20231231235959",
    employee_no: "a000001",
    employee_name: "OFC 太郎",
    create_date: "2024/1/23",
    do_code: "000001",
    do_name: "テストＤＯ",
    zo_code: "001",
    zo_name: "テストＺＯ",
    request_step: "0",
    request_data_json: {
      "0": {
        name: "ofc太郎",
        mailaddress: "ofc-tarou@sej.7andi.co.jp",
        status: "申請完了",
        comment: "abcdefg",
        creaet_date: "2024/1/23",
        creaet_user: "test",
        update_date: "2024/1/23",
        update_user: "test",
        display_name: "OFC",
      },
      "1": {
        name: "dm太郎",
        mailaddress: "db-tarou@sej.7andi.co.jp",
        status: "未確認",
        comment: "abcdefg",
        creaet_date: "2024/1/23",
        creaet_user: "test",
        update_date: "2024/1/23",
        update_user: "test",
        display_name: "DM",
      },
    },
    product_name: "テスト商品4",
    maker_name: "テストメーカー4",
    request_tenpo: ["000001", "000002"],
    image1: "/image/sampleImage4.png",
  },
];
*/

type ApiDataType = {
  id: string;
  image1: string;
  productName: string;
  zoName: string;
  doName: string;
  tenpoName: [];
  employeeName: string;
  status: string;
  createDate: string;
  confirmStep: string;
  updateDate: string;
  confirmDataUpdateDate: string;
};

const nameDisplay = [
  { step: "0", display: "OFC" },
  { step: "1", display: "DM" },
  { step: "2", display: "ZM" },
  { step: "3", display: "MD" },
  { step: "4", display: "CMD/SMD" },
  { step: "5", display: "OFC：申請判断" },
  { step: "6", display: "外部業者：表示確認" },
  { step: "7", display: "QC：表示確認" },
  { step: "8", display: "QC上長：表示確認" },
  { step: "9", display: "QC：微生物検査" },
];

const statusDisplay = [
  {
    step: "0",
    "0": "未申請",
    "1": "申請",
    "2": "申請完了",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "中止",
  },
  {
    step: "1",
    "0": "未確認",
    "1": "リスクあり",
    "2": "問題なし",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "",
  },
  {
    step: "2",
    "0": "未確認",
    "1": "リスクあり",
    "2": "問題なし",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "",
  },
  {
    step: "3",
    "0": "未確認",
    "1": "リスクあり",
    "2": "問題なし",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "",
  },
  {
    step: "4",
    "0": "未確認",
    "1": "リスクあり",
    "2": "問題なし",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "",
  },
  {
    step: "5",
    "0": "未確認",
    "1": "申請実施",
    "2": "微生物検査結果提出",
    "3": "食品検査同意書提出",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "申請中止",
  },
  {
    step: "6",
    "0": "未確認",
    "1": "表示適合",
    "2": "表示不適合",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "",
  },
  {
    step: "7",
    "0": "未確認",
    "1": "表示適合",
    "2": "",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "表示不適合",
  },
  {
    step: "8",
    "0": "未確認",
    "1": "リスクあり",
    "2": "問題なし",
    "3": "確認不要",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "",
  },
  {
    step: "9",
    "0": "未確認",
    "1": "検査あり",
    "2": "検査なし",
    "3": "",
    "4": "微生物検査結果合格",
    "5": "食品検査同意書OK",
    "6": "食品検査同意書NG",
    "7": "",
    "8": "差戻",
    "9": "微生物検査結果不合格",
  },
];

const checkModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  Height: "10%",
  minWidth: "20%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

/* ---------------------------------------------------------------------------------- */
const setVhVariable = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};
window.addEventListener("resize", setVhVariable);
window.addEventListener("load", setVhVariable);

const circularProgress = css`
  position: absolute;
  top: 35%;
  left: 45%;
  opacity: 0.8;
  color: #ccc;
  // opacity: 0.5;
`;

const titleArea = css`
  height: calc(var(--vh, 1vh) * 5);
  width: 95vw;
`;
const title = css`
  margin: 5px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
`;

const contentsAreaAll = css`
  height: calc(var(--vh, 1vh) * 78);
  width: 90vw;
  overflow: auto;
`;

const contentsAreaButton = css`
  height: calc(var(--vh, 1vh) * 8);
  width: 90vw;
`;

const content = css`
  margin: 10px 5px 10px 20px;
  color: #000;
`;

const TableBase = styled(Grid)(({ theme }) => ({
  marginTop: "20px",
  backgroundColor: "#d9d9d9",
  padding: "1px",
  width: "175vw",
  overflowX: "auto",
  overflowY: "auto",
  maxHeight: "70vh",
  // whiteSpace: "nowrap",
  [theme.breakpoints.down("sm")]: {
    width: "125vw",
    // backgroundColor: "red",
  },
  [theme.breakpoints.up("lg")]: {
    width: "125vw", // サイズ以上のデバイスではwidthを変更
    // backgroundColor: "blue",
  },
}));

const ColumnHeader = styled(Grid)({
  color: "white",
  outlineColor: "#3e3e3e",
  backgroundColor: "#4dc0b2",
  margin: "2px",
});

const HeaderHeight = styled(Grid)({
  height: "7vh",
});

const HeaderHeightCheck = styled(Grid)({
  height: "7vh",
  textDecoration: "underline", // 常に下線を引く
  fontWeight: "bold", // 常に太字にする
  "&:hover": {
    opacity: "1",
    cursor: "pointer",
  },
});

const ColumnResult = styled(Grid)({
  color: "#3e3e3e",
  backgroundColor: "#ffffff",
  verticalAlign: "middle",
  margin: "2px",
  wordWrap: "break-word",
  overflowWrap: "anywhere",
});

const SelectLine = styled(Grid)({
  "&:hover": {
    textDecoration: "underline",
    opacity: "1",
    cursor: "pointer",
    backgroundColor: "#d8e8e6 !important",
  },
});

const ImageBox = styled(Box)({
  cursor: "pointer",
  position: "relative",
  paddingBottom: "75%", // アスペクト比を 4:3 に設定
  height: 0,
  overflow: "hidden",
});

//データ表示用の配列
let resultList: any = {};

// カテゴリープルダウン用設定
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/* ---------------------------------------------------------------------------------- */
const RequestDb: React.FC<{}> = () => {
  const windowName = "現金仕入システム";
  // 一括確認権限（2：ZM、6：外部業者：表示確認、7：QC：表示確認、8：QC上長：表示確認、9：QC：微生物検査）
  const bulkConfirmAllowed = ["2", "6", "7", "8", "9"];
  // step毎のデフォルトOKステータス
  const stepOkStatus: { [key: string]: string } = {
    "2": "2", // 2：問題なし
    "6": "1", // 1：表示適合
    "7": "1", // 1：表示適合
    "8": "2", // 2：問題なし
    "9": "2", // 2：検査なし
  };
  // 帳票出力権限（6：外部業者：表示確認）
  const printAllowed = ["6"];
  // カテゴリー選択対象（7：QC：表示確認）
  const categorySelectTarget = ["7"];
  // 担当確認step
  type HandleConfirmStep = {
    [step: string]: string[];
  };
  const handleConfirmStep: HandleConfirmStep = {
    "0": ["0", "5"],
    "1": ["1"],
    "2": ["2"],
    "3": ["3"],
    "4": ["4"],
    "5": ["5"],
    "6": ["6"],
    "7": ["7", "9"],
    "8": ["8"],
  };
  // 担当ステップか判定
  const isHandleStep = (loginUser: string, step: string): boolean => {
    return handleConfirmStep[loginUser].includes(step);
  };

  const navigate = useNavigate();

  const [windowHeight, setWindowHeight] = useState(0);
  const dispatch = useDispatch();

  // 検索条件
  const selectSearch = useSelector(searchItemData); //初期値をSelectorから受け取り

  const [searchParam, setSearchParam] = React.useState({
    id: selectSearch.id,
    employeeNo: selectSearch.employeeNo,
    productName: selectSearch.productName,
    makerName: selectSearch.makerName,
    statusStep: selectSearch.statusStep,
    statusCode: selectSearch.statusCode,
    statusCheckComplete: selectSearch.statusCheckComplete,
    statusCheckDelete: selectSearch.statusCheckDelete,
    category: selectSearch.category,
    createdDateFrom: selectSearch.createdDateFrom
      ? DateTime.fromISO(selectSearch.createdDateFrom)
      : null,
    createdDateTo: selectSearch.createdDateTo
      ? DateTime.fromISO(selectSearch.createdDateTo)
      : null,
    tenpoCode: selectSearch.tenpoCode,
    doCode: selectSearch.doCode,
    zoCode: selectSearch.zoCode,
    confirmStep: selectSearch.confirmStep,
  });

  const [apiData, setApiData] = React.useState<ApiDataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState<Record<string, boolean>>({});

  //登録確認Modalの開閉
  const [checkModalOpen, setCheckModalOpen] = React.useState(false);
  const handleCheckModalOpen = () => setCheckModalOpen(true);
  const handleCheckModalClose = () => setCheckModalOpen(false);

  //登録完了Modalの開閉
  const [completeOpen, setCompleteOpen] = React.useState(false);
  const handleCompleteOpen = () => setCompleteOpen(true);
  const handleCompleteClose = () => setCompleteOpen(false);
  const handleCompleteCloseForBack = (
    event: React.MouseEvent<HTMLInputElement>,
    reason: string,
  ) => {
    if (reason === "backdropClick") return;
    setCompleteOpen(false);
  };

  const userInfo = useSelector(selectUser);
  const loginUser = userInfo.userAuth;
  const imageToken = useSelector(selectImageToken);

  // メッセージ用ダイアログ設定
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);

  useEffect(() => {
    // CSVファイルから店舗、DO、ZO情報読み込み
    initializeExistingSejStores(imageToken);
  }, []);

  /* ---------------------------------------------------------------------------------- */

  //　ダイアログの処理
  const [dialogRefs, setDialogRefs] = React.useState<
    (React.RefObject<HTMLDialogElement> | null)[]
  >([]);

  const handleClickInDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
    },
    [],
  );

  // ダイアログ閉じる
  const dialogClose = () => {
    setDialogFlg(false);
  };

  /* ---------------------------------------------------------------------------------- */
  //データ取得
  async function fetchData({
    id = "",
    employeeNo = "",
    productName = "",
    makerName = "",
    statusStep = "",
    statusCode = "",
    statusCheckComplete = "0",
    statusCheckDelete = "0",
    category = "",
    createdDateFrom = "",
    createdDateTo = "",
    tenpoCode = [],
    doCode = [],
    zoCode = [],
    confirmStep = "",
  } = {}) {
    setLoading(true);

    let apiParams = {
      query: `
        query {
          listAllSearch(
            input: {
              id: "${id}",
              employeeNo: "${employeeNo}",
              productName: "${productName}",
              makerName: "${makerName}",
              step: "${statusStep}",
              status: "${statusCode}",
              completeFlag: "${statusCheckComplete}",
              deleteFlag: "${statusCheckDelete}",
              qcCategory: "${category}",
              dateFrom: "${createdDateFrom}",
              dateTo: "${createdDateTo}",
              tenpoCode: ${JSON.stringify(tenpoCode)},
              doCode: ${JSON.stringify(doCode)},
              zoCode: ${JSON.stringify(zoCode)},
              confirmStep: "${confirmStep}",
            }
          ) {
            id
            image1
            productName
            zoName
            doName
            tenpoName
            employeeName
            status
            createDate
            confirmStep
            updateDate
            confirmDataUpdateDate
          }
        }
      `,
    };

    const url = process.env.REACT_APP_API_URL + "graphql";
    await postRequest(url, apiParams)
      .then((response) => {
        const data = response.data.data.listAllSearch;
        setChecked(
          data.reduce(
            (acc: any, cur: any) => ({ ...acc, [cur.id]: false }),
            {},
          ),
        );
        setApiData(data); // 取得したデータをステートにセット
        const newDialogRefs = data.map(() =>
          React.createRef<HTMLDialogElement>(),
        ); // 新しいdialogRefsを作成
        setDialogRefs(newDialogRefs);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error); // エラーハンドリング
        setDialogMessage([String(error)]);
        setDialogFlg(true);
      });
  }

  const [renderCount, setRenderCount] = useState(selectSearch.count);

  useEffect(() => {
    // OFCの場合、社員ID検索条件に設定
    if (renderCount === 0) {
      if (loginUser === "0") {
        setSearchParam({
          ...searchParam,
          employeeNo: userInfo.employeeNo, // 社員IDを検索条件に設定
          confirmStep: loginUser, // ログイン者の確認対象データが初期表示されるように検索条件を設定
        });
        // 初回レンダリング時にデータを取得
        fetchData({
          confirmStep: loginUser,
          employeeNo: userInfo.employeeNo,
        });
      } else {
        setSearchParam({
          ...searchParam,
          confirmStep: loginUser, // ログイン者の確認対象データが初期表示されるように検索条件を設定
        });
        // 初回レンダリング時にデータを取得
        fetchData({
          confirmStep: loginUser,
        });
      }
      setRenderCount(renderCount + 1);
    } else if (renderCount > 0) {
      //検索情報の受け渡し
      fetchData({
        id: searchParam.id,
        employeeNo: searchParam.employeeNo,
        productName: searchParam.productName,
        makerName: searchParam.makerName,
        statusStep: searchParam.statusStep,
        statusCode: searchParam.statusCode,
        statusCheckComplete: searchParam.statusCheckComplete,
        statusCheckDelete: searchParam.statusCheckDelete,
        category: searchParam.category,
        createdDateFrom: searchParam.createdDateFrom
          ? searchParam.createdDateFrom.toFormat("yyyy/MM/dd")
          : undefined,
        createdDateTo: searchParam.createdDateTo
          ? searchParam.createdDateTo.toFormat("yyyy/MM/dd")
          : undefined,
        tenpoCode: searchParam.tenpoCode,
        doCode: searchParam.doCode,
        zoCode: searchParam.zoCode,
        confirmStep: searchParam.confirmStep,
      });
      setSearchParam({ ...searchParam });
    }
  }, []);

  apiData && (resultList = apiData);

  /* ---------------------------------------------------------------------------------- */
  //サーバ時間を変換
  const changeDate = (date: any) => {
    const newDate = new Date(date);
    const formattedDate = `${newDate.getFullYear()}/${
      newDate.getMonth() + 1
    }/${newDate.getDate()}`;
    return <>{formattedDate}</>;
  };

  /* ---------------------------------------------------------------------------------- */
  //チェックの更新
  const handleChangeChack = (event: any) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const statusUnconfirmed = "0";
  //全選択、全解除
  const toggleAll = () => {
    setChecked((prev) => {
      const allChecked: Record<string, boolean> = { ...prev };
      const stepTwoKeys = resultList
        .filter((item: any) => isHandleStep(loginUser, item.confirmStep))
        .filter((item: any) => item.status === statusUnconfirmed)
        .map((item: any) => item.id);

      const newValue = !stepTwoKeys.every((key: any) => prev[key]);

      stepTwoKeys.forEach((key: any) => {
        allChecked[key] = newValue;
      });

      return allChecked;
    });
  };

  // 一括確認権限がある場合ボタンを表示
  const isBulkConfirmUser = bulkConfirmAllowed.includes(loginUser);

  // 一括確認権限があり1つでもチェックがついていたらボタンを有効化
  const isBulkConfirm =
    Object.values(checked).some((value) => value) &&
    bulkConfirmAllowed.includes(loginUser);

  // 一括出力権限がある場合ボタンを表示
  const isPrintUser = printAllowed.includes(loginUser);

  // 一括出力権限があり1つでもチェックがついて、チェックが10以下ならボタンを有効化
  const isPrint =
    Object.values(checked).some((value) => value === true) &&
    Object.values(checked).filter((value) => value === true).length <= 10 &&
    printAllowed.includes(loginUser);

  /* ---------------------------------------------------------------------------------- */
  //一括確認

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [responseList, setResponseList] = useState<
    { id: any; response: any }[]
  >([]);
  const [selectCategory, setSelectCategory] = useState(""); // QCのカテゴリー選択の情報保持
  const [isCategory, setIsCategory] = useState(false); // カテゴリープルダウン表示有無
  const [isBulkOkAbailable, setIsBulkOkAbailable] = useState(false); // 一括確認時のOKボタン押下可否
  useEffect(() => {
    const newHasCategoryTarget = Object.keys(checked)
      .filter((key) => checked[key])
      .some((key) => {
        const target = apiData.find((item) => item.id === key);
        return target
          ? categorySelectTarget.includes(target.confirmStep)
          : false;
      });
    setIsCategory(newHasCategoryTarget);
    setIsBulkOkAbailable(!newHasCategoryTarget || selectCategory !== "");
  }, [checked, apiData, selectCategory]);

  async function confirmRequestAll() {
    const selectedKeys: any = Object.keys(checked).filter(
      (key) => checked[key],
    );

    setConfirmLoading(true);

    const promises = selectedKeys.map((key: any) => {
      let targetRow = apiData.find((item) => item.id === key)!;
      const step = targetRow.confirmStep;
      //const nextStep = (Number(step) + 1).toString();
      let nextStep;
      if (step === "9") {
        nextStep = "0";
      } else {
        nextStep = (Number(step) + 1).toString();
      }
      // 申請完了の場合
      let completeFlag = "0";
      if (nextStep === "0") {
        completeFlag = "1";
      }
      const userName = userInfo?.employeeName;
      const updateDate = targetRow.updateDate;
      const confirmDataUpdateDate = targetRow.confirmDataUpdateDate;
      const categoryExp = categorySelectTarget.includes(targetRow.confirmStep)
        ? `qcCategory:"${selectCategory}", `
        : "";

      let apiParams = {
        query:
          "mutation { confirmRequest(input:" +
          `{id:"${key}", ` +
          `step:"${step}", ` +
          `updateUser:"${userName}", ` +
          `recordUpdateDate:"${updateDate}",` +
          `updateDate:"${confirmDataUpdateDate}",` +
          categoryExp +
          `nextStep:"${nextStep}", ` +
          `status:"${stepOkStatus[step]}",` +
          'returnFlag:"0",' +
          `completeFlag:"${completeFlag}", ` +
          'comment:"（一括確認）"' +
          "})}",
      };

      const url = process.env.REACT_APP_API_URL + "graphql";
      return postRequest(url, apiParams)
        .then((response) => {
          setResponseList((prevState) => [
            ...prevState,
            { id: key, response: response.data.data.confirmRequest },
          ]);
          const data = Array.isArray(response.data.data)
            ? response.data.data
            : [response.data.data];
          setApiData(data); // 取得したデータをステートにセット
        })
        .catch((error) => {
          console.error(error); // エラーハンドリング
          setDialogMessage([String(error)]);
          setDialogFlg(true);
        });
    });
    //ループ処理が終わってから以降の処理を行う
    await Promise.all(promises);
    //画面の再読み込み

    if (renderCount === 0) {
      if (loginUser === "0") {
        setSearchParam({
          ...searchParam,
          employeeNo: userInfo.employeeNo, // 社員IDを検索条件に設定
          confirmStep: loginUser, // ログイン者の確認対象データが初期表示されるように検索条件を設定
        });
        // 初回レンダリング時にデータを取得
        fetchData({
          confirmStep: loginUser,
          employeeNo: userInfo.employeeNo,
        });
      } else {
        setSearchParam({
          ...searchParam,
          confirmStep: loginUser, // ログイン者の確認対象データが初期表示されるように検索条件を設定
        });
        // 初回レンダリング時にデータを取得
        fetchData({
          confirmStep: loginUser,
        });
      }
      setRenderCount(renderCount + 1);
    } else if (renderCount > 0) {
      //検索情報の受け渡し
      fetchData({
        id: searchParam.id,
        employeeNo: searchParam.employeeNo,
        productName: searchParam.productName,
        makerName: searchParam.makerName,
        statusStep: searchParam.statusStep,
        statusCode: searchParam.statusCode,
        statusCheckComplete: searchParam.statusCheckComplete,
        statusCheckDelete: searchParam.statusCheckDelete,
        category: searchParam.category,
        createdDateFrom: searchParam.createdDateFrom
          ? searchParam.createdDateFrom.toFormat("yyyy/MM/dd")
          : undefined,
        createdDateTo: searchParam.createdDateTo
          ? searchParam.createdDateTo.toFormat("yyyy/MM/dd")
          : undefined,
        tenpoCode: searchParam.tenpoCode,
        doCode: searchParam.doCode,
        zoCode: searchParam.zoCode,
        confirmStep: searchParam.confirmStep,
      });
      setSearchParam({ ...searchParam });
    }
    setConfirmLoading(false);
    handleCompleteOpen();
  }

  /* ---------------------------------------------------------------------------------- */
  // 一括出力

  // ダウンロードModalの開閉
  const [downloadModalOpen, setDownloadModalOpen] = React.useState(false);
  const [downloadUrl, setDownloadUrl] = React.useState("");
  const handleDownloadOpen = () => setDownloadModalOpen(true);
  const handleDownloadClose = () => setDownloadModalOpen(false);

  const handleDownload = () => {
    openWebSite(downloadUrl);
  };

  async function printAll(): Promise<void> {
    setLoading(true);
    const selectedKeys = Object.keys(checked).filter((key) => checked[key]);

    const url: string = process.env.REACT_APP_API_URL + "output-report";
    const param = {
      report_id: selectedKeys,
    };
    try {
      const response = await postRequest(url, param, false);
      const filePaths: string[] = response.data.dataList.map(
        (item: any) => item.file_path,
      );
      const filepath =
        process.env.REACT_APP_CLOUD_STORAGE_PRINT_URL + filePaths[0];
      setDownloadUrl(filepath);
      handleDownloadOpen();
    } catch (error) {
      // 必要があれば
      console.error(error); // エラーハンドリング
      setDialogMessage([String(error)]);
      setDialogFlg(true);
    }

    setLoading(false);
  }

  /* ---------------------------------------------------------------------------------- */
  //商品検索
  const requestSearch = (
    id: any,
    employeeNo: any,
    productName: any,
    makerName: any,
    statusStep: any,
    statusCode: any,
    statusCheckComplete: any,
    statusCheckDelete: any,
    category: any,
    createdDateFrom: any,
    createdDateTo: any,
    tenpoCode: [],
    doCode: [],
    zoCode: [],
    confirmStep: any,
  ) => {
    // 検索条件をstateに保存
    setSearchParam({
      id,
      employeeNo,
      productName,
      makerName,
      statusStep,
      statusCode,
      statusCheckComplete,
      statusCheckDelete,
      category,
      createdDateFrom,
      createdDateTo,
      tenpoCode,
      doCode,
      zoCode,
      confirmStep,
    });

    const dateFrom = createdDateFrom?.toISO();
    const dateTo = createdDateTo?.toISO();

    createdDateFrom = createdDateFrom
      ? createdDateFrom.toFormat("yyyy/MM/dd")
      : "";
    createdDateTo = createdDateTo ? createdDateTo.toFormat("yyyy/MM/dd") : "";

    //データを取得
    fetchData({
      id,
      employeeNo,
      productName,
      makerName,
      statusStep,
      statusCode,
      statusCheckComplete,
      statusCheckDelete,
      category,
      createdDateFrom,
      createdDateTo,
      tenpoCode,
      doCode,
      zoCode,
      confirmStep,
    });

    dispatch(
      inputSearchData({
        id: id,
        employeeNo: employeeNo,
        productName: productName,
        makerName: makerName,
        statusStep: statusStep,
        statusCode: statusCode,
        statusCheckComplete: statusCheckComplete,
        statusCheckDelete: statusCheckDelete,
        category: category,
        createdDateFrom: dateFrom ? dateFrom : null,
        createdDateTo: dateTo ? dateTo : null,
        tenpoCode: tenpoCode,
        doCode: doCode,
        zoCode: zoCode,
        confirmStep: confirmStep,
        count: renderCount,
      }),
    );
  };

  /* ---------------------------------------------------------------------------------- */
  //検索情報のデータ初期化
  const clearData = () => {
    //setSearched("");
    // setRows([...dataRow]);
    // resultList = dataRow;
    //setSearchItem([]);
  };

  const clickReference = (index: any) => {
    // 画面遷移
    navigate("/requestDbContents", { state: { data: resultList[index].id } });
  };

  /* ---------------------------------------------------------------------------------- */

  function getDisplayName(step: any) {
    const rule = nameDisplay.find((rule) => rule.step === step);
    return rule ? rule.display : null;
  }

  function getDisplayStatus(step: any, status: string) {
    const rule: any = statusDisplay.find((rule) => rule.step === step);
    return rule ? rule[status] : null;
  }

  // 一括処理できるユーザか判定
  const isAllowBuklkUser = (): boolean => {
    return (
      bulkConfirmAllowed.includes(loginUser) || printAllowed.includes(loginUser)
    );
  };

  // 申請ID列の幅。一括処理列がない場合はその分を吸収する
  const requestIdWide = isAllowBuklkUser() ? 1.5 : 1.8;

  // 一括確認できるか判定。担当stepに該当、かつ、一括処理が許可されているstepであること
  const isAllowBulk = (confirmStep: string, statusStep: string): boolean => {
    return (
      isHandleStep(loginUser, confirmStep) &&
      (bulkConfirmAllowed.includes(confirmStep) ||
        printAllowed.includes(confirmStep)) &&
      statusStep === "0"
    );
  };
  /* ～～～～～～～～～～～～～～～～～～～～～～～～～～～～～～ */
  useEffect(() => {
    // window.setTimeout(dispMsg, timer);
    setWindowHeight(window.innerHeight * 0.905);
    userLogging("現金仕入", "現金仕入申請データベース#初期表示", "");
  }, []);

  /* ---------------------------------------------------------------------------------- */

  //店舗情報ソート
  // const clickSort = (sortKey: string) => {
  //   let mapList: any[] = [];
  //   for (let i = 0; i < resultList[0].length; i++) {
  //     mapList.push(resultList[0][i]);
  //   }

  //   let asc = true;
  //   if (sortingKey === sortKey) {
  //     asc = false;
  //   }
  //   resultList[0] = mapList.sort(function (first, second) {
  //     if (first[sortKey] > second[sortKey]) {
  //       return asc ? 1 : -1;
  //     } else if (first[sortKey] < second[sortKey]) {
  //       return asc ? -1 : 1;
  //     } else {
  //       return 0;
  //     }
  //   });
  //   if (asc) {
  //     setSortingKey(sortKey);
  //   } else {
  //     setSortingKey("");
  //   }
  // };

  /* ---------------------------------------------------------------------------------- */
  return loading ? (
    <div>
      <CircularProgress css={circularProgress} size={120} />
      <Header windowName={windowName} />
    </div>
  ) : (
    <>
      <Header windowName={windowName} />
      <Grid container alignItems="center" justifyContent="center">
        {/* タイトルエリア-START */}
        <Grid container css={titleArea}>
          <Grid item xs={5}>
            <Typography variant="h3" css={title}>
              現金仕入申請データベース
            </Typography>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            <RequestDbSearchModal
              requestSearch={requestSearch}
              searchParam={searchParam}
            />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        {/* タイトルエリア-END */}

        {/* データベース-START */}
        <Grid container css={contentsAreaAll}>
          {/* メインコンテンツ↓ここから */}
          {/* チェック表↓ここから */}
          <Grid item>
            {resultList && (
              <TableBase container>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "#d9d9d9",
                    zIndex: 2,
                  }}
                >
                  <Grid item lg={0.3} md={0.3} sm={0.3}>
                    <ColumnHeader item>
                      <HeaderHeight
                        container
                        justifyContent="center"
                        alignItems="center"
                        // onClick={() => clickSort("index")}
                      >
                        No.
                      </HeaderHeight>
                    </ColumnHeader>
                  </Grid>

                  {isAllowBuklkUser() ? (
                    <Grid item lg={0.3} md={0.3} sm={0.3}>
                      <ColumnHeader item>
                        <HeaderHeightCheck
                          container
                          justifyContent="center"
                          alignItems="center"
                          onClick={() => toggleAll()}
                        >
                          一括
                          <br />
                          処理
                        </HeaderHeightCheck>
                      </ColumnHeader>
                    </Grid>
                  ) : (
                    ""
                  )}

                  <Grid
                    item
                    lg={requestIdWide}
                    md={requestIdWide}
                    sm={requestIdWide}
                  >
                    <ColumnHeader item>
                      <HeaderHeight
                        container
                        justifyContent="center"
                        alignItems="center"
                        //onClick={() => clickSort("id")}
                      >
                        申請ID
                      </HeaderHeight>
                    </ColumnHeader>
                  </Grid>

                  <Grid item lg={0.7} md={0.7} sm={0.7}>
                    <ColumnHeader item>
                      <HeaderHeight
                        container
                        justifyContent="center"
                        alignItems="center"
                        //  onClick={() => clickSort("tenpoCode")}
                      >
                        画像
                      </HeaderHeight>
                    </ColumnHeader>
                  </Grid>

                  <Grid item lg={1.45} md={1.45} sm={1.45}>
                    <ColumnHeader item>
                      <HeaderHeight
                        container
                        justifyContent="center"
                        alignItems="center"
                        // onClick={() => clickSort("productName")}
                      >
                        商品名
                      </HeaderHeight>
                    </ColumnHeader>
                  </Grid>

                  <Grid item lg={1} md={1} sm={1}>
                    <ColumnHeader item>
                      <HeaderHeight
                        container
                        justifyContent="center"
                        alignItems="center"
                        // onClick={() => clickSort("zoName")}
                      >
                        ZO名
                      </HeaderHeight>
                    </ColumnHeader>
                  </Grid>

                  <Grid item lg={1} md={1} sm={1}>
                    <ColumnHeader item>
                      <HeaderHeight
                        container
                        justifyContent="center"
                        alignItems="center"
                        //onClick={() => clickSort("DoName")}
                      >
                        DO名
                      </HeaderHeight>
                    </ColumnHeader>
                  </Grid>

                  <Grid item lg={1.75} md={1.75} sm={1.75}>
                    <ColumnHeader item>
                      <HeaderHeight
                        container
                        justifyContent="center"
                        alignItems="center"
                        //    onClick={() => clickSort("tenpoName")}
                      >
                        店舗名
                      </HeaderHeight>
                    </ColumnHeader>
                  </Grid>
                  <Grid item lg={1.25} md={1.25} sm={1.25}>
                    <ColumnHeader item>
                      <HeaderHeight
                        container
                        justifyContent="center"
                        alignItems="center"
                        // onClick={() => clickSort("step")}
                      >
                        申請担当者
                      </HeaderHeight>
                    </ColumnHeader>
                  </Grid>
                  <Grid item lg={1.75} md={1.75} sm={1.75}>
                    <ColumnHeader item>
                      <HeaderHeight
                        container
                        justifyContent="center"
                        alignItems="center"
                        // onClick={() => clickSort("check")}
                      >
                        申請ステータス
                      </HeaderHeight>
                    </ColumnHeader>
                  </Grid>

                  <Grid item lg={1} md={1} sm={1}>
                    <ColumnHeader item>
                      <HeaderHeight
                        container
                        justifyContent="center"
                        alignItems="center"
                        // onClick={() => clickSort("create_date")}
                      >
                        申請日
                      </HeaderHeight>
                    </ColumnHeader>
                  </Grid>
                </Grid>

                {resultList.map((row: any, index: any) => (
                  <SelectLine
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item lg={0.3} md={0.3} sm={0.3}>
                      <ColumnResult item>
                        <Grid
                          container
                          sx={{ height: "12vh" }}
                          alignItems="center"
                          justifyContent="center"
                          onClick={() => {
                            clickReference(index);
                          }}
                        >
                          {index + 1}
                        </Grid>
                      </ColumnResult>
                    </Grid>

                    {isAllowBuklkUser() ? (
                      <Grid item lg={0.3} md={0.3} sm={0.3}>
                        <ColumnResult item>
                          <Grid
                            container
                            sx={{ height: "12vh" }}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Checkbox
                              checked={checked[row.id] || false}
                              onChange={handleChangeChack}
                              name={row && row.id ? row.id.toString() : ""}
                              disabled={
                                !isAllowBulk(row.confirmStep, row.status)
                              }
                            />
                            <br />
                            {!isAllowBulk(row.confirmStep, row.status) && (
                              <span>不可</span>
                            )}
                          </Grid>
                        </ColumnResult>
                      </Grid>
                    ) : (
                      ""
                    )}

                    <Grid
                      item
                      lg={requestIdWide}
                      md={requestIdWide}
                      sm={requestIdWide}
                    >
                      <ColumnResult item>
                        <Grid
                          container
                          sx={{ height: "12vh" }}
                          alignItems="center"
                          justifyContent="center"
                          onClick={() => {
                            clickReference(index);
                          }}
                        >
                          {row.id}
                        </Grid>
                      </ColumnResult>
                    </Grid>

                    <Grid item lg={0.7} md={0.7} sm={0.7}>
                      <ColumnResult item>
                        <Grid
                          container
                          sx={{ height: "12vh" }}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <FormControl fullWidth>
                            <div
                              role="button"
                              onClick={() =>
                                dialogRefs[index]?.current?.showModal()
                              }
                              onKeyDown={() =>
                                dialogRefs[index]?.current?.showModal()
                              }
                              className="image-box"
                              tabIndex={0}
                            >
                              <ImageBox>
                                <CardMedia
                                  component="img"
                                  image={getImageFile(row.image1, imageToken)}
                                  alt=""
                                  sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </ImageBox>
                            </div>
                            <dialog
                              ref={dialogRefs[index]}
                              className="image-dialog"
                              onClick={() =>
                                dialogRefs[index]?.current?.close()
                              }
                            >
                              <div
                                onClick={handleClickInDialog}
                                className="contents"
                              >
                                <img
                                  src={getImageFile(row.image1, imageToken)}
                                  alt=""
                                  width="100%"
                                />
                              </div>
                            </dialog>
                          </FormControl>
                        </Grid>
                      </ColumnResult>
                    </Grid>

                    <Grid item lg={1.45} md={1.45} sm={1.45}>
                      <ColumnResult item>
                        <Grid
                          container
                          sx={{ height: "12vh" }}
                          alignItems="center"
                          justifyContent="center"
                          onClick={() => {
                            clickReference(index);
                          }}
                        >
                          {row.productName}
                        </Grid>
                      </ColumnResult>
                    </Grid>

                    <Grid item lg={1} md={1} sm={1}>
                      <ColumnResult item>
                        <Grid
                          container
                          sx={{ height: "12vh" }}
                          alignItems="center"
                          justifyContent="center"
                          onClick={() => {
                            clickReference(index);
                          }}
                        >
                          {row.zoName}
                        </Grid>
                      </ColumnResult>
                    </Grid>

                    <Grid item lg={1} md={1} sm={1}>
                      <ColumnResult item>
                        <Grid
                          container
                          sx={{ height: "12vh" }}
                          alignItems="center"
                          justifyContent="center"
                          onClick={() => {
                            clickReference(index);
                          }}
                        >
                          {row.doName}
                        </Grid>
                      </ColumnResult>
                    </Grid>

                    <Grid item lg={1.75} md={1.75} sm={1.75}>
                      <ColumnResult item>
                        <Grid
                          container
                          sx={{ height: "12vh" }}
                          alignItems="center"
                          justifyContent="center"
                          onClick={() => {
                            clickReference(index);
                          }}
                        >
                          {/* {row.tenpoName.join("、")} */}
                          {Array.isArray(row.tenpoName)
                            ? row.tenpoName.join("、")
                            : row.tenpoName}
                        </Grid>
                      </ColumnResult>
                    </Grid>

                    <Grid item lg={1.25} md={1.25} sm={1.25}>
                      <ColumnResult item>
                        <Grid
                          container
                          sx={{ height: "12vh" }}
                          alignItems="center"
                          justifyContent="center"
                          onClick={() => {
                            clickReference(index);
                          }}
                        >
                          {row.employeeName}
                        </Grid>
                      </ColumnResult>
                    </Grid>

                    <Grid item lg={1.75} md={1.75} sm={1.75}>
                      <ColumnResult item>
                        <Grid
                          container
                          sx={{ height: "12vh" }}
                          alignItems="center"
                          justifyContent="center"
                          onClick={() => {
                            clickReference(index);
                          }}
                        >
                          【{getDisplayName(row.confirmStep)}】：
                          {getDisplayStatus(row.confirmStep, row.status)}
                        </Grid>
                      </ColumnResult>
                    </Grid>

                    <Grid item lg={1} md={1} sm={1}>
                      <Grid
                        item
                        sx={{
                          color: "#3e3e3e",
                          backgroundColor: "#ffffff",
                          verticalAlign: "middle",
                          margin: "2px",
                        }}
                        onClick={() => {
                          clickReference(index);
                        }}
                      >
                        <Grid
                          container
                          sx={{ height: "12vh" }}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {changeDate(row.createDate)}
                        </Grid>
                      </Grid>
                    </Grid>
                  </SelectLine>
                ))}
              </TableBase>
            )}
          </Grid>

          {/* チェック表↑ここまで */}

          {/* メインコンテンツ↑ここまで */}
        </Grid>

        <Grid container css={contentsAreaButton}>
          <Grid container xs={12}>
            <Grid item xs={8}></Grid>
            {isPrintUser ? (
              <Grid item xs={2}>
                <Button
                  disabled={!isPrint}
                  variant="contained"
                  style={{ width: "90%", margin: "2px" }}
                  onClick={() => {
                    printAll();
                  }}
                >
                  一括出力
                </Button>
                <Typography
                  style={{ fontSize: 12 }}
                  color="textSecondary"
                  align="center"
                >
                  ※1度に出力できるのは10件まで
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={2}></Grid>
            )}

            {isBulkConfirmUser ? (
              <Grid item xs={2}>
                <Button
                  disabled={!isBulkConfirm}
                  variant="contained"
                  style={{ width: "90%", margin: "2px" }}
                  onClick={() => {
                    handleCheckModalOpen();
                    setSelectCategory("");
                  }}
                >
                  一括確認
                </Button>
              </Grid>
            ) : (
              <Grid item xs={2}></Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={checkModalOpen}
        onClose={handleCheckModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={checkModalStyle}>
          {confirmLoading ? (
            <CircularProgress size={36} />
          ) : (
            <>
              <Grid container alignContent="center">
                <Grid item xs={12}>
                  <Typography fontSize={16} component="h2">
                    一括確認してもよろしいでしょうか？
                    <br />
                    （こちらを選択するとすべて問題なしの結果で送信されます）
                  </Typography>
                </Grid>
                {isCategory ? (
                  <>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography css={content}>
                            商品カテゴリー：
                          </Typography>
                        </Grid>
                        <Grid item>
                          <FormControl sx={{ width: 200 }}>
                            <Select
                              size="small"
                              defaultValue=""
                              MenuProps={MenuProps}
                              onChange={(e) =>
                                setSelectCategory(category[e.target.value])
                              }
                            >
                              {Object.keys(category).map((key) => (
                                <MenuItem key={key} value={key}>
                                  {key}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography>
                            ※商品カテゴリーは表示確認の申請のみ反映されます
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
                <Grid item xs={6}>
                  <Button
                    disabled={!isBulkOkAbailable}
                    variant="contained"
                    style={{
                      width: "90%",
                      margin: "2px",
                      backgroundColor: "#C4C4C4",
                      color: "black",
                    }}
                    onClick={() => {
                      confirmRequestAll();
                    }}
                  >
                    はい
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    style={{
                      width: "90%",
                      margin: "2px",
                      backgroundColor: "#C4C4C4",
                      color: "black",
                    }}
                    onClick={handleCheckModalClose}
                  >
                    いいえ
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>

      <Modal
        open={completeOpen}
        onClose={handleCompleteCloseForBack}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={checkModalStyle}>
          <Grid container alignContent="center">
            <Grid item xs={12}>
              <Typography fontSize={16} component="h2">
                {responseList.map((item) => {
                  if (item.response === "duplication_error") {
                    return (
                      <>
                        ID:{item.id}
                        <br />
                      </>
                    );
                  }
                })}
                {responseList.some(
                  (item) => item.response === "duplication_error",
                ) ? (
                  <p>
                    他のユーザーにより対象データが更新されています。
                    <br />
                    データの確認をお願いします。
                  </p>
                ) : responseList.some((item) => item.response === "error") ? (
                  "エラーが発生しました。もう一度試してください。"
                ) : (
                  "登録完了しました"
                )}
              </Typography>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                style={{
                  width: "90%",
                  margin: "2px",
                  backgroundColor: "#C4C4C4",
                  color: "black",
                }}
                onClick={() => {
                  handleCheckModalClose();
                  handleCompleteClose();
                }}
              >
                閉じる
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Box>
      </Modal>

      {/* ダウンロード確認モーダルstart */}
      <Modal
        open={downloadModalOpen}
        onClose={handleDownloadClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={checkModalStyle}>
          <>
            <Grid container alignContent="center">
              <Grid item xs={12}>
                <Typography fontSize={16} component="h2">
                  帳票の作成が完了しました。
                  <br />
                  ダウンロードしますか？
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  style={{
                    width: "90%",
                    margin: "2px",
                    backgroundColor: "#C4C4C4",
                    color: "black",
                  }}
                  onClick={() => {
                    handleDownloadClose();
                    handleDownload();
                  }}
                >
                  ダウンロード
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  style={{
                    width: "90%",
                    margin: "2px",
                    backgroundColor: "#C4C4C4",
                    color: "black",
                  }}
                  onClick={handleDownloadClose}
                >
                  キャンセル
                </Button>
              </Grid>
            </Grid>
          </>
        </Box>
      </Modal>
      {/* ダウンロード確認モーダルend */}
      {/* ダイアログ */}
      <CashPurchaseDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogClose}
        doNo={dialogClose}
        isAlert={true}
      />
    </>
  );
};
export default RequestDb;
