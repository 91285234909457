import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectTenpoInfoList } from "../SummarySearchSlice";

import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getAllExistingSejStores,
  getExistingSejStore,
} from "../../../../data/existingSejStores";
import { selectUser } from "../../../../features/userSlice";

// export interface TENPO_RECORD {
//   // 各アプリケーション共通項目
//   tenpo_code: string;
//   tenpo_name: string;
//   tenpo_tel: string;
// }

// // 店舗の型のオブジェクトの集まり
// export interface INTERFACE_TENPO_DATA {
//   [index: string]: TENPO_RECORD;
// }

// // 店舗の型
// export interface INTERFACE_TENPO {
//   tenpo_data: INTERFACE_TENPO_DATA;
//   count: number;
// }

// // DOの初期値
// const initialTenpo: INTERFACE_TENPO = {
//   tenpo_data: {
//     "003745": {
//       tenpo_code: "003745",
//       tenpo_name: "七瀬　有）高田屋戸谷春実商店",
//       tenpo_tel: "026-228-5693",
//     },
//     "009314": {
//       tenpo_code: "009314",
//       tenpo_name: "須坂南",
//       tenpo_tel: "026-245-6468",
//     },
//     "020853": {
//       tenpo_code: "020853",
//       tenpo_name: "山ノ内佐野",
//       tenpo_tel: "0269-33-8701",
//     },
//     "067458": {
//       tenpo_code: "067458",
//       tenpo_name: "須坂井上",
//       tenpo_tel: "026-248-6388",
//     },
//   },
//   count: 0,
// };

// const tenpoData = {
//   tenpo: initialTenpo,
// };

// // 店舗情報の全量の情報を返す
// export const getAllTenpo = () => {
//   return tenpoData.tenpo;
// };

// // 店舗Codeのある店舗のデータを返す
// export const getTenpo = (tenpoCode: string): TENPO_RECORD => {
//   return tenpoData.tenpo.tenpo_data[tenpoCode];
// };

const PaperDiv = styled("div")<{ props?: any }>(({ props }) => props.paper);
const ListPaperDiv = styled("div")<{ props?: any }>(
  ({ props }) => props.listPaper,
);
const SearchButton = styled("button")<{ props?: any }>(
  ({ props }) => props.searchButton,
);
const SelectButtonDiv = styled("div")<{ props?: any }>(
  ({ props }) => props.selectButton,
);

const SearchTenpo: React.FC<{
  styles: any;
  disabled: boolean;
  firstView: boolean;
  toggleStatus: boolean;
  searchMode: number;
  onClickFunc: any;
  deleteFunc: any;
}> = ({
  styles,
  disabled,
  firstView,
  toggleStatus,
  searchMode,
  onClickFunc,
  deleteFunc,
}) => {
  const [open, setOpen] = React.useState(false);
  // 検索文字列
  const [searchWord, setSearchWord] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const user = useSelector(selectUser);
  //const user = { tenpoCode: ["001", "004", "007"] };
  const selectTenpoList = useSelector(selectTenpoInfoList);

  const MAX_SEARCH_LIST = 150;
  const tenpoMaster = getAllExistingSejStores().store_data;

  const [tenpoList, setTenpoList] = React.useState([""]);
  // 検索文字列が更新された際に動く
  useEffect(() => {
    // 申請はOFCの店舗のみ表示、検索は全店舗表示、
    if (searchMode === 1) {
      setTenpoList(userTenpoButton());
    } else {
      setTenpoList(allTenpoButton());
    }
  }, [searchWord]);
  //}, [searchWord]);

  function userTenpoButton() {
    let tenpoList: any[] = [""];
    let tenpoCode: any = "";
    let tenpoName: any = "";
    let tenpoTel: any = "";
    let hitCount: number = 0;

    for (let i = 0; i < user.tenpoCode.length; i++) {
      tenpoCode = user.tenpoCode[i];
      if (tenpoCode === "") return tenpoList;
      tenpoName = tenpoMaster.get(user.tenpoCode[i])?.tenpo_name;
      tenpoTel = tenpoMaster.get(user.tenpoCode[i])?.tenpo_tel;
      // 検索文字列にマッチしたものだけリストに入れる
      if (
        tenpoCode.match(searchWord) ||
        tenpoName.match(searchWord) ||
        tenpoTel.match(searchWord)
      ) {
        tenpoList.push(
          <Button
            key={i}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => {
              onClickFunc(
                user.tenpoCode[i],
                tenpoMaster.get(user.tenpoCode[i])?.tenpo_name,
                tenpoMaster.get(user.tenpoCode[i])?.tenpo_tel,
              );
              setOpen(false);
            }}
          >
            <SelectButtonDiv props={styles}>
              {user.tenpoCode[i]}：
              {tenpoMaster.get(user.tenpoCode[i])?.tenpo_name}
              {/* {tenpoMaster.get(user.tenpoCode[i])?.tenpo_tel} */}
            </SelectButtonDiv>
          </Button>,
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    return tenpoList;
  }

  function allTenpoButton() {
    let tenpoList: any[] = [""];
    tenpoList = Array.from(tenpoMaster.entries()).map(([key, value]) => {
      if (key === "undefined" || searchWord === "") {
        return null;
        // (
        //   <Button
        //     color="primary"
        //     variant="contained"
        //     fullWidth={true}
        //     onClick={() => {
        //       onClickFunc(value.tenpo_code, value.tenpo_name, value.tenpo_tel);
        //       setOpen(false);
        //     }}
        //   >
        //     <SelectButtonDiv props={styles}>
        //       {value.tenpo_code}：{value.tenpo_name}
        //     </SelectButtonDiv>
        //   </Button>
        // );
      } //else
      if (
        value.tenpo_code.match(searchWord) ||
        value.tenpo_name.match(searchWord) ||
        value.tenpo_tel.match(searchWord)
      ) {
        return (
          <Button
            key={String(key)}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => {
              onClickFunc(value.tenpo_code, value.tenpo_name, value.tenpo_tel);
              setOpen(false);
            }}
          >
            <SelectButtonDiv props={styles}>
              {value.tenpo_code}：{value.tenpo_name}
            </SelectButtonDiv>
          </Button>
        );
      }
      return null;
    });
    return tenpoList; //.slice(0, MAX_SEARCH_LIST);
    // for (let key in tenpoMaster.entries()) {
    //   if (key === "undefined" || searchWord === "") {
    //     tenpoList.push(
    //       <Button
    //         key={key}
    //         color="primary"
    //         variant="contained"
    //         fullWidth={true}
    //         onClick={() => {
    //           onClickFunc(
    //             tenpoMaster.get(key)?.tenpo_code,
    //             tenpoMaster.get(key)?.tenpo_name,
    //             tenpoMaster.get(key)?.tenpo_tel,
    //           );
    //           setOpen(false);
    //         }}
    //       >
    //         <SelectButtonDiv props={styles}>
    //           {tenpoMaster.get(key)?.tenpo_code}：
    //           {tenpoMaster.get(key)?.tenpo_name}
    //         </SelectButtonDiv>
    //       </Button>,
    //     );
    //     // 検索文字列にマッチしたものだけリストに入れる
    //   } else if (
    //     tenpoMaster.get(key)?.tenpo_code.match(searchWord) ||
    //     tenpoMaster.get(key)?.tenpo_name.match(searchWord) ||
    //     tenpoMaster.get(key)?.tenpo_tel.match(searchWord)
    //   ) {
    //     tenpoList.push(
    //       <Button
    //         key={key}
    //         color="primary"
    //         variant="contained"
    //         fullWidth={true}
    //         onClick={() => {
    //           onClickFunc(
    //             tenpoMaster.get(key)?.tenpo_code,
    //             tenpoMaster.get(key)?.tenpo_name,
    //             tenpoMaster.get(key)?.tenpo_tel,
    //           );
    //           setOpen(false);
    //         }}
    //       >
    //         <SelectButtonDiv props={styles}>
    //           {tenpoMaster.get(key)?.tenpo_code}：
    //           {tenpoMaster.get(key)?.tenpo_name}：
    //           {tenpoMaster.get(key)?.tenpo_tel}
    //         </SelectButtonDiv>
    //       </Button>,
    //     );
    //     hitCount++;
    //   }
    //   if (hitCount > MAX_SEARCH_LIST) break;
    // }
    // return tenpoList;
  }

  return (
    <>
      <SearchButton
        onClick={() => {
          handleOpen();
          onClickFunc("", "");
        }}
        disabled={disabled}
        style={{ WebkitAppearance: "none" }}
        props={styles}
      >
        <SelectButtonDiv props={styles}>{"店舗名"}</SelectButtonDiv>
      </SearchButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <PaperDiv props={styles}>
          <TextField
            sx={styles.searchTextField}
            value={searchWord}
            label="店舗コード、店舗名で探す"
            variant="outlined"
            size="medium"
            fullWidth={true}
            disabled={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length <= 100) {
                setSearchWord(e.target.value);
              }
            }}
          />
          <ListPaperDiv props={styles}>{tenpoList}</ListPaperDiv>
        </PaperDiv>
      </Modal>
      {/* <List dense={dense}> */}
      <List sx={styles.selectList}>
        {selectTenpoList.map((value: any, index: number) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  deleteFunc(value.tenpoCode, value.tenpoName, value.tenpoTel);
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText primary={"店舗番号：" + value.tenpoCode} />
            <ListItemText primary={"店舗名：" + value.tenpoName} />
            {/* <ListItemText primary={"電話番号：" + value.tenpoTel} /> */}
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default SearchTenpo;
