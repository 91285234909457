import React, { FC } from "react";
import { styled } from "@mui/material/styles";
// import { createTheme, adaptV4Theme } from "@mui/material";
// import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { DateTime } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
// import { DatePicker as XDatePicker } from "@mui/x-date-pickers/DatePicker";
// import { DesktopDatePicker as XDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker as XDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  usePickerLayout,
  PickersLayoutRoot,
  pickersLayoutClasses,
  PickersLayoutContentWrapper,
} from "@mui/x-date-pickers/PickersLayout";
import InputAdornment from "@mui/material/InputAdornment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

let color = "#4dc0b2";

function DatePickerCustomLayout(props: any) {
  const { toolbar, tabs, content, actionBar } = usePickerLayout(props);
  return (
    <PickersLayoutRoot
      className={pickersLayoutClasses.root}
      ownerState={props}
      sx={{
        [`.${pickersLayoutClasses.toolbar}`]: {
          color: "#fff",
          backgroundColor: "#4dc0b2",
        },
      }}
    >
      {toolbar}
      {actionBar}
      <PickersLayoutContentWrapper
        className={pickersLayoutClasses.contentWrapper}
      >
        {tabs}
        {content}
      </PickersLayoutContentWrapper>
    </PickersLayoutRoot>
  );
}

// TODO other props
const DatePicker: FC<{
  selectedDate: DateTime | null;
  dateFunc: any;
  format?: any;
  // maxDate?: any;
  // minDate?: any;
  // backgroundColor?: string;
  // mainColor?: string;
  // width?: any;
}> = ({
  selectedDate,
  dateFunc,
  format,
  // maxDate,
  // minDate,
  // backgroundColor,
  // mainColor,
  // width,
}) => {
  let maxDate = null;
  let minDate = null;
  let mainColor = "#4dc0b2";

  if (mainColor) {
    color = mainColor;
  } else {
    color = "orange";
  }

  if (!format) {
    format = "yyyy/MM/dd";
  }
  if (!minDate) {
    minDate = DateTime.fromFormat("20210701", "yyyyMMdd");
  }
  if (!maxDate) {
    DateTime.fromFormat(DateTime.local().toString(), "yyyyMMdd");
  }
  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale="ja"
      dateFormats={{ monthAndYear: "yyyy/MM/dd" }}
      localeText={{
        previousMonth: "前月",
        nextMonth: "次月",
        cancelButtonLabel: "閉じる",
        okButtonLabel: "決定",
      }}
    >
      <XDatePicker
        // label="Controlled picker"
        value={selectedDate}
        onChange={(val: DateTime | null) => dateFunc(val)}
        format={format}
        localeText={{ toolbarTitle: "" }}
        slotProps={{
          toolbar: {
            hidden: false,
            toolbarFormat: "M月d日(ccc)",
          },
          textField: {
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            },
          },
        }}
        slots={{
          layout: DatePickerCustomLayout,
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
