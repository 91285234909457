import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { auth } from "../src/firebase/firebase";
import { getParam } from "./components/auth/Login";
import { postRequest } from "./apis/axiosAction";
import { CircularProgress } from "@mui/material";
import { setUserInfo } from "./data/userInfo";
import { userLogging } from "./apis/userLog";
import {
  generateKeyPairRsaOaep,
  exportPublicKey,
  arrayBufferToBase64,
  decryptRsaOaepAndConcatenate,
} from "./apis/cryptoUtils";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { useDispatch } from "react-redux";
import { useDeviceType } from "./apis/util";
import { login, setIsDeviceType, updateImageToken } from "./features/userSlice";
import { IS_AUTH_DISPLAY } from "./apis/privilege";

interface pathState {
  mode: string;
  code: string;
  paramKey: string;
}

const initial: string = "home";

// URLのパス及びパラメータの調整を行う
const setUrlPath = (): pathState => {
  const mode: string =
    getParam("mode", window.location.href) !== ""
      ? getParam("mode", window.location.href)
      : initial;
  const paramKey = mode === "daily-store-multi" ? "docode" : "tenpocode";
  const code: string = getParam(paramKey, window.location.href);
  const value: pathState = { mode, code, paramKey };
  return value;
};

const App: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [loginLoading, setLoginLoading] = useState(false);
  const ps: pathState = setUrlPath();
  // デバイス・OSの判定を呼び出し
  useDeviceType();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const tokenPatamertername = "token";
  const token = query.get(tokenPatamertername);

  useEffect(() => {
    const unSub = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // firebase認証済み
        // 初期処理
        console.log("初期処理");
        userLogging("共通", "ログイン処理", "");
        // 認証
        initialOperation(authUser);
      } else {
        // firebase認証未
        if (IS_AUTH_DISPLAY && token === null) {
          // ローカルまたはクラウド開発環境またはクラウド試験環境ではログイン画面へ遷移する
          navigate("/login");
        } else if (token !== null) {
          callCustomFirebaseAuthentication(token);
        } else {
          // Firebase認証されず、かつデジタルツールから遷移せずにトークンがない場合はCookie経由で認証にトライする
          callUserAuthAfterInitialLogin(true, false, false, false);
        }
      }
    });
    return () => {
      unSub();
    };
    // }, [dispatch, ps]);
  }, [dispatch]);

  useEffect(() => {
    // 定期的にCookie更新とユーザー 情報更新処理を行う
    const intervalTime = 5 * 60 * 1000;
    setInterval(
      () => callUserAuthAfterInitialLogin(false, true, true, true),
      intervalTime,
    );
  }, []);

  // 初期処理
  const initialOperation = async (authUser: any): Promise<void> => {
    // ウェイト表示
    setLoginLoading(true);
    dispatch(
      setIsDeviceType({
        deviceType: true,
        ios: true,
        iphone: true,
      }),
    );
    // 認証
    await callUserAuthAfterInitialLogin(false, true, true, true);
    // ウェイト表示解除
    setLoginLoading(false);
    // URLのパラメータで遷移先を決める
    // navigate(ps.mode + "?" + ps.paramKey + "=" + ps.code);
  };

  /**
   * Firebaseカスタム認証及びユーザー情報取得
   * トークン情報の再利用を防ぐために公開鍵方式を用いる
   * @param token デジタルツールにて生成されたワンタイムトークン
   */
  const callCustomFirebaseAuthentication = async (
    token: string,
  ): Promise<void> => {
    try {
      const url: string = process.env.REACT_APP_API_URL + "users/user-auth";
      // Crypto Web APIでの公開鍵方式を用いて暗号化/複合化を行う
      const keyPair = await generateKeyPairRsaOaep();
      const publicKey = await exportPublicKey(keyPair);
      const publicKeyBase64 = arrayBufferToBase64(publicKey);
      const param = {
        user_token: token,
        public_key: publicKeyBase64,
      };
      const response = await postRequest(url, param, false, false);
      const obj = JSON.parse(response.data.data);
      const storageToken = await decryptRsaOaepAndConcatenate(
        obj["storage_token"],
        keyPair,
      );
      const customToken = await decryptRsaOaepAndConcatenate(
        obj["custom_token"],
        keyPair,
      );
      // Firebaseカスタム認証を行う
      const cAuth = getAuth();
      signInWithCustomToken(cAuth, customToken)
        .then(() => {
          console.log("signInWithCustomToken custom");
        })
        .catch((e: any) => {
          console.log(e);
        });
      // storagetoken設定
      dispatch(
        updateImageToken({
          imageToken: storageToken,
        }),
      );
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * 2回目ログイン以降Cookie処理及びアプリケーション情報やユーザー情報取得
   * 一部トークンでは情報の再利用を防ぐために公開鍵方式を用いる
   * @param token デジタルツールにて生成されたワンタイムトークン
   */
  const callUserAuthAfterInitialLogin = async (
    isCookieAuth: boolean = false,
    isCookieUpdate: boolean = false,
    isUserInfo: boolean = false,
    isStorageToken: boolean = false,
  ): Promise<void> => {
    try {
      const url: string =
        process.env.REACT_APP_API_URL + "users/user-auth-after-initial-login";
      // Crypto Web APIでの公開鍵方式を用いて暗号化/複合化を行う
      const keyPair = await generateKeyPairRsaOaep();
      const publicKey = await exportPublicKey(keyPair);
      const publicKeyBase64 = arrayBufferToBase64(publicKey);
      const param = {
        public_key: publicKeyBase64,
        is_cookie_auth: isCookieAuth,
        is_cookie_update: isCookieUpdate,
        is_user_info: isUserInfo,
        is_storage_token: isStorageToken,
      };
      const response = await postRequest(url, param, false);
      const obj = JSON.parse(response.data.data);
      if (isCookieAuth) {
        const customToken = await decryptRsaOaepAndConcatenate(
          obj["custom_token"],
          keyPair,
        );
        // Firebaseカスタム認証を行う
        const cAuth = getAuth();
        signInWithCustomToken(cAuth, customToken)
          .then(() => {
            console.log("signInWith Cookie Custom Token");
          })
          .catch((e: any) => {
            console.log(e);
            // ログイン失敗時はデジタルツールに遷移する
            console.log("no token");
            window.location.href = process.env.REACT_APP_DIGITAL_TOOLS_URL!;
          });
      }
      if (isUserInfo) {
        const json = obj["userCheck"];

        // 追加でユーザの権限を取得
        let apiParams = {
          query:
            "query {  authorityMasterSearch" +
            '(jobCategoryCodeOa: "' +
            json.job_category_code_OA +
            '", ' +
            'departmentCodeOa:"' +
            json.department_code_OA +
            '") { step }}',
        };
        const url = process.env.REACT_APP_API_URL + "graphql";
        const authResponse = await postRequest(url, apiParams, false);

        let hqDepartmentNameOA = json.hq_department_name_OA?.replace(
          "ＱＣ室",
          "ＱＣ部",
        );
        dispatch(
          login({
            employeeNo: json.employee_no,
            employeeName: json.employee_name,
            jobCategory: json.job_category_code_OA,
            jobCategoryCodeOA: json.job_category_code_OA,
            departmentCodeOA: json.department_code_OA,
            departmentNameOA: json.department_name_OA,
            hqDepartmentCodeOA: json.hq_department_code_OA,
            hqDepartmentNameOA: hqDepartmentNameOA,
            mailAddress: json.mail_address,
            ofcId: json.ofc_code,
            zoCode: json.zo_code_list,
            zoValue: json.zo_values_list,
            doCode: json.do_code_list,
            doValue: json.do_values_list,
            tenpoCode: json.tenpo_code_list,
            tenpoValue: json.tenpo_values_list,
            userAuth: authResponse.data.data.authorityMasterSearch[0].step,
          }),
        );
        setUserInfo(json);
      }
      if (isStorageToken) {
        const storageToken = await decryptRsaOaepAndConcatenate(
          obj["storage_token"],
          keyPair,
        );
        // storagetoken設定
        dispatch(
          updateImageToken({
            imageToken: storageToken,
          }),
        );
      }
    } catch (err) {
      console.error(err);
      // firebase認証未
      if (process.env.REACT_APP_MODE === "develop") {
        // ローカルまたは開発環境ではログイン画面へ遷移する
        navigate("/login");
      } else {
        // ログイン失敗時はデジタルツールに遷移する
        window.location.href = process.env.REACT_APP_DIGITAL_TOOLS_URL!;
      }
    }
  };

  return (
    <>
      {loginLoading ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress
            size={"40vh"}
            style={{
              color: "#ccc",
              top: "30vh",
              position: "absolute",
            }}
          />
        </div>
      ) : (
        // <></>
        <Outlet />
      )}
    </>
  );
};

export default App;
