// 画面表示時間
export const timer = 500;

// APIから取得した値を表の表示用に変換する
export const dataChange = (
  data: number,
  isDouble: boolean = false,
  digit: number = 1,
) => {
  let dataChange = "";
  if (data) {
    switch (data) {
      case -111111111:
        dataChange = "";
        break;
      case -888888888:
        dataChange = "-";
        break;
      case -999999999:
        dataChange = "######.#";
        break;
      default:
        dataChange = isDouble
          ? data.toLocaleString(undefined, {
              minimumFractionDigits: digit,
              maximumFractionDigits: digit,
            })
          : data.toLocaleString();
        break;
    }
  }
  return dataChange;
};

// 小数点以下の桁数を調整する
export const addDecimalPoint = (value: number, digit: number = 1) => {
  if (value || value === 0) {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: digit,
      maximumFractionDigits: digit,
    });
  } else {
    return "";
  }
};

// 3桁区切りのカンマを加える
export const addComma = (value: number) => {
  if (value || value === 0) {
    return value.toLocaleString();
  } else {
    return "";
  }
};

// 土日祝日を色分けする
export const getHolidayStyle = (data: any) => {
  let dateColor: string = "black";
  if (data.day_of_week === 1 || data.is_holiday === true) {
    dateColor = "red";
  } else if (data.day_of_week === 7) {
    dateColor = "blue";
  }
  return dateColor;
};

// 前年の土日祝日を色分けする
export const getLastYearHolidayStyle = (data: any) => {
  let dateColor: string = "black";
  if (data.day_of_week_last_year === 1 || data.is_holiday_last_year === true) {
    dateColor = "red";
  } else if (data.day_of_week_last_year === 7) {
    dateColor = "blue";
  }
  return dateColor;
};

// yyyy-MM-ddをMM/ddに変換
export const formatMMDD = (date: string) => {
  const parts = date.split("-");
  return parts[1] + "/" + parts[2];
};

export const getDayOfWeek = (day: number) => {
  let dayOfWeek = "";
  switch (day) {
    case 1:
      dayOfWeek = "(日)";
      break;
    case 2:
      dayOfWeek = "(月)";
      break;
    case 3:
      dayOfWeek = "(火)";
      break;
    case 4:
      dayOfWeek = "(水)";
      break;
    case 5:
      dayOfWeek = "(木)";
      break;
    case 6:
      dayOfWeek = "(金)";
      break;
    case 7:
      dayOfWeek = "(土)";
      break;
  }
  return dayOfWeek;
};

export const graphColors: {
  A: string;
  B: string;
  C: string;
  D: string;
} = {
  A: "#ff9872",
  B: "#86f9c3",
  C: "#c299ff",
  D: "#8ef1ff",
};

// 日付の古い⇒新しい順にソートして日付をMM/ddに変換
export const arrangeData = (data: any) => {
  const sortedData = data.sort(function (a: any, b: any) {
    return a.date < b.date ? -1 : 1;
  });
  sortedData.map((data: any) => (data.date = formatMMDD(data.date)));
  return sortedData;
};

/**
 * 画像URL生成
 * @param imagePath
 * @param imageToken
 * @returns
 */
export function getImageFile(imagePath: string, imageToken: string) {
  if (imagePath === undefined || imagePath === "") {
    return `${window.location.origin}/image/noimage.png`;
  }
  return (
    process.env.REACT_APP_CLOUD_STORAGE_URL +
    imagePath +
    "?access_token=" +
    imageToken
  );
}
