// アプリケーション名
// 開発初期PH(デジタルツール側の認証処理が未設定)ではsejdevlopを用いる
export const APPLICATION_NAME: string = "cash-purchase-dev";
// 集計業務関数
export const NONE: string = "none";
export const IF: string = "if";
export const SUMS: string = "sums";
export const AVERAGES: string = "averages";
export const SUMIFS: string = "sumifs";
export const AVERAGEIFS: string = "averageifs";
