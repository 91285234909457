/* ---------------------------------------------------------------------------------- */
import React, { useCallback } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import Typography from "@mui/material/Typography";
import { Grid, Box } from "@mui/material";

/* ---------------------------------------------------------------------------------- */
const image = css`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

/* ---------------------------------------------------------------------------------- */

export const RequestCheckHelp: React.FC<{
  openModalNumber: number; // どの項目のモーダルが開かれたか
}> = ({ openModalNumber }) => {
  let titleElement: any;
  let contentsElement: any;

  /* ---------------------------------------------------------------------------------- */
  const refs = Array.from({ length: 10 }, () =>
    React.createRef<HTMLDialogElement>(),
  );

  // ダイアログを開く処理
  const handleOpenDialog = useCallback(
    (index: number) => {
      refs[index]?.current?.showModal();
    },
    [refs],
  );

  // ダイアログを閉じる処理
  const handleCloseDialog = useCallback(
    (index: number) => {
      refs[index]?.current?.close();
    },
    [refs],
  );
  // ダイアログ内のクリックイベントハンドラーに渡す処理
  const handleClickInDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
    },
    [],
  );

  // ヘルプデータの出し分け
  const creatHelp = (openModalNumber: number) => {
    let title: string = "";
    let contents: JSX.Element = <></>;

    switch (openModalNumber) {
      case 1:
        title = "①店舗イメージを損なう商品ではない";
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(0)}
              onKeyDown={() => handleOpenDialog(0)}
              className="image-box"
              tabIndex={0}
            >
              <img
                src={`${window.location.origin}/image/requestCheckHelp1.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <dialog
              ref={refs[0]}
              className="image-dialog"
              onClick={() => handleCloseDialog(0)}
            >
              <div onClick={handleClickInDialog} className="contents">
                <img
                  src={`${window.location.origin}/image/requestCheckHelp1.jpg`}
                  alt=""
                  width="100%"
                />
              </div>
            </dialog>
          </>
        );
        break;
      case 2:
        title = "②密閉された容器で販売";
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(1)}
              onKeyDown={() => handleOpenDialog(1)}
              className="image-box"
              tabIndex={1}
            >
              <img
                src={`${window.location.origin}/image/requestCheckHelp2.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <dialog
              ref={refs[1]}
              className="image-dialog"
              onClick={() => handleCloseDialog(1)}
            >
              <div onClick={handleClickInDialog} className="contents">
                <img
                  src={`${window.location.origin}/image/requestCheckHelp2.jpg`}
                  alt=""
                  width="100%"
                />
              </div>
            </dialog>
          </>
        );
        break;
      case 31:
        title = "③【食品表示法】必要な食品表示、栄養成分表示がある";
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(2)}
              onKeyDown={() => handleOpenDialog(2)}
              className="image-box"
              tabIndex={2}
            >
              <img
                src={`${window.location.origin}/image/requestCheckHelp3_1.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <dialog
              ref={refs[2]}
              className="image-dialog"
              onClick={() => handleCloseDialog(2)}
            >
              <div onClick={handleClickInDialog} className="contents">
                <img
                  src={`${window.location.origin}/image/requestCheckHelp3_1.jpg`}
                  alt=""
                  width="100%"
                />
              </div>
            </dialog>
          </>
        );
        break;
      case 32:
        title = "③【食品表示法】必要な食品表示、栄養成分表示がある";
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(3)}
              onKeyDown={() => handleOpenDialog(3)}
              className="image-box"
              tabIndex={3}
            >
              <img
                src={`${window.location.origin}/image/requestCheckHelp3_2.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <dialog
              ref={refs[3]}
              className="image-dialog"
              onClick={() => handleCloseDialog(3)}
            >
              <div onClick={handleClickInDialog} className="contents">
                <img
                  src={`${window.location.origin}/image/requestCheckHelp3_2.jpg`}
                  alt=""
                  width="100%"
                />
              </div>
            </dialog>
          </>
        );
        break;
      case 4:
        title = "④ 【食品衛生法】既存の店内什器で温度管理可能";
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(4)}
              onKeyDown={() => handleOpenDialog(4)}
              className="image-box"
              tabIndex={4}
            >
              <img
                src={`${window.location.origin}/image/requestCheckHelp4.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <dialog
              ref={refs[4]}
              className="image-dialog"
              onClick={() => handleCloseDialog(4)}
            >
              <div onClick={handleClickInDialog} className="contents">
                <img
                  src={`${window.location.origin}/image/requestCheckHelp4.jpg`}
                  alt=""
                  width="100%"
                />
              </div>
            </dialog>
          </>
        );
        break;
      case 5:
        title = "⑤【食品衛生法】HACCP対応 HACCP対応の確認";
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(5)}
              onKeyDown={() => handleOpenDialog(5)}
              className="image-box"
              tabIndex={5}
            >
              <img
                src={`${window.location.origin}/image/requestCheckHelp5.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <dialog
              ref={refs[5]}
              className="image-dialog"
              onClick={() => handleCloseDialog(5)}
            >
              <div onClick={handleClickInDialog} className="contents">
                <img
                  src={`${window.location.origin}/image/requestCheckHelp5.jpg`}
                  alt=""
                  width="100%"
                />
              </div>
            </dialog>
          </>
        );
        break;
      case 6:
        title = "⑥【薬機法】効果効能を標榜していない";
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(6)}
              onKeyDown={() => handleOpenDialog(6)}
              className="image-box"
              tabIndex={6}
            >
              <img
                src={`${window.location.origin}/image/requestCheckHelp6.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <dialog
              ref={refs[6]}
              className="image-dialog"
              onClick={() => handleCloseDialog(6)}
            >
              <div onClick={handleClickInDialog} className="contents">
                <img
                  src={`${window.location.origin}/image/requestCheckHelp6.jpg`}
                  alt=""
                  width="100%"
                />
              </div>
            </dialog>
          </>
        );
        break;
      case 7:
        title = "⑦【不当景品類及び不当表示防止法】特色表示による優良誤認";
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(7)}
              onKeyDown={() => handleOpenDialog(7)}
              className="image-box"
              tabIndex={7}
            >
              <img
                src={`${window.location.origin}/image/requestCheckHelp7.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <dialog
              ref={refs[7]}
              className="image-dialog"
              onClick={() => handleCloseDialog(7)}
            >
              <div onClick={handleClickInDialog} className="contents">
                <img
                  src={`${window.location.origin}/image/requestCheckHelp7.jpg`}
                  alt=""
                  width="100%"
                />
              </div>
            </dialog>
          </>
        );
        break;
      case 8:
        title = "【輸入商品】輸入届がなされているか";
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(8)}
              onKeyDown={() => handleOpenDialog(8)}
              className="image-box"
              tabIndex={8}
            >
              <img
                src={`${window.location.origin}/image/requestCheckHelp8.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <dialog
              ref={refs[8]}
              className="image-dialog"
              onClick={() => handleCloseDialog(8)}
            >
              <div onClick={handleClickInDialog} className="contents">
                <img
                  src={`${window.location.origin}/image/requestCheckHelp8.jpg`}
                  alt=""
                  width="100%"
                />
              </div>
            </dialog>
          </>
        );
        break;
      case 9:
        title = "【デイリー品】微生物検査の実施状況";
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(9)}
              onKeyDown={() => handleOpenDialog(9)}
              className="image-box"
              tabIndex={9}
            >
              <img
                src={`${window.location.origin}/image/requestCheckHelp9.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <dialog
              ref={refs[9]}
              className="image-dialog"
              onClick={() => handleCloseDialog(9)}
            >
              <div onClick={handleClickInDialog} className="contents">
                <img
                  src={`${window.location.origin}/image/requestCheckHelp9.jpg`}
                  alt=""
                  width="100%"
                />
              </div>
            </dialog>
          </>
        );
        break;
    }
    titleElement = <Typography variant="h6">{title}</Typography>;
    contentsElement = contents;
  };
  // ヘルプデータの出し分け処理呼び出し
  creatHelp(openModalNumber);

  return (
    <>
      <Box>
        <Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ marginBottom: "5px" }}
          >
            {titleElement}
          </Grid>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              borderTop: "1px solid #aaa",
              borderBottom: "1px solid #aaa",
              padding: "10px",
              height: "68vh", // ここでGridの高さを明示的に設定
              width: "40vw", // ここでGridの幅を明示的に設定
            }}
          >
            {contentsElement}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default RequestCheckHelp;
