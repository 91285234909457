import React from "react";
// import ReactDOM from 'react-dom/client'
import { createRoot } from "react-dom/client";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import Auth from "./components/auth/Auth";
import ErrorDisplay from "./components/common/ErrorDisplay";

import Home from "./components/home/Home";
import Management from "./components/management/Management";

// import * as serviceWorker from "./serviceWorker";
import ProtectedRoute from "./ProtectedRoute";
import { Provider } from "react-redux";
import { store } from "./app/store";

import Request from "./components/request/Request";
import RequestCheck from "./components/request/RequestCheck";
import RequestInput from "./components/request/RequestInput";
import RequestDb from "./components/request/RequestDb";
import RequestNotice from "./components/request/RequestNotice";
import RequestDbContents from "./components/request/RequestDbContents";

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            {/* <Route path="/" element={<App />} /> */}
            <Route path="/login" element={<Auth />} />
            <Route path="/tenpo-error" element={<ErrorDisplay />} />

            <Route path="/" element={<App />}>
              <Route index path="/" element={<Home />} />
            </Route>
            {/* ホーム画面 */}
            {/* <Route path="/home" element={<ProtectedRoute />}> */}
            <Route path="/home" element={<App />}>
              <Route index element={<Home />} />
            </Route>

            {/* 現金仕入申請画面 */}
            {/* <Route path="/request" element={<ProtectedRoute />}> */}
            <Route path="/request" element={<App />}>
              <Route index element={<Request />} />
            </Route>

            {/* 現金仕入申請前チェックリスト */}
            {/* <Route path="/requestCheck" element={<ProtectedRoute />}> */}
            <Route path="/requestCheck" element={<App />}>
              <Route index element={<RequestCheck />} />
            </Route>

            {/* 現金仕入申請前注意事項確認 */}
            {/* <Route path="/requestNotice" element={<ProtectedRoute />}> */}
            <Route path="/requestNotice" element={<App />}>
              <Route index element={<RequestNotice />} />
            </Route>

            {/* 現金仕入申請内容入力 */}
            {/* <Route path="/requestInput" element={<ProtectedRoute />}> */}
            <Route path="/requestInput" element={<App />}>
              <Route index element={<RequestInput />} />
            </Route>

            {/* 現金仕入申請データベース */}
            {/* <Route path="/requestDb" element={<ProtectedRoute />}> */}
            <Route path="/requestDb" element={<App />}>
              <Route index element={<RequestDb />} />
            </Route>

            {/* 現金仕入申請データベース */}
            {/* <Route path="/requestDbContents" element={<ProtectedRoute />}> */}
            <Route path="/requestDbContents" element={<App />}>
              <Route index element={<RequestDbContents />} />
            </Route>
            <Route path="/login" element={<Auth />} />
            <Route path="/tenpo-error" element={<ErrorDisplay />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
  );
}
