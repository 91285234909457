import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CashPurchaseDialog: React.FunctionComponent<{
  msg: string[];
  isOpen: boolean;
  doOK: any;
  doNo: any;
  isAlert: boolean;
  isClose?: boolean;
  boldList?: any[];
}> = ({ msg, isOpen, doOK, doNo, isAlert, isClose, boldList }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const doNoAction = () => {
    if (isClose) {
      doNo();
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={() => doNoAction()}
        aria-labelledby="common-dialog-title"
        aria-describedby="common-dialog-description"
      >
        <DialogContent>
          {msg.map((row: string, i) =>
            row ? (
              boldList && boldList.indexOf(i) !== -1 ? (
                <Typography style={{ fontWeight: "bold" }}>{row}</Typography>
              ) : (
                <Typography>{row}</Typography>
              )
            ) : (
              <br />
            )
          )}
        </DialogContent>

        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={() => {
              doOK();
              navigate("/home");
            }}
            variant={"contained"}
            style={{
              // width: "90%",
              margin: "2px",
              backgroundColor: "#C4C4C4",
              color: "black",
            }}
          >
            閉じる
          </Button>

          {isAlert ? (
            ""
          ) : (
            <Button onClick={() => doNo()} variant={"contained"}>
              キャンセル
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CashPurchaseDialog;
