import React, { useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Button, Box, Modal, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FileReader from "react-file-reader";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import { deleteRequest, postRequest } from "../../apis/axiosAction";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CashPurchaseDialog from "./CashPurchaseDialog";
import { selectUser } from "../../features/userSlice";
import { uploadFileGoogleCloudStorage } from "../../apis/photoAction";
import { v4 as uuidv4 } from "uuid";

const content = css`
  margin: 10px 5px 10px 20px;
  color: #000;
`;

const selectItemcss = css`
  margin: 8px 10px 8px 40px;
  min-width: 500px;
  max-width: 520px;
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "auto",
  maxHeight: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const checkModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  Height: "10%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

type CategoryType = {
  [key: string]: string;
};

export const category: CategoryType = {
  ビール: "01",
  日本酒: "02",
  "洋酒・ワイン・雑酒": "03",
  米飯: "04",
  調理パン: "05",
  "麺類・その他": "06",
  デリカテッセン: "07",
  デザート: "08",
  "牛乳・乳飲料": "09",
  乳製品: "10",
  玉子: "11",
  生活デイリー: "12",
  パンぺストリー: "13",
  菓子: "14",
  加工食品: "15",
  "調味料・乾物": "16",
  米穀: "17",
  し好品: "18",
  珍味: "19",
  缶詰: "20",
  冷凍食品: "21",
  アイスクリーム: "22",
  ソフトドリンク: "23",
  栄養ドリンク: "24",
  健康栄養食品: "25",
  その他: "26",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RequestComentModal: React.FC<{
  comentType: any;
  selectId: any;
  confirmStep: any;
  confirmStatus: any;
  inspectionResultUrl: any;
  consentFormUrl: any;
  userAuth: any;
  updateDate: any;
  confirmDataUpdateDate: any;
  file1: any;
  file2: any;
}> = ({
  comentType,
  selectId,
  confirmStep,
  confirmStatus,
  inspectionResultUrl,
  consentFormUrl,
  userAuth,
  updateDate,
  confirmDataUpdateDate,
  file1,
  file2,
}) => {
  //Modalの開閉
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //登録確認Modalの開閉
  const [checkModalOpen, setCheckModalOpen] = React.useState(false);
  const handleCheckModalOpen = () => setCheckModalOpen(true);
  //const handleCheckModalClose = () => setCheckModalOpen(false);
  const handleCheckModalClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      setCheckModalOpen(false);
    }
  };

  //削除確認Modalの開閉
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const handleDeleteModalOpen = () => setDeleteModalOpen(true);
  //const handleCheckModalClose = () => setCheckModalOpen(false);
  const handleDeleteModalClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      setDeleteModalOpen(false);
    }
  };

  //登録完了Modalの開閉
  const [completeOpen, setCompleteOpen] = React.useState(false);
  const handleCompleteOpen = () => setCompleteOpen(true);
  // const handleCompleteClose = () => setCompleteOpen(false);
  const handleCompleteClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      setCompleteOpen(false);
    }
  };

  //ログイン時に取得されたユーザ情報
  const userInfo: any = useSelector(selectUser);

  //ログインしたユーザーの識別
  let logInUser = userAuth;
  // OFCでログイン かつ 申請段階がOFC申請判断の場合、権限変更(5)
  if (logInUser === "0" && confirmStep === "5") {
    logInUser = confirmStep;
  }
  // QCでログイン かつ 申請段階がQC上長確認の場合、権限変更(8)
  if (logInUser === "7" && confirmStep === "8") {
    logInUser = confirmStep;
  }
  // QCでログイン かつ 申請段階がQC表示確認の場合、権限変更(9)
  if (logInUser === "7" && confirmStep === "9") {
    logInUser = confirmStep;
  }
  const navigate = useNavigate();

  const [apiData, setApiData] = React.useState<[]>([]);
  const [loading, setLoading] = React.useState(false); //API読み込みを保持するState
  const [text, setText] = useState(""); //入力コメント保持のState
  const [checkItemList, setCheckItemList] = React.useState<string[]>(
    new Array(5).fill(""),
  ); //MD認証の選択肢保持のState
  const [status, setStatus] = useState(""); //ステータス保持のState(差戻、リスクあり、問題なしなど)
  const [nextStep, setNextStep] = useState(""); //次の認証ステップの情報保持のState
  const [selectCategory, setSelectCategory] = useState(""); //QCのカテゴリー選択の情報保持
  const [flag, setFlag] = useState("0"); //メール送信用のflag処理
  const [uploadFile1, setUploadFile1] = React.useState(inspectionResultUrl);
  const [uploadFile2, setUploadFile2] = React.useState(consentFormUrl);
  // メッセージ用ダイアログ設定
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);

  const [responseText, setResponseText] = useState("");

  const inputFile1 = React.useRef<HTMLInputElement>(null);
  const inputFile2 = React.useRef<HTMLInputElement>(null);

  function confirmRequest() {
    // 申請完了フラグ Stepが9（QC：微生物検査）かつ申請が完了する選択肢の場合：1
    let completeFlag = "0";
    if (comentType === "9" && (status === "2" || status === "5")) {
      completeFlag = "1";
    }

    const newText = text.replace(/\n/g, "\\n");

    let apiParams = {
      query:
        "mutation { confirmRequest(input:" +
        '{id:"' +
        selectId +
        '",' +
        'step:"' +
        comentType +
        '",' +
        'updateUser:"' +
        userInfo.employeeName +
        '",' +
        'recordUpdateDate:"' +
        updateDate +
        '",' +
        'updateDate:"' +
        confirmDataUpdateDate +
        '",' +
        'comment:"' +
        newText +
        '", ' +
        'mdCheck1:"' +
        checkItemList[0] +
        '",' +
        'mdCheck2:"' +
        checkItemList[1] +
        '", ' +
        'mdCheck3:"' +
        checkItemList[2] +
        '",' +
        'mdCheck4:"' +
        checkItemList[3] +
        '", ' +
        'mdCheck5:"' +
        checkItemList[4] +
        '", ' +
        'qcCategory:"' +
        selectCategory +
        '", ' +
        'nextStep:"' +
        nextStep +
        '", ' +
        'status:"' +
        status +
        '", ' +
        'returnFlag:"' +
        flag +
        '", ' +
        'completeFlag:"' +
        completeFlag +
        '", ' +
        'inspectionResultUrl:"' +
        (uploadFile1 ? uploadFile1 : "") +
        '", ' +
        'consentFormUrl:"' +
        (uploadFile2 ? uploadFile2 : "") +
        '"})}',
    };
    const url = process.env.REACT_APP_API_URL + "graphql";
    setLoading(true);
    postRequest(url, apiParams)
      .then((response) => {
        setResponseText(response.data.data.confirmRequest);
        setApiData(response.data.data); // 取得したデータをステートにセット
        setLoading(false);
        handleCompleteOpen();
      })
      .catch((error) => {
        console.error(error); // エラーハンドリング
        setLoading(false);
        setDialogMessage([String(error)]);
        setOpen(false);
        setCheckModalOpen(false);
        setCompleteOpen(false);
        setDialogFlg(true);
      });
  }

  function deleteRequest() {
    const newText = text.replace(/\n/g, "\\n");

    let apiParams = {
      query:
        "mutation { deleteRequest(input: " +
        '{id:"' +
        selectId +
        '", ' +
        'step:"' +
        comentType +
        '", ' +
        'updateUser:"' +
        userInfo.employeeName +
        '", ' +
        'comment:"' +
        newText +
        '", ' +
        'nextStep:"0",' +
        'status:"9"})}',
    };
    const url = process.env.REACT_APP_API_URL + "graphql";
    setLoading(true);
    postRequest(url, apiParams)
      .then((response) => {
        setApiData(response.data.data); // 取得したデータをステートにセット
        setLoading(false);
        handleCompleteOpen();
      })
      .catch((error) => {
        console.error(error); // エラーハンドリング
        setLoading(false);
        setDialogMessage([String(error)]);
        setOpen(false);
        setCheckModalOpen(false);
        setDeleteModalOpen(false);
        setDialogFlg(true);
      });
  }

  //現在のStatusと次のStepの設定
  function inputStepParam(type: any, newStatus: any) {
    setStatus(newStatus);
    if (type === "1" || type === "2" || type === "3" || type === "4") {
      //DM,ZM,MD,CMD/SMD
      if (newStatus === "1" || newStatus === "2") {
        const next = Number(type) + 1;
        const nextStepStr = next.toString();
        setNextStep(nextStepStr);
      } else if (newStatus === "8") {
        setNextStep("0");
        setFlag("1");
      }
    } else if (type === "5") {
      //OFC申請判断
      if (newStatus === "1") {
        setNextStep("6");
      } else if (newStatus === "2" || newStatus === "3") {
        setNextStep("9");
      }
    } else if (type === "6") {
      //外部業者
      if (newStatus === "1" || newStatus === "2") {
        setNextStep("7");
      } else if (newStatus === "8") {
        setNextStep("5");
        setFlag("1");
      }
    } else if (type === "7") {
      //QC表示
      if (newStatus === "1") {
        setNextStep("8");
      } else if (newStatus === "8") {
        setNextStep("5");
        setFlag("1");
      }
    } else if (type === "8") {
      //QC上長
      if (newStatus === "1" || newStatus === "2" || newStatus === "3") {
        setNextStep("9");
      } else if (newStatus === "8") {
        setNextStep("5");
        setFlag("1");
      }
    } else if (type === "9") {
      //QC微生物
      if (newStatus === "1" || newStatus === "4") {
        setNextStep("5");
      } else if (newStatus === "2" || newStatus === "5") {
        setNextStep("0");
      } else if (newStatus === "8" || newStatus === "6") {
        setNextStep("5");
        setFlag("1");
      }
    }
  }

  //ボタンの表示非表示設定
  const itemListVisible =
    checkItemList[0] === "" ||
    checkItemList[1] === "" ||
    checkItemList[2] === "" ||
    checkItemList[3] === "" ||
    checkItemList[4] === "";

  const textVisible = text === "";

  // ダイアログ閉じる
  const dialogClose = () => {
    setDialogFlg(false);
  };

  //MDの情報入力画面Modal
  function mdComent(type: any) {
    const handleCheckItem = (event: SelectChangeEvent) => {
      let itemName = event.target.name.split("_");
      let index: number = Number(itemName[1]) - 1;
      let tmpCheckItemList = checkItemList.slice(0, checkItemList.length);

      tmpCheckItemList[index] = event.target.value;

      setCheckItemList(tmpCheckItemList);
    };

    return (
      <>
        <Typography variant="h6" css={content}>
          【MD確認内容】
        </Typography>
        <Grid container alignContent="center">
          <Grid item xs={12}>
            <Typography css={content}>
              ①SNS等での批判的なコメント・拡散の可能性は無い
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl css={selectItemcss}>
              <InputLabel id="demo-simple-select-autowidth-label">
                ①SNS等での批判的なコメント・拡散の可能性は無い
              </InputLabel>
              <Select
                name="checkItem_1"
                label="①SNS等での批判的なコメント・拡散の可能性は無い"
                defaultValue={checkItemList[0]}
                onChange={(e: SelectChangeEvent) => {
                  handleCheckItem(e);
                }}
                autoWidth
              >
                <MenuItem value={"0"}>
                  〇：商品、販売方法についてお客様や世の中の捉え方を考慮している
                </MenuItem>
                <MenuItem value={"1"}>×：拡散の可能性がある</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container alignContent="center">
          <Grid item xs={12}>
            <Typography css={content}>
              ②【企業規模】メーカー（仕入れ先）リスクが高くない
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl css={selectItemcss}>
              <InputLabel id="demo-simple-select-autowidth-label">
                ②【企業規模】メーカー（仕入れ先）リスクが高くない
              </InputLabel>
              <Select
                name="checkItem_2"
                label="②【企業規模】メーカー（仕入れ先）リスクが高くない"
                defaultValue={checkItemList[1]}
                onChange={(e: SelectChangeEvent) => {
                  handleCheckItem(e);
                }}
                autoWidth
              >
                <MenuItem value={"0"}>
                  リスク低：SEJ推奨品を扱うメーカー、一部上場企業
                </MenuItem>
                <MenuItem value={"1"}>
                  リスク中：上記以外の株式会社、有限会社
                </MenuItem>
                <MenuItem value={"2"}>
                  リスク高：個人事業者、法人団体（社会福祉法人、特定非営利活動法人）などの企業規模が小さい場合、商品事故発生時の対応力が懸念される
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container alignContent="center">
          <Grid item xs={12}>
            <Typography css={content}>
              ③【違反事例】メーカー（仕入れ先）リスクが高くない
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl css={selectItemcss}>
              <InputLabel id="demo-simple-select-autowidth-label">
                ③【違反事例】メーカー（仕入れ先）リスクが高くない
              </InputLabel>
              <Select
                name="checkItem_3"
                label="③【違反事例】メーカー（仕入れ先）リスクが高くない"
                defaultValue={checkItemList[2]}
                onChange={(e: SelectChangeEvent) => {
                  handleCheckItem(e);
                }}
                autoWidth
              >
                <MenuItem value={"0"}>
                  リスク低：違反事例がない。回収事故を頻繁には起こしていない
                </MenuItem>
                <MenuItem value={"1"}>
                  リスク高：違反事例がある。商品回収を頻繁に起こしている
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container alignContent="center">
          <Grid item xs={12}>
            <Typography css={content}>
              ④ 7-11オリジナル商品であるとの誤認を与えない
              <br />
              ※オリジナル商品と誤認を与えるような容器、包装形態でない
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl css={selectItemcss}>
              <InputLabel id="demo-simple-select-autowidth-label">
                ④ 7-11オリジナル商品であるとの誤認を与えない
              </InputLabel>
              <Select
                name="checkItem_4"
                label="④ 7-11オリジナル商品であるとの誤認を与えない"
                defaultValue={checkItemList[3]}
                onChange={(e: SelectChangeEvent) => {
                  handleCheckItem(e);
                }}
                autoWidth
              >
                <MenuItem value={"0"}>
                  リスク低：オリジナル商品と明らかに見分けがつく
                </MenuItem>
                <MenuItem value={"1"}>
                  リスク高：一見して、オリジナル商品と見分けがつかない
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container alignContent="center">
          <Grid item xs={12}>
            <Typography css={content}>
              ⑤他社商品の意匠権・商標権を侵害していないことを確認している
              <br />
              ※他社品と誤認を与えるような容器・包装形態ではなく、商標権を確認している
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl css={selectItemcss}>
              <InputLabel id="demo-simple-select-autowidth-label">
                ⑤他社商品の意匠権・商標権を侵害していないことを確認している
              </InputLabel>
              <Select
                name="checkItem_5"
                label="⑤他社商品の意匠権・商標権を侵害していないことを確認している"
                defaultValue={checkItemList[4]}
                onChange={(e: SelectChangeEvent) => {
                  handleCheckItem(e);
                }}
                autoWidth
              >
                <MenuItem value={"0"}>
                  リスク低：他社の意匠権、商標登録への侵害に関して、確認できている
                </MenuItem>
                <MenuItem value={"1"}>
                  リスク高：他社の意匠権、商標登録への侵害に関して、確認できていない
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          <Typography css={content}>⑥その他コメント</Typography>
          <FormControl fullWidth>
            {/* <InputLabel  >
    その他コメント
                  </InputLabel> */}
            <TextField
              defaultValue=""
              multiline
              rows={3}
              inputProps={{
                maxLength: 200,
              }}
              onChange={(e) => {
                if (e.target.value.length <= 200) {
                  setText(e.target.value);
                }
              }}
            />
          </FormControl>
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ height: "10vh", marginBottom: "20px", padding: "10px" }}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={3.6} justifyContent="center">
            <Button
              disabled={textVisible}
              onClick={() => {
                inputStepParam(type, "8");
                handleCheckModalOpen();
              }}
              variant="contained"
              style={{ width: "70%", margin: "2px" }}
            >
              差戻
            </Button>
          </Grid>
          <Grid item xs={3.6} justifyContent="center">
            <Button
              disabled={itemListVisible || textVisible}
              variant="contained"
              style={{ width: "70%", margin: "2px" }}
              onClick={() => {
                inputStepParam(type, "1");
                handleCheckModalOpen();
              }}
            >
              リスクあり
            </Button>
          </Grid>
          <Grid item xs={3.6} justifyContent="center">
            <Button
              disabled={itemListVisible}
              variant="contained"
              style={{ width: "70%", margin: "2px" }}
              onClick={() => {
                inputStepParam(type, "2");
                handleCheckModalOpen();
              }}
            >
              問題なし
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
  //テキスト入力画面のModal
  function comentTextBox(type: any) {
    return (
      <>
        <Grid container alignItems="center" justifyContent="center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            確認済み登録
          </Typography>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            リスクあり・差戻の場合はコメントを入力して「リスクあり・差戻」を選択してください
            <br />
            既にコメントが入力されている場合は上書きされますのでご注意ください
          </Typography>
        </Grid>

        <Grid container alignItems="center" justifyContent="center">
          <FormControl fullWidth>
            {/* <InputLabel  >
                問題ありで進達する場合や不備で刺し戻す場合は、コメントを入力してください。
                <br/>
                既にコメントが入力されている場合は上書きされますのでご注意ください
              </InputLabel> */}
            <TextField
              defaultValue=""
              multiline
              rows={13}
              inputProps={{
                maxLength: 200,
              }}
              onChange={(e) => {
                if (e.target.value.length <= 200) {
                  setText(e.target.value);
                }
              }}
            />
          </FormControl>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Typography sx={{ mt: 2 }}>
            ※差戻を選択すると、担当OFCへメールが送付されます
          </Typography>
        </Grid>
        {type !== "8" ? (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: "10vh", marginBottom: "20px", padding: "10px" }}
          >
            <Grid item xs={1}></Grid>
            <Grid item xs={3.6} justifyContent="center">
              <Button
                disabled={textVisible}
                onClick={() => {
                  inputStepParam(type, "8");
                  handleCheckModalOpen();
                }}
                variant="contained"
                style={{ width: "70%", margin: "2px" }}
              >
                差戻
              </Button>
            </Grid>
            <Grid item xs={3.6} justifyContent="center">
              <Button
                disabled={textVisible}
                variant="contained"
                style={{ width: "70%", margin: "2px" }}
                onClick={() => {
                  inputStepParam(type, "1");
                  handleCheckModalOpen();
                }}
              >
                リスクあり
              </Button>
            </Grid>
            <Grid item xs={3.6} justifyContent="center">
              <Button
                variant="contained"
                style={{ width: "70%", margin: "2px" }}
                onClick={() => {
                  inputStepParam(type, "2");
                  handleCheckModalOpen();
                }}
              >
                問題なし
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: "10vh", marginBottom: "20px", padding: "10px" }}
          >
            <Grid item xs={1}></Grid>
            <Grid item xs={2.75} justifyContent="center">
              <Button
                disabled={textVisible}
                onClick={() => {
                  inputStepParam(type, "8");
                  handleCheckModalOpen();
                }}
                variant="contained"
                style={{ width: "70%", margin: "2px" }}
              >
                差戻
              </Button>
            </Grid>
            <Grid item xs={2.75} justifyContent="center">
              <Button
                disabled={textVisible}
                variant="contained"
                style={{ width: "70%", margin: "2px" }}
                onClick={() => {
                  inputStepParam(type, "1");
                  handleCheckModalOpen();
                }}
              >
                リスクあり
              </Button>
            </Grid>
            <Grid item xs={2.75} justifyContent="center">
              <Button
                variant="contained"
                style={{ width: "70%", margin: "2px" }}
                onClick={() => {
                  inputStepParam(type, "2");
                  handleCheckModalOpen();
                }}
              >
                問題なし
              </Button>
            </Grid>

            <Grid item xs={2.75} justifyContent="center">
              <Button
                variant="contained"
                style={{ width: "70%", margin: "2px" }}
                onClick={() => {
                  inputStepParam(type, "3");
                  handleCheckModalOpen();
                }}
              >
                確認不要
              </Button>
            </Grid>
          </Grid>
        )}
      </>
    );
  }

  const [uploadFileList, setUploadFileList] = React.useState<string[]>([
    inspectionResultUrl,
    consentFormUrl,
  ]);
  const [uploadFileName, setUploadFileName] = React.useState<string[]>([]);

  function ofcCheck(type: any) {
    //  アップロード選択時：ファイルのアップロードとURL取得
    const handleUploadfile = (
      event: any,
      itemNumber: number,
    ): Promise<void> => {
      // Promiseの型引数をvoidに設定
      return new Promise<void>((resolve, reject) => {
        if (event.target.files && event.target.files.length > 0) {
          let uploadFile: any = event.target.files[0];
          // 元のファイル名と拡張子を取得
          let originalName = uploadFile.name;
          let dotIndex = originalName.lastIndexOf(".");
          let fileName = originalName.slice(0, dotIndex);
          let fileExt = originalName.slice(dotIndex);

          setLoading(true);
          handleCheckModalOpen();

          // 新しいファイル名を設定
          let newFileName = `${fileName}-${uuidv4()}${fileExt}`;
          const directory = "cash-purchase-inspection/";
          uploadFileGoogleCloudStorage(uploadFile, directory, newFileName)
            .then((filepath: any) => {
              upload(directory + newFileName, itemNumber, newFileName)
                .then(() => {
                  setLoading(false); // ローディングを非表示にする
                  resolve();
                })
                .catch((error) => {
                  setLoading(false); // エラーが発生してもローディングを非表示にする
                  reject(error);
                });
            })
            .catch((error) => {
              setLoading(false); // エラーが発生してもローディングを非表示にする
              reject(error);
            });
        } else {
          console.warn("ファイルが選択されていません。");
          reject(new Error("ファイルが選択されていません。"));
        }
      });
    };

    const upload = async (
      downloadURL: string,
      itemNumber: number,
      fileName: string,
    ): Promise<void> => {
      return new Promise<void>((resolve, reject) => {
        // ファイルがアップロードされている場合、データリストにURLを保存する
        if (downloadURL != null) {
          switch (itemNumber) {
            case 0:
              setUploadFile1(downloadURL);
              break;
            case 1:
              setUploadFile2(downloadURL);
              break;
            default:
              console.error("Invalid inputNumber");
          }

          let tmpUploadFileName = [...uploadFileName]; //.slice(0, uploadFileName.length);
          tmpUploadFileName[itemNumber] = fileName;
          setUploadFileName(tmpUploadFileName);
        }
        resolve();
      });
    };

    async function handleRequest(event: any, number: any) {
      try {
        await handleUploadfile(event, number);
        await handleCheckModalOpen();
      } catch (error) {
        console.error(error);
      }
    }

    return (
      <>
        <Grid container alignItems="center" justifyContent="center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            申請判断
          </Typography>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            申請判断を入力してください
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ height: "10vh", marginBottom: "20px", padding: "10px" }}
        >
          <Grid item xs={2}></Grid>
          <Grid item xs={4} justifyContent="center">
            <Button
              onClick={() => {
                handleDeleteModalOpen();
              }}
              variant="contained"
              color="primary"
              component="span"
              style={{ width: "70%", margin: "2px" }}
            >
              中止
            </Button>
          </Grid>
          <Grid item xs={4} justifyContent="center">
            <Button
              disabled={
                confirmStatus !== "0" &&
                (confirmStatus !== "8" || file1 !== null || file1 !== "")
              }
              variant="contained"
              color="primary"
              component="span"
              style={{ width: "70%", margin: "2px" }}
              onClick={() => {
                inputStepParam(type, "1");
                handleCheckModalOpen();
              }}
            >
              申請実施
            </Button>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ height: "10vh", marginBottom: "20px", padding: "10px" }}
        >
          <Grid item xs={2}></Grid>
          <Grid
            item
            container
            xs={8}
            style={{ border: "1px dashed #F00" }}
            alignItems="center"
          >
            <Grid item xs={6}>
              微生物検査結果：
              <Typography
                variant="body1"
                style={{ color: "red", fontWeight: "bold" }}
              >
                ※注：QCからの依頼後に実施
              </Typography>
            </Grid>
            <Grid item xs={6} justifyContent="center">
              <label>
                <input
                  ref={inputFile1}
                  style={{ display: "none" }}
                  type="file"
                  multiple
                  accept=".pdf"
                  disabled={
                    confirmStatus !== "1" &&
                    !(
                      confirmStatus === "8" &&
                      file1 !== "" &&
                      file1 !== null &&
                      file2 === ""
                    )
                  }
                  onChange={(e) => {
                    handleRequest(e, 0);
                  }}
                />
                <Button
                  disabled={
                    confirmStatus !== "1" &&
                    !(
                      confirmStatus === "8" &&
                      file1 !== "" &&
                      file1 !== null &&
                      file2 === ""
                    )
                  }
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ width: "70%", margin: "2px" }}
                  onClick={(e) => {
                    if (inputFile1.current) inputFile1.current.value = "";
                    inputStepParam(type, "2");
                  }}
                >
                  微生物検査結果アップロード
                </Button>
              </label>
              <Typography>{uploadFileName[0]}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>{" "}
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ height: "10vh", marginBottom: "20px", padding: "10px" }}
        >
          <Grid item xs={2}></Grid>
          <Grid
            item
            container
            xs={8}
            style={{ border: "1px dashed #F00" }}
            alignItems="center"
          >
            <Grid item xs={6}>
              食品検査同意書提出：
              <Typography
                variant="body1"
                style={{ color: "red", fontWeight: "bold" }}
              >
                ※注：QCからの依頼後に実施
              </Typography>
            </Grid>

            <Grid item xs={6} justifyContent="center">
              <label>
                <input
                  ref={inputFile2}
                  style={{ display: "none" }}
                  type="file"
                  multiple
                  accept=".pdf"
                  disabled={
                    confirmStatus !== "2" &&
                    (confirmStatus !== "8" || file2 === null || file2 === "")
                  }
                  onChange={(e) => {
                    handleRequest(e, 1);
                  }}
                />
                <Button
                  disabled={
                    confirmStatus !== "2" &&
                    (confirmStatus !== "8" || file2 === null || file2 === "")
                  }
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{ width: "70%", margin: "2px" }}
                  onClick={(e) => {
                    if (inputFile2.current) inputFile2.current.value = "";
                    inputStepParam(type, "3");
                  }}
                >
                  食品検査同意書アップロード
                </Button>
              </label>
              <Typography>{uploadFileName[1]}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>{" "}
      </>
    );
  }

  //QC表示のコメント入力画面のModal
  function qc1Coment(type: any) {
    const categoryVisible = selectCategory === "";

    return (
      <>
        <Grid container alignItems="center" justifyContent="center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            確認済み登録
          </Typography>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            表示不適合・差戻の場合はコメントを入力して「表示不適合・差戻」を選択してください
            <br />
            既にコメントが入力されている場合は上書きされますのでご注意ください
          </Typography>
        </Grid>

        <Grid container alignItems="center" justifyContent="center">
          <FormControl fullWidth>
            {/* <InputLabel  >
              問題ありで進達する場合や不備で刺し戻す場合は、コメントを入力してください。
              <br/>
              既にコメントが入力されている場合は上書きされますのでご注意ください
            </InputLabel> */}
            <TextField
              defaultValue=""
              multiline
              rows={10}
              inputProps={{
                maxLength: 200,
              }}
              onChange={(e) => {
                if (e.target.value.length <= 200) {
                  setText(e.target.value);
                }
              }}
            />
          </FormControl>
        </Grid>

        <Grid item>
          <br />
        </Grid>

        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <Typography css={content}>商品カテゴリー：</Typography>
            </Grid>
            <Grid item>
              <FormControl sx={{ width: 200 }}>
                <Select
                  size="small"
                  defaultValue=""
                  MenuProps={MenuProps}
                  onChange={(e) => setSelectCategory(category[e.target.value])}
                >
                  {Object.keys(category).map((key) => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justifyContent="center">
          <Typography sx={{ mt: 2 }}>
            ※差戻を選択すると、担当OFCへメールが送付されます
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ height: "10vh", marginBottom: "20px", padding: "10px" }}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={3.66} justifyContent="center">
            <Button
              disabled={textVisible || categoryVisible}
              onClick={() => {
                inputStepParam(type, "8");
                handleCheckModalOpen();
              }}
              variant="contained"
              style={{ width: "70%", margin: "2px" }}
            >
              差戻
            </Button>
          </Grid>
          <Grid item xs={3.66} justifyContent="center">
            <Button
              disabled={textVisible || categoryVisible}
              variant="contained"
              style={{ width: "70%", margin: "2px" }}
              onClick={() => {
                handleDeleteModalOpen();
              }}
            >
              表示不適合
            </Button>
          </Grid>
          <Grid item xs={3.66} justifyContent="center">
            <Button
              disabled={categoryVisible}
              variant="contained"
              style={{ width: "70%", margin: "2px" }}
              onClick={() => {
                inputStepParam(type, "1");
                handleCheckModalOpen();
              }}
            >
              表示適合
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }

  //QC微生物検査のコメント入力画面のModal
  function qc2Coment(type: any) {
    return (
      <>
        <Grid container alignItems="center" justifyContent="center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            確認済み登録
          </Typography>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            検査あり・微生物検査結果/食品検査同意書
            NG・差戻の場合はコメントを入力して「検査あり・NG・差戻」を選択してください
            <br />
            既にコメントが入力されている場合は上書きされますのでご注意ください
          </Typography>
        </Grid>

        <Grid container alignItems="center" justifyContent="center">
          <FormControl fullWidth>
            {/* <InputLabel  >
                問題ありで進達する場合や不備で刺し戻す場合は、コメントを入力してください。
                <br/>
                既にコメントが入力されている場合は上書きされますのでご注意ください
              </InputLabel> */}
            <TextField
              defaultValue=""
              multiline
              rows={5}
              inputProps={{
                maxLength: 200,
              }}
              onChange={(e) => {
                if (e.target.value.length <= 200) {
                  setText(e.target.value);
                }
              }}
            />
          </FormControl>
        </Grid>

        <Grid item>
          <br />
        </Grid>

        <Grid container alignItems="center" justifyContent="center">
          <Typography sx={{ mt: 2 }}>
            ※差戻を選択すると、担当OFCへメールが送付されます
          </Typography>
        </Grid>

        {/* {confirmStatus === "0" && ( */}
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ height: "10vh", marginBottom: "20px", padding: "10px" }}
        >
          <Grid item xs={3} justifyContent="center">
            <Button
              disabled={textVisible}
              onClick={() => {
                inputStepParam(type, "8");
                handleCheckModalOpen();
              }}
              variant="contained"
              style={{ width: "70%", margin: "2px" }}
            >
              差戻
            </Button>
          </Grid>
          <Grid item xs={3} justifyContent="center">
            <Button
              // disabled={confirmStatus!=="0"}
              disabled={
                textVisible ||
                (confirmStatus !== "0" &&
                  (confirmStatus !== "8" || file1 !== null || file1 !== ""))
              }
              variant="contained"
              style={{ width: "70%", margin: "2px" }}
              onClick={() => {
                inputStepParam(type, "1");
                handleCheckModalOpen();
              }}
            >
              検査あり
            </Button>
          </Grid>
          <Grid item xs={3} justifyContent="center">
            <Button
              disabled={
                confirmStatus !== "0" &&
                (confirmStatus !== "8" || file1 !== null || file1 !== "")
              }
              variant="contained"
              style={{ width: "70%", margin: "2px" }}
              onClick={() => {
                inputStepParam(type, "2");
                handleCheckModalOpen();
              }}
            >
              検査なし
            </Button>
          </Grid>
        </Grid>
        {/* )} */}

        {(confirmStatus === "1" ||
          (confirmStatus === "8" &&
            file1 !== null &&
            file1 !== "" &&
            file2 === "")) && (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: "10vh", marginBottom: "20px", padding: "10px" }}
          >
            <Grid item xs={3} justifyContent="center">
              <Typography css={content}>微生物検査結果：</Typography>
            </Grid>
            <Grid item xs={3} justifyContent="center">
              <Button
                variant="contained"
                style={{ width: "70%", margin: "2px" }}
                onClick={() => {
                  inputStepParam(type, "4");
                  handleCheckModalOpen();
                }}
              >
                OK
              </Button>
            </Grid>
            <Grid item xs={3} justifyContent="center">
              <Button
                disabled={textVisible}
                variant="contained"
                style={{ width: "70%", margin: "2px" }}
                onClick={() => {
                  handleDeleteModalOpen();
                }}
              >
                NG
              </Button>
            </Grid>
          </Grid>
        )}

        {(confirmStatus === "4" ||
          (confirmStatus === "8" && file2 !== null && file2 !== "")) && (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: "10vh", marginBottom: "20px", padding: "10px" }}
          >
            <Grid item xs={3} justifyContent="center">
              <Typography css={content}>食品検査同意書：</Typography>
            </Grid>
            <Grid item xs={3} justifyContent="center">
              <Button
                variant="contained"
                style={{ width: "70%", margin: "2px" }}
                onClick={() => {
                  inputStepParam(type, "5");
                  handleCheckModalOpen();
                }}
              >
                OK
              </Button>
            </Grid>
            <Grid item xs={3} justifyContent="center">
              <Button
                disabled={textVisible}
                variant="contained"
                style={{ width: "70%", margin: "2px" }}
                onClick={() => {
                  inputStepParam(type, "6");
                  handleCheckModalOpen();
                }}
              >
                NG
              </Button>
            </Grid>
          </Grid>
        )}
      </>
    );
  }

  function jficComent(type: any) {
    return (
      <>
        <Grid container alignItems="center" justifyContent="center">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            確認済み登録
          </Typography>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            表示不適合・差戻の場合はコメントを入力して「表示不適合・差戻」を選択してください
            <br />
            既にコメントが入力されている場合は上書きされますのでご注意ください
          </Typography>
        </Grid>

        <Grid container alignItems="center" justifyContent="center">
          <FormControl fullWidth>
            <TextField
              defaultValue=""
              multiline
              rows={13}
              inputProps={{
                maxLength: 200,
              }}
              onChange={(e) => {
                if (e.target.value.length <= 200) {
                  setText(e.target.value);
                }
              }}
            />
          </FormControl>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Typography sx={{ mt: 2 }}>
            ※不適合・差戻を選択すると、担当へメールが送付されます
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ height: "10vh", marginBottom: "20px", padding: "10px" }}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={3.6} justifyContent="center">
            <Button
              disabled={textVisible}
              onClick={() => {
                inputStepParam(type, "8");
                handleCheckModalOpen();
              }}
              variant="contained"
              style={{ width: "70%", margin: "2px" }}
            >
              差戻
            </Button>
          </Grid>
          <Grid item xs={3.6} justifyContent="center">
            <Button
              disabled={textVisible}
              variant="contained"
              style={{ width: "70%", margin: "2px" }}
              onClick={() => {
                inputStepParam(type, "2");
                handleCheckModalOpen();
              }}
            >
              表示不適合
            </Button>
          </Grid>
          <Grid item xs={3.6} justifyContent="center">
            <Button
              variant="contained"
              style={{ width: "70%", margin: "2px" }}
              onClick={() => {
                inputStepParam(type, "1");
                handleCheckModalOpen();
              }}
            >
              表示適合
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }

  //modalの切り替え
  function switchComent() {
    switch (comentType) {
      //DM
      case "1":
        return <>{comentTextBox("1")}</>;
      //ZM
      case "2":
        return <>{comentTextBox("2")}</>;
      //MD
      case "3":
        return <>{mdComent("3")}</>;
      //CMD/SMD
      case "4":
        return <>{comentTextBox("4")}</>;
      //OFC申請判断
      case "5":
        return <>{ofcCheck("5")}</>;
      //外部業者：表示確認
      case "6":
        return <>{jficComent("6")}</>;
      //QC表示確認
      case "7":
        return <>{qc1Coment("7")}</>;
      //QC上長表示確認
      case "8":
        return <>{comentTextBox("8")}</>;
      //QC:微生物検査
      case "9":
        return <>{qc2Coment("9")}</>;
    }
  }

  return (
    <>
      {comentType === "0" ? (
        <Grid
          item
          sx={{
            color: "white",
            outlineColor: "#3e3e3e",
            backgroundColor:
              (comentType === logInUser || logInUser === "5") &&
              confirmStatus !== "2" &&
              confirmStatus !== "9"
                ? "#4d73bf"
                : "#a9a9a9",
            margin: "2px",
            "&:hover": {
              backgroundColor:
                (comentType === logInUser || logInUser === "5") &&
                confirmStatus !== "2" &&
                confirmStatus !== "9"
                  ? "#99bbff"
                  : "#a9a9a9",
              cursor: "pointer",
              opacity: "1",
            },
          }}
        >
          <Grid
            container
            sx={{
              height: "10.5vh",
              "&:hover": {
                //textDecoration: "underline",
                opacity: "1",
                cursor: "pointer",
              },
            }}
            alignItems="center"
            justifyContent="center"
            onClick={() => {
              if (
                (comentType === logInUser || logInUser === "5") &&
                confirmStatus !== "2" &&
                confirmStatus !== "9"
              )
                handleDeleteModalOpen();
            }}
          >
            中止
            {/* 0の時は削除API　0以外は確認APIを投げるため表示の出し分け */}
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          sx={{
            color: "white",
            outlineColor: "#3e3e3e",
            //ログインしたUserと確認段階が一致した場合のみボダンが押せるようにする
            backgroundColor:
              comentType === confirmStep && comentType === logInUser
                ? "#4d73bf"
                : "#a9a9a9",
            margin: "2px",
            "&:hover": {
              backgroundColor:
                comentType === confirmStep && comentType === logInUser
                  ? "#99bbff"
                  : "#a9a9a9",
              cursor: "pointer",
              opacity: "1",
            },
          }}
        >
          <Grid
            container
            sx={{
              height:
                comentType === "5" || comentType === "3" ? "10.5vh" : "21vh",
              "&:hover": {
                //textDecoration: "underline",
                opacity: "1",
                cursor: "pointer",
              },
            }}
            alignItems="center"
            justifyContent="center"
            onClick={() => {
              if (comentType === confirmStep && comentType === logInUser) {
                handleOpen();
              }
            }}
          >
            確認
            {/* 0の時は削除API　0以外は確認APIを投げるため表示の出し分け */}
          </Grid>
        </Grid>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{switchComent()}</Box>
      </Modal>

      <Modal
        open={checkModalOpen}
        onClose={handleCheckModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={checkModalStyle}>
          {loading ? (
            <CircularProgress size={36} />
          ) : (
            <>
              <Grid container alignContent="center">
                <Grid item xs={12}>
                  <Typography fontSize={16} component="h2">
                    登録してもよろしいでしょうか？
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    style={{
                      width: "90%",
                      margin: "2px",
                      backgroundColor: "#C4C4C4",
                      color: "black",
                    }}
                    onClick={() => {
                      confirmRequest();
                    }}
                  >
                    はい
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    style={{
                      width: "90%",
                      margin: "2px",
                      backgroundColor: "#C4C4C4",
                      color: "black",
                    }}
                    onClick={() => {
                      setCheckModalOpen(false);
                    }}
                  >
                    いいえ
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>

      <Modal
        open={deleteModalOpen}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={checkModalStyle}>
          {loading ? (
            <CircularProgress size={36} />
          ) : (
            <>
              <Grid container alignContent="center">
                <Grid item xs={12}>
                  <Typography fontSize={16} component="h2">
                    {comentType === "0" || comentType === "5"
                      ? "申請中止してもよろしいでしょうか？"
                      : "不適合でよろしいでしょうか？"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    style={{
                      width: "90%",
                      margin: "2px",
                      backgroundColor: "#C4C4C4",
                      color: "black",
                    }}
                    onClick={() => {
                      deleteRequest();
                    }}
                  >
                    はい
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    style={{
                      width: "90%",
                      margin: "2px",
                      backgroundColor: "#C4C4C4",
                      color: "black",
                    }}
                    onClick={() => {
                      setDeleteModalOpen(false);
                    }}
                  >
                    いいえ
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>

      <Modal
        open={completeOpen}
        onClose={handleCompleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={checkModalStyle}>
          <Grid container alignContent="center">
            <Grid item xs={12}>
              {responseText === "duplication_error" ? (
                <Typography fontSize={16} component="h2">
                  他のユーザーにより対象データが更新されています。
                  <br />
                  データの確認をお願いします。
                </Typography>
              ) : responseText === "error" ? (
                <Typography fontSize={16} component="h2">
                  エラーが発生しました。もう一度試してください。
                </Typography>
              ) : (
                <Typography fontSize={16} component="h2">
                  登録完了しました
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                style={{
                  width: "90%",
                  margin: "2px",
                  backgroundColor: "#C4C4C4",
                  color: "black",
                }}
                onClick={() => {
                  setCheckModalOpen(false);
                  handleClose();
                  setCompleteOpen(false);
                  navigate("/home");
                }}
              >
                閉じる
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Box>
      </Modal>
      {/* ダイアログ */}
      <CashPurchaseDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogClose}
        doNo={dialogClose}
        isAlert={true}
      />
    </>
  );
};
export default RequestComentModal;
