import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Modal,
  CircularProgress,
  CardMedia,
  styled,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectImageToken } from "../../features/userSlice";
import { getImageFile } from "../common/utils";
import { RequestSampleImage } from "./RequestSampleImage";

const ImageBox = styled(Box)({
  cursor: "pointer",
  position: "relative",
  paddingBottom: "100%",
  height: 0,
  overflow: "hidden",
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

export const RequestItemCameraUpload: React.FC<{
  itemNumber: number;
  onHandleUploadPhoto: any;
  onUploadFile: any;
  title: any;
  image: any;
}> = ({ itemNumber, onHandleUploadPhoto, onUploadFile, title, image }) => {
  const [url, setUrl] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const loadingGif = `${window.location.origin}` + "/image/icon_loader.gif";
  // const noImage = `${window.location.origin}` + "/image/noimage.png";

  const [openInputFile, setOpenInputFile] = React.useState(false);
  const handleOpenInputFile = () => setOpenInputFile(true);
  // const handleCloseInputFile = () => setOpenInputFile(false);
  const handleCloseInputFile = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      setOpenInputFile(false);
    }
  };

  useEffect(() => {
    setUrl(image);
  }, [image]);

  const fileUploadeURL = (downloadURL: string) => {
    setUrl(downloadURL);
  };

  const updateLoadingStatus = (status: boolean) => {
    setLoading(status);
  };

  const imageToken = useSelector(selectImageToken);

  return (
    <div>
      <div
        role="button"
        onClick={handleOpenInputFile}
        onKeyDown={handleOpenInputFile}
        className="image-box"
      >
        <ImageBox>
          <CardMedia
            component="img"
            image={getImageFile(url, imageToken)}
            alt=""
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </ImageBox>
      </div>

      <Modal
        open={openInputFile}
        onClose={handleCloseInputFile}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Grid container alignItems="center" justifyContent="center">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              サンプルを確認の上、画像をアップロードしてください
            </Typography>
          </Grid>
          <Grid container xs={12}>
            <Grid
              item
              xs={6}
              style={{
                border: "1px solid gray",
                boxSizing: "border-box",
                borderColor: "#d9d9d9",
              }}
            >
              <Grid xs={12}>
                <Box display="flex" justifyContent="center">
                  <Typography fontSize={15}>商品画像</Typography>
                </Box>

                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems={"center"}
                  minHeight={400}
                >
                  <label>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      multiple
                      onChange={(e) => {
                        if (e.target?.files?.length === 0) return;
                        onHandleUploadPhoto(
                          e,
                          fileUploadeURL,
                          updateLoadingStatus,
                          itemNumber,
                        );
                      }}
                    />

                    {loading ? (
                      <CircularProgress
                        size={"12vh"}
                        style={{
                          color: "#ccc",
                        }}
                      />
                    ) : (
                      <img
                        alt=""
                        src={getImageFile(url, imageToken)}
                        style={{
                          objectFit: "contain",
                          maxHeight: "50vh",
                          maxWidth: "100%",
                          margin: "auto",
                        }}
                      />
                    )}
                  </label>
                </Box>
              </Grid>
              <Grid container alignItems="center" justifyContent="center">
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 1, color: "red" }}
                >
                  ↑クリックして画像を登録
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={6}
              style={{
                border: "1px solid gray",
                boxSizing: "border-box",
                borderColor: "#d9d9d9",
              }}
            >
              <Box display="flex" justifyContent="center">
                <Typography fontSize={15}>サンプル画像</Typography>
              </Box>

              {/* <img
                alt=""
                src={`${window.location.origin}` + "/image/InputFileSample.png"}
                height="90%"
                style={{
                  width: "90%",
                  WebkitAppearance: "none",
                  display: "flex",
                  margin: "auto",
                }}
              /> */}
              <RequestSampleImage openModalNumber={itemNumber} />
              <Grid>
                <Box display="flex" justifyContent="center">
                  <Typography fontSize={18} color="red" fontWeight="bold">
                    （注意事項）
                    <br />
                    ・商品の一部でも写っていない画像は使用できません。
                    <br />
                    ・なるべく画像のサイズいっぱいに写っている画像を使用してください。
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <br />
          </Grid>

          {/* <Grid>
            <Box display="flex" justifyContent="center">
              <Typography fontSize={15}>
                （注意事項）
                <br />
                ・商品の一部でも写っていない画像は使用できません。
                <br />
                ・なるべく画像のサイズいっぱいに写っている画像を使用してください。
              </Typography>
            </Box>
          </Grid> */}

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ padding: "10px" }}
          >
            <Grid>
              <Button
                variant="contained"
                style={{ width: "100%", margin: "2px" }}
                onClick={() => {
                  setOpenInputFile(false);
                }}
              >
                閉じる
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export default RequestItemCameraUpload;
