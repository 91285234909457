/* ---------------------------------------------------------------------------------- */
import React, { useRef, useCallback, useEffect, useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Tab from "@mui/material/Tab";

import Typography from "@mui/material/Typography";
import { Grid, Box, Button, CardMedia, Modal } from "@mui/material";
import FormControl from "@mui/material/FormControl";

import Header from "../common/Header";
import { AppBar, Tabs } from "@mui/material";
import RequestComentModal from "./RequestComentModal";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";

import { useSelector } from "react-redux";
import { postRequest } from "../../apis/axiosAction";
import { CircularProgress } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import {
  updateRequestItemInfoList,
  requestItemInfoList,
} from "../organisms/search/SummarySearchSlice";
import {
  getAllDo,
  getDo,
  getAllZone,
  getZone,
  getAllExistingSejStores,
  getExistingSejStore,
} from "../../data/existingSejStores";
import { selectImageToken, selectUser } from "../../features/userSlice";
import { getImageFile } from "../common/utils";
import { openWebSite } from "../../apis/util";
import CashPurchaseDialog from "./CashPurchaseDialog";

const setVhVariable = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};
window.addEventListener("resize", setVhVariable);
window.addEventListener("load", setVhVariable);

const circularProgress = css`
  position: absolute;
  top: 35%;
  left: 45%;
  opacity: 0.8;
  color: #ccc;
  // opacity: 0.5;
`;

const content = css`
  margin: 10px 5px 10px 20px;
  color: #000;
`;

const contentsArea1 = css`
  box-sizing: border-box;
  height: 10%;
  width: 100%;
  margin: 2px;
  border: 1px solid #d9d9d9;
`;

const contentsArea2 = css`
  box-sizing: border-box;
  height: 10%;
  width: 100%;
  margin: 2px;
  border: 1px solid #d9d9d9;
`;
const contentsArea3 = css`
  box-sizing: border-box;
  height: 60%;
  width: 100%;
  margin: 2px;
  border: 1px solid #d9d9d9;
`;

const imageItem = css`
  max-width: 100%;
  max-height: 100%;
  display: flex;
  margin: auto;
  object-fit: contain;
`;

const titleArea = css`
  height: calc(var(--vh, 1vh) * 5);
  width: 95vw;
`;
const title = css`
  margin: 5px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
`;

const contentsAreaAll = css`
  height: calc(var(--vh, 1vh) * 78);
  width: 90vw;
  overflow: hidden;
`;

const contentsAreaButton = css`
  height: calc(var(--vh, 1vh) * 8);
  width: 90vw;
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: "auto",
  maxHeight: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
};

const TableBase = styled(Grid)(({ theme }) => ({
  marginTop: "20px",
  backgroundColor: "#d9d9d9",
  padding: "1px",
  width: "85vw",

  [theme.breakpoints.up("lg")]: {
    width: "80vw", // mediumサイズ以上のデバイスではwidthを変更
  },
}));

const TableBase2 = styled(Grid)(({ theme }) => ({
  marginTop: "20px",
  backgroundColor: "#d9d9d9",
  padding: "1px",
  width: "auto",
}));

const ColumnHeader = styled(Grid)({
  color: "white",
  outlineColor: "#3e3e3e",
  backgroundColor: "#4dc0b2",
  margin: "2px",
});

const ContentsList = styled(Grid)(({ theme }) => ({
  height: "64.5vh",
  width: "85vw",
  overflowY: "auto",

  [theme.breakpoints.up("lg")]: {
    height: "67.5vh",
  },
}));

const HeaderHeight = styled(Grid)({
  height: "7vh",
  // "&:hover": {
  //   textDecoration: "underline",
  //   opacity: "1",
  //   cursor: "pointer",
  // },
});

const ColumnResult = styled(Grid)({
  color: "#3e3e3e",
  backgroundColor: "#ffffff",
  verticalAlign: "middle",
  margin: "2px",
});

const ImageBox = styled(Box)({
  cursor: "pointer",
  position: "relative",
  paddingBottom: "100%",
  height: 0,
  overflow: "hidden",
});

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

const checkData = [
  {
    checkItem: "①店舗イメージを損なう商品ではない",
    inputItem: {
      0: "リスク低：問題ない",
      1: " リスク高：店舗イメージと乖離している",
      2: "",
    },
  },
  {
    checkItem: "②密閉された容器で販売",
    inputItem: {
      0: "リスク低：オリジナルのデイリー品同等以上",
      1: "リスク高：オリジナルのデイリー品以下",
      2: "",
    },
  },
  {
    checkItem: "③【食品表示法】必要な食品表示、栄養成分表示がある",
    inputItem: {
      0: "○：必要な項目がある",
      1: "×：必要な項目がない",
      2: "",
    },
  },
  {
    checkItem: "④ 【食品衛生法】既存の店内什器で温度管理可能",
    inputItem: {
      0: "○：温度管理可能",
      1: "×：温度管理が難しい",
      2: "",
    },
  },
  {
    checkItem: "⑤【食品衛生法】HACCP対応 HACCP対応の確認",
    inputItem: {
      0: "○：小規模事業者でHACCPの考えを取り入れた衛生管理を実施している、大規模事業者でHACCPに基づく衛生管理を実施している",
      1: "×：HAACPに沿った衛生管理を実施していない",
      2: "",
    },
  },
  {
    checkItem: "⑥【薬機法】効果効能を標榜していない",
    inputItem: {
      0: "○：健康増進、心身への効果、腸活などの効果機能を標榜していない",
      1: "×：効果機能を標榜している",
      2: "",
    },
  },
  {
    checkItem: "⑦【不当景品類及び不当表示防止法】特色表示による優良誤認",
    inputItem: {
      0: "○：「●●産牛乳を使用」などを表示していない、もしくは産地証明など根拠が明確",
      1: "×：「●●産牛乳を使用」などを表示しており、明確な根拠がない",
      2: "",
    },
  },
  {
    checkItem: "【デイリー品】微生物検査の実施状況",
    inputItem: {
      0: "可：安全性の根拠（微生物検査結果）を持っており、継続的な監視結果について書面での説明ができる",
      1: "否：安全性の根拠（微生物検査結果）がない",
      2: "不要",
    },
  },
  {
    checkItem: "【輸入商品】輸入届がなされているか",
    inputItem: {
      0: "可：安全性の根拠（微生物検査結果）を持っており、継続的な監視結果について書面での説明ができる",
      1: "否：安全性の根拠（微生物検査結果）がない",
      2: "不要",
    },
  },
];

const MDcheckData = [
  {
    checkItem: "①SNS等での批判的なコメント・拡散の可能性は無い",
    inputItem: {
      0: "〇：商品、販売方法についてお客様や世の中の捉え方を考慮している",
      1: "×：拡散の可能性がある",
      2: "",
    },
  },
  {
    checkItem: "②【企業規模】メーカー（仕入れ先）リスクが高くない",
    inputItem: {
      0: "リスク低：SEJ推奨品を扱うメーカー、一部上場企業",
      1: "リスク中：上記以外の株式会社、有限会社",
      2: "リスク高：個人事業者、法人団体（社会福祉法人、特定非営利活動法人）などの企業規模が小さい場合、商品事故発生時の対応力が懸念される",
    },
  },
  {
    checkItem: "③【違反事例】メーカー（仕入れ先）リスクが高くない",
    inputItem: {
      0: "リスク低：違反事例がない。回収事故を頻繁には起こしていない",
      1: "リスク高：違反事例がある。商品回収を頻繁に起こしている",
      2: "",
    },
  },
  {
    checkItem: "④ 7-11オリジナル商品であるとの誤認を与えない",
    inputItem: {
      0: "リスク低：オリジナル商品と明らかに見分けがつく",
      1: "リスク高：一見して、オリジナル商品と見分けがつかない",
      2: "",
    },
  },
  {
    checkItem: "⑤他社商品の意匠権・商標権を侵害していないことを確認している",
    inputItem: {
      0: "リスク低：他社の意匠権、商標登録への侵害に関して、確認できている",
      1: "リスク高：他社の意匠権、商標登録への侵害に関して、確認できていない",
      2: "",
    },
  },
];

const statusDisplay = [
  {
    step: "0",
    "0": "未申請",
    "1": "申請",
    "2": "申請完了",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "中止",
  },
  {
    step: "1",
    "0": "未確認",
    "1": "リスクあり",
    "2": "問題なし",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "",
  },
  {
    step: "2",
    "0": "未確認",
    "1": "リスクあり",
    "2": "問題なし",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "",
  },
  {
    step: "3",
    "0": "未確認",
    "1": "リスクあり",
    "2": "問題なし",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "",
  },
  {
    step: "4",
    "0": "未確認",
    "1": "リスクあり",
    "2": "問題なし",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "",
  },
  {
    step: "5",
    "0": "未確認",
    "1": "申請実施",
    "2": "微生物検査結果提出",
    "3": "食品検査同意書提出",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "申請中止",
  },
  {
    step: "6",
    "0": "未確認",
    "1": "表示適合",
    "2": "表示不適合",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "",
  },
  {
    step: "7",
    "0": "未確認",
    "1": "表示適合",
    "2": "",
    "3": "",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "表示不適合",
  },
  {
    step: "8",
    "0": "未確認",
    "1": "リスクあり",
    "2": "問題なし",
    "3": "確認不要",
    "4": "",
    "5": "",
    "6": "",
    "7": "",
    "8": "差戻",
    "9": "",
  },
  {
    step: "9",
    "0": "未確認",
    "1": "検査あり",
    "2": "検査なし",
    "3": "",
    "4": "微生物検査結果合格",
    "5": "食品検査同意書OK",
    "6": "食品検査同意書NG",
    "7": "",
    "8": "差戻",
    "9": "微生物検査結果不合格",
  },
];

/* ---------------------------------------------------------------------------------- */
//tabのコンポーネント作成エリア
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

interface LinkTabProps {
  label?: string;
  href?: string;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
      style={{
        fontSize: "20px",
      }}
    />
  );
}

// ステータスの名変換
function getDisplayStatus(step: any, status: string) {
  const rule: any = statusDisplay.find((rule) => rule.step === step.toString());
  return rule ? rule[status] : null;
}

//データ表示用の配列
let resultList: any = {}; //詳細リストの全体
let confirmData: any = {}; //認証データのJson
let step: any = "";
let newValueList: any = {};
let inspect = "";
let consent = "";
let updateDate = "";
/* ---------------------------------------------------------------------------------- */

const RequestDbContents: React.FC<{}> = () => {
  const windowName = "現金仕入システム";
  // 帳票出力権限（6：外部業者：表示確認）
  const printAllowed = ["6"];
  //tab切り替えのState
  const [value, setValue] = React.useState(0);
  //内容切り替えのプルダウンのhandleChange
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const location = useLocation();
  const navigate = useNavigate();
  //DB画面でクリックされたIDの受け渡し
  const [selectId, setSelectId] = React.useState("");

  //ログイン時に取得されたユーザ情報
  const userInfo: any = useSelector(selectUser);
  //ユーザー情報の受け取り
  let logInUser = userInfo.userAuth;

  const infoList: any = useSelector(requestItemInfoList);

  //Modalの開閉
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //Modalの開閉
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  /* ---------------------------------------------------------------------------------- */

  const [apiData, setApiData] = React.useState<[]>([]);
  const [loading, setLoading] = React.useState(false);

  const imageToken = useSelector(selectImageToken);

  // メッセージ用ダイアログ設定
  const [dialogMessage, setDialogMessage] = React.useState<any[]>([]);
  const [dialogFlg, setDialogFlg] = React.useState(false);

  // ダイアログ閉じる
  const dialogClose = () => {
    setDialogFlg(false);
  };

  //データ取得
  useEffect(() => {
    // 申請ID
    let tempSelectedId = "";
    // 選択された申請ID
    if (location.state) {
      tempSelectedId = location.state.data;
    }
    const windowUrl: URL = new URL(window.location.href);
    // URLパラメーターの申請IDを取得
    windowUrl.searchParams.forEach(function (value: string, key: string) {
      if (key === "id") {
        tempSelectedId = value;
      }
    });
    // 連携データ存在チェック（直リンク及びリロード判定）
    if (tempSelectedId === "") {
      // 連携データがない＝直リンク及びリロード時は業務フロートップへ遷移する。
      navigate("/requestDb");
      return;
    }
    setSelectId(tempSelectedId);
    setLoading(true);
    let apiParams = {
      query:
        "query {  detailSearch" +
        '(id: "' +
        tempSelectedId +
        '", ' +
        'step:"' +
        logInUser +
        '") { id docLastId createDate employeeName updateDate updateUser ofcId employeeNo employeeName mailaddress telNo doCode doName zoCode zoName confirmStep confirmDataJson checkDaily checkImport checkPb check1 check2 check3 check4 check5 check6 check7 check8 check9 productName makerName tenpoCode tenpoName recycleMark costPrice sellingPrice bestBeforeDays image1 image2 image3 image4 image5 inspectionResultUrl consentFormUrl }}',
    };
    const url = process.env.REACT_APP_API_URL + "graphql";
    postRequest(url, apiParams) // ここには実際のAPIのURLを指定します。
      .then((response) => {
        const data = response.data.data.detailSearch;
        if (Array.isArray(data) && data.length === 0) {
          // データがない場合は業務フロートップへ遷移する。
          navigate("/requestDb");
          return;
        }
        setApiData(data); // 取得したデータをステートにセット
        //APIから取得したデータを配列に詰める
        resultList = data;
        if (resultList[0] && resultList[0].confirmDataJson) {
          confirmData = JSON.parse(resultList[0].confirmDataJson);
          step = resultList[0].confirmStep;
          loginAuthChange(step);
          inspect = resultList[0].inspectionResultUrl;
          consent = resultList[0].consentFormUrl;
          updateDate = resultList[0].updateDate;

          // 一時対応 外部業者ユーザの場合、JSON型のデータを6番目に入れ替える TODO
          if (logInUser === "6") {
            let tmpConfirmData = new Array(10).fill(null);
            tmpConfirmData[6] = confirmData[0];
            confirmData = tmpConfirmData;
          }
        }

        if (resultList[0]) {
          const tenpoList = resultList[0].tenpoCode.map(
            (code: any, index: any) => {
              return {
                tenpoCode: code,
                tenpoName: getExistingSejStore(code)?.tenpo_name,
                tenpoTel: getExistingSejStore(code)?.tenpo_tel,
              };
            },
          );
          const step = String(Number(resultList[0].confirmStep) + 1);

          newValueList = {
            user: logInUser,
            inputReqestInfoList: {
              dailyItem: resultList[0].checkDaily,
              importItem: resultList[0].checkImport,
              BPitem: resultList[0].checkPb,
            },
            checkListInfo: {
              check1: resultList[0].check1,
              check2: resultList[0].check2,
              check3: resultList[0].check3,
              check4: resultList[0].check4,
              check5: resultList[0].check5,
              check6: resultList[0].check6,
              check7: resultList[0].check7,
              check8: resultList[0].check8,
              check9: resultList[0].check9,
            },
            tenpoInfoList: tenpoList,
            zoInfoList: resultList[0].zoCode,
            doInfoList: resultList[0].doCode,
            itemInfoList: {
              id: tempSelectedId,
              telNo: resultList[0].telNo,
              itemName: resultList[0].productName,
              makerName: resultList[0].makerName,
              recycle: resultList[0].recycleMark,
              costPrice: resultList[0].costPrice,
              sellPrice: resultList[0].sellingPrice,
              limitDay: resultList[0].bestBeforeDays,
              category: "",
              image1Url: resultList[0].image1,
              image2Url: resultList[0].image2,
              image3Url: resultList[0].image3,
              image4Url: resultList[0].image4,
              image5Url: resultList[0].image5,
              confirmStep: step,
            },
            checkItem: ["1", "2", "3", "4", "5", "6"],
          };
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error); // エラーハンドリング
        setDialogMessage([String(error)]);
        setDialogFlg(true);
      });
  }, []); // useEffectの依存配列は空にして、コンポーネントのマウント時にだけAPIからデータを取得

  // ユーザの権限変換
  function loginAuthChange(step: string) {
    // OFCでログイン かつ 申請段階がOFC申請判断の場合、権限変更(5)
    if (logInUser === "0" && step === "5") {
      logInUser = step;
    }
    // QCでログイン かつ 申請段階がQC上長確認の場合、権限変更(8)
    if (logInUser === "7" && step === "8") {
      logInUser = step;
    }
    // QCでログイン かつ 申請段階がQC表示確認の場合、権限変更(9)
    if (logInUser === "7" && step === "9") {
      logInUser = step;
    }
  }

  const dispatch = useDispatch();

  function clickEdit() {
    dispatch(updateRequestItemInfoList(newValueList));
    navigate("/request");
  }

  /* ---------------------------------------------------------------------------------- */
  //サーバ時間を変換
  const changeDate = (date: any) => {
    const newDate = new Date(date);
    const formattedDate = `${newDate.getFullYear()}/${
      newDate.getMonth() + 1
    }/${newDate.getDate()}`;
    return <>{formattedDate}</>;
  };

  /* ---------------------------------------------------------------------------------- */
  const refs = Array.from({ length: 5 }, () =>
    React.createRef<HTMLDialogElement>(),
  );

  // ダイアログを開く処理
  const handleOpenDialog = useCallback(
    (index: number) => {
      refs[index]?.current?.showModal();
    },
    [refs],
  );

  // ダイアログを閉じる処理
  const handleCloseDialog = useCallback(
    (index: number) => {
      refs[index]?.current?.close();
    },
    [refs],
  );
  // ダイアログ内のクリックイベントハンドラーに渡す処理
  const handleClickInDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
    },
    [],
  );

  // 出力できるか
  const isPrint = printAllowed.includes(logInUser);

  /* ---------------------------------------------------------------------------------- */
  // 帳票出力

  // ダウンロードModalの開閉
  const [downloadModalOpen, setDownloadModalOpen] = React.useState(false);
  const [downloadUrl, setDownloadUrl] = React.useState("");
  const handleDownloadOpen = () => setDownloadModalOpen(true);
  const handleDownloadClose = () => setDownloadModalOpen(false);

  const handleDownloadForm = () => {
    openWebSite(downloadUrl);
  };

  async function print(): Promise<void> {
    setLoading(true);
    const selectedKeys = [selectId];

    const url: string = process.env.REACT_APP_API_URL + "output-report";
    const param = {
      report_id: selectedKeys,
    };
    try {
      const response = await postRequest(url, param, false);
      const filePaths: string[] = response.data.dataList.map(
        (item: any) => item.file_path,
      );
      const filepath =
        process.env.REACT_APP_CLOUD_STORAGE_PRINT_URL + filePaths[0];
      setDownloadUrl(filepath);
      handleDownloadOpen();
    } catch (error) {
      // 必要があれば
      console.error(error); // エラーハンドリング
      setDialogMessage([String(error)]);
      setDialogFlg(true);
    }

    setLoading(false);
  }

  /* ---------------------------------------------------------------------------------- */
  //チェック項目の入力一覧表示
  function checkListContects() {
    return (
      <Grid item>
        <TableBase container>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#d9d9d9",
            }}
          >
            <Grid item lg={6} md={6} sm={6}>
              <ColumnHeader item>
                <HeaderHeight
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  チェック項目
                </HeaderHeight>
              </ColumnHeader>
            </Grid>
            <Grid item lg={6} md={6} sm={6}>
              <ColumnHeader item>
                <HeaderHeight
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  入力内容
                </HeaderHeight>
              </ColumnHeader>
            </Grid>
          </Grid>

          {checkData.map((row, i) => (
            <Grid container alignItems="center">
              <Grid item lg={6} md={6} sm={6}>
                <ColumnResult item>
                  <Grid container sx={{ height: "7vh" }} alignItems="center">
                    {row?.checkItem}
                  </Grid>
                </ColumnResult>
              </Grid>

              <Grid item lg={6} md={6} sm={6}>
                <ColumnResult item>
                  <Grid container sx={{ height: "7vh" }} alignItems="center">
                    {resultList.length > 0
                      ? resultList[0][`check${i + 1}`] === "0"
                        ? row.inputItem[0]
                        : resultList[0][`check${i + 1}`] === "1"
                        ? row.inputItem[1]
                        : resultList[0][`check${i + 1}`] === "2"
                        ? row.inputItem[2]
                        : "データがありません"
                      : "データがありません"}
                  </Grid>
                </ColumnResult>
              </Grid>
            </Grid>
          ))}
        </TableBase>
      </Grid>
    );
  }

  /* ---------------------------------------------------------------------------------- */
  //各認証者の入力情報
  function inputComent() {
    return (
      <Grid item>
        <TableBase container>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#d9d9d9",
            }}
          >
            <Grid item lg={2} md={2} sm={2}>
              <ColumnHeader item>
                <Grid
                  container
                  sx={{
                    height: "7vh",
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  確認者
                </Grid>
              </ColumnHeader>
            </Grid>

            <Grid item lg={1.5} md={1.5} sm={1.5}>
              <ColumnHeader item>
                <Grid
                  container
                  sx={{
                    height: "7vh",
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  確認日
                </Grid>
              </ColumnHeader>
            </Grid>

            <Grid item lg={2} md={2} sm={2}>
              <ColumnHeader item>
                <Grid
                  container
                  sx={{
                    height: "7vh",
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  ステータス
                </Grid>
              </ColumnHeader>
            </Grid>

            <Grid item lg={6.5} md={6.5} sm={6.5}>
              <ColumnHeader item>
                <Grid
                  container
                  sx={{
                    height: "7vh",
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  コメント
                </Grid>
              </ColumnHeader>
            </Grid>
          </Grid>
          {confirmData.length > 0 &&
            confirmData.map((row: any, index: any) => {
              if (logInUser !== "6" || (logInUser === "6" && index === 6)) {
                //外部業者がログイン時にその他の内容を非表示にする
                return (
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item lg={2} md={2} sm={2}>
                      <ColumnResult item>
                        <Grid
                          container
                          sx={{ height: "21vh" }}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {row?.display_name}
                        </Grid>
                      </ColumnResult>
                    </Grid>

                    <Grid item lg={1.5} md={1.5} sm={1.5}>
                      <ColumnResult item>
                        <Grid
                          container
                          sx={{ height: "21vh" }}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {row?.confirm_date
                            ? changeDate(row?.confirm_date)
                            : "-"}
                        </Grid>
                      </ColumnResult>
                    </Grid>

                    <Grid item lg={2} md={2} sm={2}>
                      <ColumnResult item>
                        <Grid
                          container
                          sx={{ height: "21vh" }}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {getDisplayStatus(index, row?.status)}
                        </Grid>
                      </ColumnResult>
                    </Grid>

                    <Grid item lg={5} md={5} sm={5}>
                      <ColumnResult item>
                        {/* <CustomTooltip
                          title={
                            <div>
                              {row.comment?.map((comment: any, index: any) =>
                                comment
                                  .replace(/\\n/g, "\n")
                                  .split("\n")
                                  .map((line: any, lineIndex: any) => (
                                    <p
                                      key={`${index}-${lineIndex}`}
                                      style={{ margin: 0 }}
                                    >
                                      {line}
                                    </p>
                                  )),
                              )}
                            </div>
                          }
                          placement="top"
                          enterTouchDelay={0}
                          leaveTouchDelay={3000}
                        > */}
                        <Grid
                          container
                          sx={{ height: "21vh", overflowY: "auto" }}
                          alignItems="center"
                          justifyContent="center"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {row?.comment
                            ? row?.comment.map((comment: any) => (
                                <>
                                  {comment?.replace(/\\n/g, "<br/>")}
                                  <br />
                                </>
                              ))
                            : "-"}
                        </Grid>
                        {/* </CustomTooltip> */}
                      </ColumnResult>
                    </Grid>

                    <Grid item lg={1.5} md={1.5} sm={1.5}>
                      <RequestComentModal
                        comentType={index.toString()}
                        selectId={selectId}
                        confirmStep={step}
                        confirmStatus={row?.status}
                        inspectionResultUrl={inspect}
                        consentFormUrl={consent}
                        userAuth={logInUser}
                        updateDate={updateDate}
                        confirmDataUpdateDate={row?.update_date}
                        file1={resultList[0]?.inspectionResultUrl}
                        file2={resultList[0]?.consentFormUrl}
                      />
                      {(index === 0 || index === 3 || index === 5) &&
                        (index === 3 ? (
                          <Grid
                            item
                            sx={{
                              color: "white",
                              outlineColor: "#3e3e3e",
                              backgroundColor:
                                row?.md_check1 !== null && row?.md_check1 !== ""
                                  ? "#66E09D"
                                  : "#a9a9a9",
                              margin: "2px",
                              "&:hover": {
                                backgroundColor:
                                  row?.md_check1 !== null &&
                                  row?.md_check1 !== ""
                                    ? "#B3F0CE"
                                    : "#a9a9a9",
                                cursor: "pointer",
                                opacity: "1",
                              },
                            }}
                          >
                            <Grid
                              container
                              sx={{
                                height: "10.5vh",
                                "&:hover": {
                                  opacity: "1",
                                  cursor: "pointer",
                                },
                              }}
                              alignItems="center"
                              justifyContent="center"
                              onClick={() => {
                                if (
                                  row?.md_check1 !== null &&
                                  row?.md_check1 !== ""
                                ) {
                                  handleOpen();
                                }
                              }}
                            >
                              MD確認内容
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            sx={{
                              color: "white",
                              outlineColor: "#3e3e3e",
                              backgroundColor:
                                (step === "0" || step === "5") &&
                                row?.status === "8" &&
                                (resultList[0]?.inspectionResultUrl === null ||
                                  resultList[0]?.inspectionResultUrl === "") &&
                                (index.toString() === "0" ||
                                  index.toString() === "5")
                                  ? "#66E09D"
                                  : "#a9a9a9",
                              margin: "2px",
                              "&:hover": {
                                backgroundColor:
                                  (step === "0" || step === "5") &&
                                  row?.status === "8" &&
                                  (resultList[0]?.inspectionResultUrl ===
                                    null ||
                                    resultList[0]?.inspectionResultUrl ===
                                      "") &&
                                  (index.toString() === "0" ||
                                    index.toString() === "5")
                                    ? "#B3F0CE"
                                    : "#a9a9a9",
                                cursor: "pointer",
                                opacity: "1",
                              },
                            }}
                          >
                            <Grid
                              container
                              sx={{
                                height: "10.5vh",
                                "&:hover": {
                                  opacity: "1",
                                  cursor: "pointer",
                                },
                              }}
                              alignItems="center"
                              justifyContent="center"
                              onClick={() => {
                                if (
                                  (step === "0" || step === "5") &&
                                  row?.status === "8" &&
                                  (resultList[0]?.inspectionResultUrl ===
                                    null ||
                                    resultList[0]?.inspectionResultUrl ===
                                      "") &&
                                  (index.toString() === "0" ||
                                    index.toString() === "5")
                                )
                                  clickEdit();
                              }}
                            >
                              編集
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                );
              } else return null;
            })}
        </TableBase>
      </Grid>
    );
  }

  /* ---------------------------------------------------------------------------------- */

  const handleDownload1 = () => {
    // PDFのURL
    const pdfUrl = getImageFile(resultList[0].inspectionResultUrl, imageToken);

    // 新しいウィンドウを開いてPDFをダウンロード
    window.open(pdfUrl, "_blank");
  };

  const handleDownload2 = () => {
    // PDFのURL
    const pdfUrl = getImageFile(resultList[0].consentFormUrl, imageToken);

    // 新しいウィンドウを開いてPDFをダウンロード
    window.open(pdfUrl, "_blank");
  };

  const handleDownload = () => {
    const inspectionConsentFormPath: string =
      process.env.REACT_APP_INSPECTION_CONSENT_FROM + "";
    const wordUrl = getImageFile(inspectionConsentFormPath, imageToken);

    window.open(wordUrl, "_blank");
  };

  return loading ? (
    <div>
      <CircularProgress css={circularProgress} size={120} />
      <Header windowName={windowName} />
    </div>
  ) : (
    <>
      <Header windowName={windowName} />

      <Grid container alignItems="center" justifyContent="center">
        {/* タイトルエリア-START */}
        <Grid container css={titleArea}>
          <Grid item xs={12}>
            <Typography variant="h3" css={title}>
              現金仕入申請データベース
            </Typography>
          </Grid>
        </Grid>
        {/* タイトルエリア-END */}

        <Grid container css={contentsAreaAll}>
          <AppBar position="static">
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#37ab9d",
                  height: "10px",
                },
              }}
              style={{
                backgroundColor: "#f2f2f2",
                color: "#3e3e3e",
              }}
            >
              <LinkTab label="申請内容" href="/print" {...a11yProps(0)} />
              {logInUser !== "6" && (
                <LinkTab
                  label="申請チェックリスト内容"
                  href="/print"
                  {...a11yProps(1)}
                />
              )}
              <LinkTab
                label="申請確認"
                href="/summery"
                {...a11yProps(logInUser !== "6" ? 2 : 1)}
              />
            </Tabs>
          </AppBar>

          <TabPanel value={value} index={0}>
            {/*入力エリアエリア-START */}
            <ContentsList container>
              {/* 1行目 */}
              <Grid container css={contentsArea1} alignItems="center">
                <Grid item>
                  <Typography css={content}>
                    DO名：
                    {resultList.length > 0
                      ? resultList[0].doName
                      : "データがありません"}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography css={content}>
                    OFC名：
                    {resultList.length > 0
                      ? resultList[0].employeeName
                      : "データがありません"}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography css={content}>
                    電話番号 ：
                    {resultList.length > 0
                      ? resultList[0].telNo
                      : "データがありません"}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography css={content}>
                    店舗名 ：{" "}
                    {resultList.length > 0
                      ? resultList[0].tenpoName.join("/")
                      : "データがありません"}
                  </Typography>
                </Grid>
              </Grid>

              {/* 2行目 */}
              <Grid container css={contentsArea2} alignItems="center">
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography css={content}>
                        商品名：{" "}
                        {resultList.length > 0
                          ? resultList[0].productName
                          : "データがありません"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography css={content}>
                        メーカー名：{" "}
                        {resultList.length > 0
                          ? resultList[0].makerName
                          : "データがありません"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography css={content}>
                        商品（容器・パッケージ）にリサイクルマークの有無：
                        {resultList.length > 0
                          ? resultList[0].recycleMark === "0"
                            ? "有"
                            : resultList[0].recycleMark === "1"
                            ? "無"
                            : "データがありません"
                          : "データがありません"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* 3行目 */}
              <Grid container css={contentsArea2} alignItems="center">
                {logInUser !== "6" && (
                  <Grid item>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography css={content}>
                          原価：
                          {resultList.length > 0
                            ? resultList[0].costPrice
                            : "データがありません"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {logInUser !== "6" && (
                  <Grid item>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography css={content}>
                          売価：
                          {resultList.length > 0
                            ? resultList[0].sellingPrice
                            : "データがありません"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography css={content}>
                        製造から賞味期限までの日数（賞味期限1ヶ月以内の場合メーカーへ聞き取り）：
                        {resultList.length > 0
                          ? resultList[0].bestBeforeDays
                          : "データがありません"}
                        日
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* 入力エリア-END */}

              {/* 画像アップロードエリア-START */}
              <Grid container css={contentsArea3} style={{ margin: "2px" }}>
                {/* 画面左：チェックエリア */}
                <Grid container xs={12}>
                  {/* 1行目 */}
                  <Grid
                    item
                    xs={2.4}
                    style={{
                      border: "1px solid #d9d9d9",
                      boxSizing: "border-box",
                    }}
                  >
                    <Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          margin: "10px",
                          borderBottom: "1px solid #d9d9d9",
                          boxSizing: "border-box",
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          style={{ height: "40px" }}
                        >
                          <Typography fontSize={15}>①商品全体画像*</Typography>
                        </Box>
                      </Grid>

                      {resultList.length > 0 ? (
                        <FormControl fullWidth>
                          <div
                            role="button"
                            onClick={() => handleOpenDialog(0)}
                            onKeyDown={() => handleOpenDialog(0)}
                            className="image-box"
                            tabIndex={0}
                          >
                            <ImageBox>
                              <CardMedia
                                component="img"
                                image={getImageFile(
                                  resultList[0].image1,
                                  imageToken,
                                )}
                                alt=""
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </ImageBox>
                          </div>
                          <dialog
                            ref={refs[0]}
                            className="image-dialog"
                            onClick={() => handleCloseDialog(0)}
                          >
                            <div
                              onClick={handleClickInDialog}
                              className="contents"
                            >
                              <img
                                src={getImageFile(
                                  resultList[0].image1,
                                  imageToken,
                                )}
                                alt=""
                                width="100%"
                              />
                            </div>
                          </dialog>
                        </FormControl>
                      ) : (
                        <Typography fontSize={15}>
                          データがありません
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={2.4}
                    style={{
                      border: "1px solid #d9d9d9",
                      boxSizing: "border-box",
                    }}
                  >
                    <Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          margin: "10px",
                          borderBottom: "1px solid #d9d9d9",
                          boxSizing: "border-box",
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          style={{ height: "40px" }}
                        >
                          <Typography fontSize={15}>
                            ②商品全体画像（裏面/分割）*
                          </Typography>
                        </Box>
                      </Grid>
                      {resultList.length > 0 ? (
                        <FormControl fullWidth>
                          <div
                            role="button"
                            onClick={() => handleOpenDialog(1)}
                            onKeyDown={() => handleOpenDialog(1)}
                            className="image-box"
                            tabIndex={0}
                          >
                            <ImageBox>
                              <CardMedia
                                component="img"
                                image={getImageFile(
                                  resultList[0].image2,
                                  imageToken,
                                )}
                                alt=""
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </ImageBox>
                          </div>
                          <dialog
                            ref={refs[1]}
                            className="image-dialog"
                            onClick={() => handleCloseDialog(1)}
                          >
                            <div
                              onClick={handleClickInDialog}
                              className="contents"
                            >
                              <img
                                src={getImageFile(
                                  resultList[0].image2,
                                  imageToken,
                                )}
                                alt=""
                                width="100%"
                              />
                            </div>
                          </dialog>
                        </FormControl>
                      ) : (
                        <Typography fontSize={15}>
                          データがありません
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  {/* 2行目 */}
                  <Grid
                    item
                    xs={2.4}
                    style={{
                      border: "1px solid #d9d9d9",
                      boxSizing: "border-box",
                    }}
                  >
                    <Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          margin: "10px",
                          borderBottom: "1px solid #d9d9d9",
                          boxSizing: "border-box",
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          style={{ height: "40px" }}
                        >
                          <Typography fontSize={15}>
                            ③賞味期限表示画像
                          </Typography>
                        </Box>
                      </Grid>
                      {resultList.length > 0 ? (
                        <FormControl fullWidth>
                          <div
                            role="button"
                            onClick={() => handleOpenDialog(2)}
                            onKeyDown={() => handleOpenDialog(2)}
                            className="image-box"
                            tabIndex={0}
                          >
                            <ImageBox>
                              <CardMedia
                                component="img"
                                image={getImageFile(
                                  resultList[0].image3,
                                  imageToken,
                                )}
                                alt=""
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </ImageBox>
                          </div>
                          <dialog
                            ref={refs[2]}
                            className="image-dialog"
                            onClick={() => handleCloseDialog(2)}
                          >
                            <div
                              onClick={handleClickInDialog}
                              className="contents"
                            >
                              <img
                                src={getImageFile(
                                  resultList[0].image3,
                                  imageToken,
                                )}
                                alt=""
                                width="100%"
                              />
                            </div>
                          </dialog>
                        </FormControl>
                      ) : (
                        <Typography fontSize={15}>
                          データがありません
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={2.4}
                    style={{
                      border: "1px solid #d9d9d9",
                      boxSizing: "border-box",
                    }}
                  >
                    <Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          margin: "10px",
                          borderBottom: "1px solid #d9d9d9",
                          boxSizing: "border-box",
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          style={{ height: "40px" }}
                        >
                          <Typography fontSize={15}>
                            ④栄養成分表示画像
                          </Typography>
                        </Box>
                      </Grid>
                      {resultList.length > 0 ? (
                        <FormControl fullWidth>
                          <div
                            role="button"
                            onClick={() => handleOpenDialog(3)}
                            onKeyDown={() => handleOpenDialog(3)}
                            className="image-box"
                            tabIndex={0}
                          >
                            <ImageBox>
                              <CardMedia
                                component="img"
                                image={getImageFile(
                                  resultList[0].image4,
                                  imageToken,
                                )}
                                alt=""
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </ImageBox>
                          </div>
                          <dialog
                            ref={refs[3]}
                            className="image-dialog"
                            onClick={() => handleCloseDialog(3)}
                          >
                            <div
                              onClick={handleClickInDialog}
                              className="contents"
                            >
                              <img
                                src={getImageFile(
                                  resultList[0].image4,
                                  imageToken,
                                )}
                                alt=""
                                width="100%"
                              />
                            </div>
                          </dialog>
                        </FormControl>
                      ) : (
                        <Typography fontSize={15}>
                          データがありません
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={2.4}
                    style={{
                      border: "1px solid #d9d9d9",
                      boxSizing: "border-box",
                    }}
                  >
                    <Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          margin: "10px",
                          borderBottom: "1px solid #d9d9d9",
                          boxSizing: "border-box",
                        }}
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          style={{ height: "40px" }}
                        >
                          <Typography fontSize={15}>
                            ⑤原材料名表示部分画像*
                          </Typography>
                        </Box>
                      </Grid>
                      {resultList.length > 0 ? (
                        <FormControl fullWidth>
                          <div
                            role="button"
                            onClick={() => handleOpenDialog(4)}
                            onKeyDown={() => handleOpenDialog(4)}
                            className="image-box"
                            tabIndex={0}
                          >
                            <ImageBox>
                              <CardMedia
                                component="img"
                                image={getImageFile(
                                  resultList[0].image5,
                                  imageToken,
                                )}
                                alt=""
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </ImageBox>
                          </div>
                          <dialog
                            ref={refs[4]}
                            className="image-dialog"
                            onClick={() => handleCloseDialog(4)}
                          >
                            <div
                              onClick={handleClickInDialog}
                              className="contents"
                            >
                              <img
                                src={getImageFile(
                                  resultList[0].image5,
                                  imageToken,
                                )}
                                alt=""
                                width="100%"
                              />
                            </div>
                          </dialog>
                        </FormControl>
                      ) : (
                        <Typography fontSize={15}>
                          データがありません
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ContentsList>
          </TabPanel>
          {logInUser !== "6" && (
            <TabPanel value={value} index={1}>
              <ContentsList container>{checkListContects()}</ContentsList>
            </TabPanel>
          )}

          <TabPanel value={value} index={logInUser !== "6" ? 2 : 1}>
            {confirmData && (
              <ContentsList container>{inputComent()}</ContentsList>
            )}
          </TabPanel>
        </Grid>

        <Grid container css={contentsAreaButton}>
          <Grid container xs={12}>
            <Grid item xs={2}>
              <Button
                variant="contained"
                style={{ width: "90%", margin: "2px" }}
                onClick={() => {
                  navigate("/requestDb");
                }}
              >
                一覧に戻る
              </Button>
            </Grid>

            <Grid item xs={2}></Grid>

            {isPrint ? (
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  style={{ width: "90%", margin: "2px" }}
                  onClick={() => {
                    print();
                  }}
                >
                  帳票出力
                </Button>
              </Grid>
            ) : (
              <Grid item xs={2}></Grid>
            )}

            <Grid item xs={3}>
              {(logInUser === "7" || logInUser === "8" || logInUser === "9") &&
                resultList &&
                resultList[0] && (
                  <Button
                    disabled={!resultList[0].inspectionResultUrl}
                    variant="contained"
                    style={{ width: "90%", margin: "2px" }}
                    onClick={() => {
                      handleDownload1();
                    }}
                  >
                    微生物検査ダウンロード
                  </Button>
                )}
            </Grid>
            <Grid item xs={3}>
              {(logInUser === "7" || logInUser === "8" || logInUser === "9") &&
                resultList &&
                resultList[0] && (
                  <Button
                    disabled={!resultList[0].consentFormUrl}
                    variant="contained"
                    style={{ width: "90%", margin: "2px" }}
                    onClick={() => {
                      handleDownload2();
                    }}
                  >
                    食品検査同意書ダウンロード
                  </Button>
                )}
              {(logInUser === "0" || logInUser === "5") && (
                <Button
                  variant="contained"
                  style={{ width: "100%", margin: "2px" }}
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  食品検査代行同意書ダウンロード
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {logInUser !== "6" && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container alignItems="center" justifyContent="center">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                MD確認内容
                <br />
              </Typography>
            </Grid>
            <TableBase2 container>
              {confirmData.length > 0 &&
                MDcheckData.map((row, i) => (
                  <Grid container alignItems="center">
                    <Grid item lg={6} md={6} sm={6}>
                      <ColumnResult item>
                        <Grid
                          container
                          sx={{ height: "7vh" }}
                          alignItems="center"
                        >
                          {row?.checkItem}
                        </Grid>
                      </ColumnResult>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6}>
                      <ColumnResult item>
                        <Grid
                          container
                          sx={{ height: "7vh" }}
                          alignItems="center"
                        >
                          {confirmData[3]?.[`md_check${i + 1}`] === "0"
                            ? row.inputItem[0]
                            : confirmData[3]?.[`md_check${i + 1}`] === "1"
                            ? row.inputItem[1]
                            : confirmData[3]?.[`md_check${i + 1}`] === "2"
                            ? row.inputItem[2]
                            : "データがありません"}
                        </Grid>
                      </ColumnResult>
                    </Grid>
                  </Grid>
                ))}
            </TableBase2>

            <Grid container alignContent="center">
              <Grid item xs={5}></Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  style={{
                    width: "90%",
                    margin: "2px",
                    backgroundColor: "#C4C4C4",
                    color: "black",
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  閉じる
                </Button>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
          </Box>
        </Modal>
      )}

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container alignItems="center" justifyContent="center">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              編集画面
            </Typography>
          </Grid>

          <Grid container alignContent="center">
            <Grid item xs={5}></Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                style={{
                  width: "90%",
                  margin: "2px",
                  backgroundColor: "#C4C4C4",
                  color: "black",
                }}
                onClick={() => {
                  handleClose1();
                }}
              >
                閉じる
              </Button>
            </Grid>
            <Grid item xs={5}></Grid>
          </Grid>
        </Box>
      </Modal>
      {/* ダウンロード確認モーダルstart */}
      <Modal
        open={downloadModalOpen}
        onClose={handleDownloadClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: "30%" }}>
          <>
            <Grid container alignContent="center">
              <Grid item xs={12}>
                <Typography fontSize={16} component="h2">
                  帳票の作成が完了しました。
                  <br />
                  ダウンロードしますか？
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  style={{
                    width: "90%",
                    margin: "2px",
                    backgroundColor: "#C4C4C4",
                    color: "black",
                  }}
                  onClick={() => {
                    handleDownloadClose();
                    handleDownloadForm();
                  }}
                >
                  ダウンロード
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  style={{
                    width: "90%",
                    margin: "2px",
                    backgroundColor: "#C4C4C4",
                    color: "black",
                  }}
                  onClick={handleDownloadClose}
                >
                  キャンセル
                </Button>
              </Grid>
            </Grid>
          </>
        </Box>
      </Modal>
      {/* ダウンロード確認モーダルend */}
      <CashPurchaseDialog
        msg={dialogMessage}
        isOpen={dialogFlg}
        doOK={dialogClose}
        doNo={dialogClose}
        isAlert={true}
      />
    </>
  );
};
export default RequestDbContents;
