import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectDoInfoList } from "../SummarySearchSlice";

import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getAllDo,
  getDo,
  getAllZone,
  getZone,
  getAllExistingSejStores,
  getExistingSejStore,
} from "../../../../data/existingSejStores";

// DO別の型
export interface DO_RECORD {
  // 各アプリケーション共通項目
  do_code: string;
  do_name: string;
}

// DOの型のオブジェクトの集まり
export interface INTERFACE_DO_DATA {
  [index: string]: DO_RECORD;
}

// DOの型
export interface INTERFACE_DO {
  do_data: INTERFACE_DO_DATA;
  count: number;
}

// DOの初期値
const initialDo: INTERFACE_DO = {
  // TODO dummy data
  // do_data: {},
  do_data: {
    "00102": { do_code: "00102", do_name: "北見" },
    "00103": { do_code: "00103", do_name: "石狩" },
    "00104": { do_code: "00104", do_name: "小樽" },
    "00105": { do_code: "00105", do_name: "札幌中央" },
    "00106": { do_code: "00106", do_name: "苫小牧東" },
    "00107": { do_code: "00107", do_name: "函館" },
    "00108": { do_code: "00108", do_name: "岩見沢" },
    "00109": { do_code: "00109", do_name: "帯広" },
    "00122": { do_code: "00122", do_name: "盛岡" },
    "00131": { do_code: "00131", do_name: "石巻" },
    "00132": { do_code: "00132", do_name: "仙台北" },
    "00133": { do_code: "00133", do_name: "仙台西" },
    "00142": { do_code: "00142", do_name: "秋田北" },
    "00151": { do_code: "00151", do_name: "山形" },
    "00152": { do_code: "00152", do_name: "庄内" },
    "00153": { do_code: "00153", do_name: "米沢" },
    "00161": { do_code: "00161", do_name: "会津" },
    "00162": { do_code: "00162", do_name: "郡山" },
    "00173": { do_code: "00173", do_name: "古河" },
    "00174": { do_code: "00174", do_name: "つくば" },
    "00175": { do_code: "00175", do_name: "水戸北" },
    "00176": { do_code: "00176", do_name: "鹿嶋" },
    "00251": { do_code: "00251", do_name: "江東" },
  },
  count: 0,
};

const doData = {
  // DO
  do: initialDo,
};

// // DO情報の全量の情報を返す
// export const getAllDo = () => {
//   return doData.do;
// };

// // doCodeのあるDOのデータを返す
// export const getDo = (doCode: string): DO_RECORD => {
//   return doData.do.do_data[doCode];
// };

const PaperDiv = styled("div")<{ props?: any }>(({ props }) => props.paper);
const ListPaperDiv = styled("div")<{ props?: any }>(
  ({ props }) => props.listPaper,
);
const SearchButton = styled("button")<{ props?: any }>(
  ({ props }) => props.searchButton,
);
const SelectButtonDiv = styled("div")<{ props?: any }>(
  ({ props }) => props.selectButton,
);

const SearchDO: React.FC<{
  styles: any;
  disabled: boolean;
  firstView: boolean;
  toggleStatus: boolean;
  searchMode: number;
  onClickFunc: any;
  deleteFunc: any;
}> = ({
  styles,
  disabled,
  firstView,
  toggleStatus,
  searchMode,
  onClickFunc,
  deleteFunc,
}) => {
  const [open, setOpen] = React.useState(false);
  // 検索文字列
  const [searchWord, setSearchWord] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // TODO ユーザ権限実装時に使用
  // const user = useSelector(selectUser);
  const user = { doCode: ["00105", "00131", "00251"] };
  const selectDoList = useSelector(selectDoInfoList);

  const MAX_SEARCH_LIST = 150;
  const doMaster = getAllDo().do_data;

  const [doList, setDoList] = React.useState([""]);
  // 検索文字列が更新された際に動く
  useEffect(() => {
    // TODO ユーザ権限実装時に使用
    // ボタンのリストを更新
    // if (getAuthTenpoCheck(user.jobCategory).search < onlyAll) {
    //   setDoList(userDoButton());
    // }
    // else {
    //   setDoList(allDoButton());
    // }

    setDoList(allDoButton());
  }, [searchWord]);

  function userDoButton() {
    let doList: any[] = [""];
    let doCode: any = "";
    let doName: any = "";
    let hitCount: number = 0;

    for (let i = 0; i < user.doCode.length; i++) {
      doCode = user.doCode[i];
      if (doCode === "") return doList;
      doName = doMaster[user.doCode[i]].do_name;
      // 検索文字列にマッチしたものだけリストに入れる
      if (doCode.match(searchWord) || doName.match(searchWord)) {
        doList.push(
          <Button
            key={i}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => {
              onClickFunc(user.doCode[i], doMaster[user.doCode[i]].do_name);
              setOpen(false);
            }}
          >
            <SelectButtonDiv props={styles}>
              {user.doCode[i]}：{doMaster[user.doCode[i]].do_name}
            </SelectButtonDiv>
          </Button>,
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    return doList;
  }

  function allDoButton() {
    let doList: any[] = [""];
    let hitCount: number = 0;
    for (let key in doMaster) {
      if (key === "undefined" || searchWord === "") {
        doList.push(
          <Button
            key={key}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => {
              onClickFunc(doMaster[key].do_code, doMaster[key].do_name);
              setOpen(false);
            }}
          >
            <SelectButtonDiv props={styles}>
              {doMaster[key].do_code}：{doMaster[key].do_name}
            </SelectButtonDiv>
          </Button>,
        );
      }
      // 検索文字列にマッチしたものだけリストに入れる
      else if (
        doMaster[key].do_code.match(searchWord) ||
        doMaster[key].do_name.match(searchWord)
      ) {
        doList.push(
          <Button
            key={key}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => {
              onClickFunc(doMaster[key].do_code, doMaster[key].do_name);
              setOpen(false);
            }}
          >
            <SelectButtonDiv props={styles}>
              {doMaster[key].do_code}：{doMaster[key].do_name}
            </SelectButtonDiv>
          </Button>,
        );
        hitCount++;
      }
      if (hitCount > MAX_SEARCH_LIST) break;
    }
    return doList;
  }

  return (
    <>
      <SearchButton
        onClick={() => {
          handleOpen();
          onClickFunc("", "");
        }}
        disabled={disabled}
        style={{ WebkitAppearance: "none" }}
        props={styles}
      >
        <SelectButtonDiv props={styles}>{"ＤＯ名"}</SelectButtonDiv>
      </SearchButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <PaperDiv props={styles}>
          <TextField
            sx={styles.searchTextField}
            value={searchWord}
            label="ＤＯコード、ＤＯ名で探す"
            variant="outlined"
            size="medium"
            fullWidth={true}
            disabled={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length <= 100) {
                setSearchWord(e.target.value);
              }
            }}
          />
          <ListPaperDiv props={styles}>{doList}</ListPaperDiv>
        </PaperDiv>
      </Modal>
      {/* <List dense={dense}> */}
      <List sx={styles.selectList}>
        {selectDoList.map((value: any, index: number) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  deleteFunc(value.doCode, value.doName);
                }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText primary={value.doName} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default SearchDO;
