/* ---------------------------------------------------------------------------------- */
import React, { useState, useEffect } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import {
  Grid,
  Card,
  CardContent,
  Button,
  IconButton,
  TextField,
  Modal,
  Box,
  Stack,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import HelpIcon from "@mui/icons-material/Help";

import Header from "../common/Header";
import { RequestCheckHelp } from "./RequestCheckHelp";

import { useDispatch, useSelector } from "react-redux";
import {
  inputCheckReqestItemInfoList,
  addCheckListItemInfo,
  checkListItem,
} from "../organisms/search/SummarySearchSlice";

/* ---------------------------------------------------------------------------------- */
const setVhVariable = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
window.addEventListener('resize', setVhVariable);
window.addEventListener('load', setVhVariable);


const titleArea = css`
  height: calc(var(--vh, 1vh) * 5);
  width: 95vw;
`;

const title = css`
  margin: 5px;
  color: #000;
  font-size: 20px;
  font-weight: bold;
`;

const contentsAreaAll = css`
  height: calc(var(--vh, 1vh) * 78);
  width: 90vw;
  overflow: hidden;
`;

const contentsAreaButton = css`
  height: calc(var(--vh, 1vh) * 8);
  width: 90vw;
`;

const content = css`
  margin: 10px 10px;
  color: #f00;
  font-size: 14px;
`;

const center = css`
  text-align: center;
`;

const selectItem = css`
  margin: 3px 5px 8px 20px;
  min-width: 450px;
  max-width: 85%;
`;

const textItem = css`
  margin: 3px 5px 8px 20px;
  font-size: 14px;
`;

const text = css`
  color: red;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const menuItem = css`
  font-size: 14px;
`;

/* ---------------------------------------------------------------------------------- */
const RequestCheck: React.FC<{}> = () => {
  const windowName = "現金仕入システム";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  //前画面からの入力情報の取得
  //申請前確認のデータ取得
  const checkRequestItem: any = useSelector(inputCheckReqestItemInfoList);
  const checkItemInfo: any = useSelector(checkListItem);

  const item = {
    check1: checkItemInfo.check1,
    check2: checkItemInfo.check2,
    check3: checkItemInfo.check3,
    check4: checkItemInfo.check4,
    check5: checkItemInfo.check5,
    check6: checkItemInfo.check6,
    check7: checkItemInfo.check7,
    check8: checkItemInfo.check8,
    check9: checkItemInfo.check9,
  };

  // 選択値の保持
  const [checkItemList, setCheckItemList] = React.useState(item);

  //デイリー品と輸入品ではなかったときの処理
  useEffect(() => {
    // 連携データ存在チェック（直リンク及びリロード判定）
    if (
      checkRequestItem.dailyItem === "" &&
      checkRequestItem.importItem === "" &&
      checkRequestItem.BPitem === ""
    ) {
      // 連携データがない＝直リンク及びリロード時は業務フロートップへ遷移する。
      navigate("/request");
      return;
    }
    if (checkRequestItem.dailyItem === "2") {
      setCheckItemList((prevState) => ({
        ...prevState,
        check8: "2",
      }));
    }

    if (checkRequestItem.importItem === "2") {
      setCheckItemList((prevState) => ({
        ...prevState,
        check9: "2",
      }));
    }
  }, [checkRequestItem]);

  //選択情報をStateに保存
  const saveImageList = () => {
    dispatch(
      addCheckListItemInfo({
        check1: checkItemList.check1,
        check2: checkItemList.check2,
        check3: checkItemList.check3,
        check4: checkItemList.check4,
        check5: checkItemList.check5,
        check6: checkItemList.check6,
        check7: checkItemList.check7,
        check8: checkItemList.check8,
        check9: checkItemList.check9,
      }),
    );
  };

  //入力項目の初期化
  const clearList = () => {
    dispatch(
      addCheckListItemInfo({
        check1: "",
        check2: "",
        check3: "",
        check4: "",
        check5: "",
        check6: "",
        check7: "",
        check8: "",
        check9: "",
      }),
    );
    const clearItem = {
      check1: "",
      check2: "",
      check3: "",
      check4: "",
      check5: "",
      check6: "",
      check7: "",
      check8: "",
      check9: "",
    };
    setCheckItemList(clearItem);
  };

  const [openHelpNumber, setOpenHelpNumber] = useState(1);

  // 登録ボタンの表示制御
  const isRegistButtonVisible: boolean =
    (checkItemList.check1 &&
      checkItemList.check2 &&
      checkItemList.check3 &&
      checkItemList.check4 &&
      checkItemList.check5 &&
      checkItemList.check6 &&
      checkItemList.check7) === "" ||
    (checkRequestItem.dailyItem === "1" && checkItemList.check8 !== "0") ||
    (checkRequestItem.importItem === "1" && checkItemList.check9 !== "0");

  //前画面の情報をもとに微生物検査のプルダウンの出し分け
  function checkDailyItem() {
    switch (checkRequestItem.dailyItem) {
      case "1": {
        return (
          <Grid container alignContent="center">
            <FormControl css={selectItem} size="small">
              <InputLabel
                id="demo-simple-select-autowidth-label"
                css={menuItem}
              >
                【デイリー品】微生物検査の実施状況
              </InputLabel>
              <Select
                name="checkItem_9"
                label="【デイリー品】微生物検査の実施状況"
                value={checkItemList.check8}
                onChange={(e: SelectChangeEvent) => {
                  setCheckItemList((prevState) => ({
                    ...prevState,
                    check8: e.target.value,
                  }));
                }}
                onMouseDown={() => {
                  setOpenHelpNumber(9);
                }}
                autoWidth
              >
                <MenuItem value={"0"}>
                  可：安全性の根拠（微生物検査結果）を持っており、継続的な監視結果について書面での説明ができる
                </MenuItem>
                <MenuItem value={"1"}>
                  否：安全性の根拠（微生物検査結果）がない
                </MenuItem>
              </Select>
            </FormControl>
            <IconButton
              style={{ color: "black", padding: "3px" }}
              onClick={() => {
                setOpenHelpNumber(9);
              }}
            >
              <HelpIcon fontSize="medium" />
            </IconButton>
          </Grid>
        );
      }

      case "2": {
        return (
          <Grid container alignItems="center">
            <Typography variant="h4" css={textItem}>
              【デイリー品】微生物検査の実施状況
            </Typography>
            <IconButton
              style={{ color: "black", padding: "3px" }}
              onClick={() => {
                setOpenHelpNumber(9);
              }}
            >
              <HelpIcon fontSize="medium" />
            </IconButton>

            <Typography variant="h4" css={content}>
              不要
            </Typography>
          </Grid>
        );
      }
      case "": {
        return <></>;
      }
    }
  }

  //前画面の情報をもとに輸入商品のプルダウンの出し分け
  function checkImportItem() {
    switch (checkRequestItem.importItem) {
      case "1": {
        return (
          <Grid container alignContent="center">
            <FormControl css={selectItem} size="small">
              <InputLabel
                id="demo-simple-select-autowidth-label"
                css={menuItem}
              >
                【輸入商品】輸入届がなされているか
              </InputLabel>
              <Select
                name="checkItem_8"
                label="【輸入商品】輸入届がなされているか"
                value={checkItemList.check9}
                onChange={(e: SelectChangeEvent) => {
                  setCheckItemList((prevState) => ({
                    ...prevState,
                    check9: e.target.value,
                  }));
                }}
                onMouseDown={() => {
                  setOpenHelpNumber(8);
                }}
                autoWidth
              >
                <MenuItem value={"0"}>可：輸入届出がなされている</MenuItem>
                <MenuItem value={"1"}>
                  否：輸入届出がなされていない。わからない
                </MenuItem>
              </Select>
            </FormControl>
            <IconButton
              style={{ color: "black", padding: "3px" }}
              onClick={() => {
                setOpenHelpNumber(8);
              }}
            >
              <HelpIcon fontSize="medium" />
            </IconButton>
          </Grid>
        );
      }

      case "2": {
        return (
          <Grid container alignItems="center">
            <Typography variant="h4" css={textItem}>
              【輸入商品】輸入届がなされているか
            </Typography>
            <IconButton
              style={{ color: "black", padding: "3px" }}
              onClick={() => {
                setOpenHelpNumber(8);
              }}
            >
              <HelpIcon fontSize="medium" />
            </IconButton>

            <Typography variant="h4" css={content}>
              不要
            </Typography>
          </Grid>
        );
      }
      case "": {
        return <></>;
      }
    }
  }

  return (
    <div>
      <Header windowName={windowName} />

      {/*全体-START*/}
      <Grid container alignItems="center" justifyContent="center">
        {/* タイトルエリア-START */}
        <Grid container css={titleArea}>
          <Grid item xs={12}>
            <Typography css={title}>現金仕入申請前チェックリスト</Typography>
          </Grid>
        </Grid>
        {/* タイトルエリア-END */}

        {/*入力エリアエリア-START */}
        <Grid container css={contentsAreaAll}>
          {/* 画面左：チェックエリア */}
          <Grid item xs={6} style={{ marginBottom: "10px" }}>
            <Typography variant="h6" css={content}>
              【全体リスクの評価】
            </Typography>

            <Grid container alignContent="center">
              <FormControl css={selectItem} size="small">
                <InputLabel
                  id="demo-simple-select-autowidth-label"
                  css={menuItem}
                  variant="outlined"
                >
                  ①店舗イメージを損なう商品ではない
                </InputLabel>
                <Select
                  name="checkItem_1"
                  label="①店舗イメージを損なう商品ではない"
                  value={checkItemList.check1}
                  onChange={(e: SelectChangeEvent) => {
                    setCheckItemList((prevState) => ({
                      ...prevState,
                      check1: e.target.value,
                    }));
                  }}
                  onMouseDown={() => {
                    setOpenHelpNumber(1);
                  }}
                  autoWidth
                >
                  <MenuItem value={"0"}>リスク低：問題ない</MenuItem>
                  <MenuItem value={"1"}>
                    リスク高：店舗イメージと乖離している
                  </MenuItem>
                </Select>
              </FormControl>
              <IconButton
                style={{ color: "black", padding: "3px" }}
                onClick={() => {
                  setOpenHelpNumber(1);
                }}
              >
                <HelpIcon fontSize="medium" />
              </IconButton>
            </Grid>

            <Grid container alignContent="center">
              <FormControl css={selectItem} size="small">
                <InputLabel
                  id="demo-simple-select-autowidth-label"
                  css={menuItem}
                >
                  ②密閉された容器で販売
                </InputLabel>
                <Select
                  name="checkItem_2"
                  label="②密閉された容器で販売"
                  value={checkItemList.check2}
                  onChange={(e: SelectChangeEvent) => {
                    setCheckItemList((prevState) => ({
                      ...prevState,
                      check2: e.target.value,
                    }));
                  }}
                  onMouseDown={() => {
                    setOpenHelpNumber(2);
                  }}
                  autoWidth
                >
                  <MenuItem value={"0"}>
                    リスク低：オリジナルのデイリー品同等以上
                  </MenuItem>
                  <MenuItem value={"1"}>
                    リスク高：オリジナルのデイリー品以下
                  </MenuItem>
                </Select>
              </FormControl>
              <IconButton
                style={{ color: "black", padding: "3px" }}
                onClick={() => {
                  setOpenHelpNumber(2);
                }}
              >
                <HelpIcon fontSize="medium" />
              </IconButton>
            </Grid>

            <Grid container alignContent="center">
              <FormControl css={selectItem} size="small">
                <InputLabel
                  id="demo-simple-select-autowidth-label"
                  css={menuItem}
                >
                  ③【食品表示法】必要な食品表示、栄養成分表示がある
                </InputLabel>
                <Select
                  name="checkItem_3"
                  label="③【食品表示法】必要な食品表示、栄養成分表示がある"
                  value={checkItemList.check3}
                  onChange={(e: SelectChangeEvent) => {
                    setCheckItemList((prevState) => ({
                      ...prevState,
                      check3: e.target.value,
                    }));
                  }}
                  onMouseDown={() => {
                    setOpenHelpNumber(31);
                  }}
                  autoWidth
                >
                  <MenuItem value={"0"}>○：必要な項目がある</MenuItem>
                  <MenuItem value={"1"}>×：必要な項目がない</MenuItem>
                </Select>
              </FormControl>
              <IconButton
                style={{ color: "black", padding: "3px" }}
                onClick={() => {
                  setOpenHelpNumber(31);
                }}
              >
                <HelpIcon fontSize="medium" />
              </IconButton>
              <IconButton
                style={{ color: "black", padding: "3px" }}
                onClick={() => {
                  setOpenHelpNumber(32);
                }}
              >
                <HelpIcon fontSize="medium" />
              </IconButton>
            </Grid>

            <Grid container alignContent="center">
              <FormControl css={selectItem} size="small">
                <InputLabel
                  id="demo-simple-select-autowidth-label"
                  css={menuItem}
                >
                  ④ 【食品衛生法】既存の店内什器で温度管理可能
                </InputLabel>
                <Select
                  name="checkItem_4"
                  label="④ 【食品衛生法】既存の店内什器で温度管理可能"
                  value={checkItemList.check4}
                  onChange={(e: SelectChangeEvent) => {
                    setCheckItemList((prevState) => ({
                      ...prevState,
                      check4: e.target.value,
                    }));
                  }}
                  onMouseDown={() => {
                    setOpenHelpNumber(4);
                  }}
                  autoWidth
                >
                  <MenuItem value={"0"}>○：温度管理可能</MenuItem>
                  <MenuItem value={"1"}>×：温度管理が難しい</MenuItem>
                </Select>
              </FormControl>
              <IconButton
                style={{ color: "black", padding: "3px" }}
                onClick={() => {
                  setOpenHelpNumber(4);
                }}
              >
                <HelpIcon fontSize="medium" />
              </IconButton>
            </Grid>

            <Grid container alignContent="center">
              <FormControl css={selectItem} size="small">
                <InputLabel
                  id="demo-simple-select-autowidth-label"
                  css={menuItem}
                >
                  ⑤【食品衛生法】HACCP対応 HACCP対応の確認
                </InputLabel>
                <Select
                  name="checkItem_5"
                  label="⑤【食品衛生法】HACCP対応 HACCP対応の確認"
                  value={checkItemList.check5}
                  onChange={(e: SelectChangeEvent) => {
                    setCheckItemList((prevState) => ({
                      ...prevState,
                      check5: e.target.value,
                    }));
                  }}
                  onMouseDown={() => {
                    setOpenHelpNumber(5);
                  }}
                  autoWidth
                >
                  <MenuItem value={"0"} style={{ whiteSpace: "pre-wrap" }}>
                    ○：小規模事業者でHACCPの考えを取り入れた衛生管理を実施している、大規模事業者でHACCPに基づく衛生管理を実施している
                  </MenuItem>
                  <MenuItem value={"1"}>
                    ×：HAACPに沿った衛生管理を実施していない
                  </MenuItem>
                </Select>
              </FormControl>
              <IconButton
                style={{ color: "black", padding: "3px" }}
                onClick={() => {
                  setOpenHelpNumber(5);
                }}
              >
                <HelpIcon fontSize="medium" />
              </IconButton>
            </Grid>

            <Grid container alignContent="center">
              <FormControl css={selectItem} size="small">
                <InputLabel
                  id="demo-simple-select-autowidth-label"
                  css={menuItem}
                >
                  ⑥【薬機法】効果効能を標榜していない
                </InputLabel>
                <Select
                  name="checkItem_6"
                  label="⑥【薬機法】効果効能を標榜していない"
                  value={checkItemList.check6}
                  onChange={(e: SelectChangeEvent) => {
                    setCheckItemList((prevState) => ({
                      ...prevState,
                      check6: e.target.value,
                    }));
                  }}
                  onMouseDown={() => {
                    setOpenHelpNumber(6);
                  }}
                  autoWidth
                >
                  <MenuItem value={"0"}>
                    ○：健康増進、心身への効果、腸活などの効果機能を標榜していない
                  </MenuItem>
                  <MenuItem value={"1"}>×：効果機能を標榜している</MenuItem>
                </Select>
              </FormControl>
              <IconButton
                style={{ color: "black", padding: "3px" }}
                onClick={() => {
                  setOpenHelpNumber(6);
                }}
              >
                <HelpIcon fontSize="medium" />
              </IconButton>
            </Grid>

            <Grid container alignContent="center">
              <FormControl css={selectItem} size="small">
                <InputLabel
                  id="demo-simple-select-autowidth-label"
                  css={menuItem}
                >
                  ⑦【不当景品類及び不当表示防止法】特色表示による優良誤認
                </InputLabel>
                <Select
                  name="checkItem_7"
                  label="⑦【不当景品類及び不当表示防止法】特色表示による優良誤認"
                  value={checkItemList.check7}
                  onChange={(e: SelectChangeEvent) => {
                    setCheckItemList((prevState) => ({
                      ...prevState,
                      check7: e.target.value,
                    }));
                  }}
                  onMouseDown={() => {
                    setOpenHelpNumber(7);
                  }}
                  autoWidth
                >
                  <MenuItem value={"0"}>
                    ○：「●●産牛乳を使用」などを表示していない、もしくは産地証明など根拠が明確
                  </MenuItem>
                  <MenuItem value={"1"}>
                    ×：「●●産牛乳を使用」などを表示しており、明確な根拠がない
                  </MenuItem>
                </Select>
              </FormControl>
              <IconButton
                style={{ color: "black", padding: "3px" }}
                onClick={() => {
                  setOpenHelpNumber(7);
                }}
              >
                <HelpIcon fontSize="medium" />
              </IconButton>
            </Grid>

            <Typography variant="h6" css={content}>
              【個別の可否項目】
            </Typography>
            {checkDailyItem()}
            {checkImportItem()}
          </Grid>

          {/* 画面右：画像エリア */}
          <Grid xs={6} container>
            <Grid container xs={12} css={center} justifyContent="center">
              <RequestCheckHelp openModalNumber={openHelpNumber} />
            </Grid>
          </Grid>
        </Grid>
        {/* 入力エリア-END */}

        {/* ボタンエリア-START */}
        <Grid container css={contentsAreaButton}>
          <Grid container xs={12}>
            <Grid item xs={2}>
              <Button
                variant="contained"
                style={{ width: "90%", margin: "2px" }}
                onClick={() => {
                  navigate("/request");
                }}
              >
                戻る
              </Button>
            </Grid>

            <Grid
              container
              xs={6}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Typography variant="caption" align="right" css={text}>
                全ての確認に問題が無ければデータの登録ボタンが表示されます→
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Button
                disabled={isRegistButtonVisible}
                variant="contained"
                style={{ width: "90%", margin: "2px" }}
                onClick={() => {
                  navigate("/requestNotice");
                  saveImageList();
                }}
              >
                登録チェック
              </Button>
            </Grid>

            <Grid item xs={2}>
              <Button
                variant="contained"
                style={{ width: "90%", margin: "2px" }}
                onClick={() => {
                  clearList();
                }}
              >
                クリア
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* ボタンエリア-END */}
      </Grid>
      {/*全体-END*/}
    </div>
  );
};
export default RequestCheck;
