// import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import { Grid, Card, CardContent } from "@mui/material";

const grid = css`
  margin-top: 64px;
`;
const card = css`
  padding: 8px;
`;
const style = css`
  text-align: center;
  font-size: 24px;
`;

const header = css`
  ${style}
  padding: 4px;
  background-color: #e60012;
  border: 1px #333333;
  color: #fff;
`;

const p = css`
  ${style}
  margin: 32px 10px;
`;

const ErrorDisplay: React.FC<{}> = ({}) => {
  return (
    <>
      <Grid container css={grid}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardContent css={card}>
              <div css={header}>無効なアクセスです</div>
              <div css={p}>
                指定された店舗、またはこの画面の利用権限がありません
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </>
  );
};
export default ErrorDisplay;
