import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { DateTime } from "luxon";

export const SUMMARY_DATE_FORMAT = "yyyy/MM/dd HH:mm:ss";
export interface SUMMARY_CONDITION {
  requestItemInfoList: {
    inputReqestInfoList: {
      dailyItem: string;
      importItem: string;
      BPitem: string;
    };
    checkListInfo: {
      check1: string;
      check2: string;
      check3: string;
      check4: string;
      check5: string;
      check6: string;
      check7: string;
      check8: string;
      check9: string;
    };
    tenpoInfoList: { tenpoCode: string; tenpoName: string; tenpoTel: string }[];
    zoInfoList: { zoCode: string; zoName: string }[];
    doInfoList: { doCode: string; doName: string }[];
    itemInfoList: {
      id: string;
      telNo: string;
      itemName: string;
      makerName: string;
      recycle: string;
      costPrice: string;
      sellPrice: string;
      limitDay: string;
      category: string;
      image1Url: string;
      image2Url: string;
      image3Url: string;
      image4Url: string;
      image5Url: string;
      confirmStep: string;
    };
    checkItem: [];
    searchData: {
      id: string;
      employeeNo: string;
      productName: string;
      makerName: string;
      statusStep: string;
      statusCode: string;
      statusCheckComplete: string;
      statusCheckDelete: string;
      category: string;
      createdDateFrom: string | null;
      createdDateTo: string | null;
      tenpoCode: [];
      doCode: [];
      zoCode: [];
      confirmStep: string;
      count: number;
    };
  };
}

const initialSummaryCondition: SUMMARY_CONDITION = {
  requestItemInfoList: {
    inputReqestInfoList: {
      dailyItem: "",
      importItem: "",
      BPitem: "",
    },
    checkListInfo: {
      check1: "",
      check2: "",
      check3: "",
      check4: "",
      check5: "",
      check6: "",
      check7: "",
      check8: "",
      check9: "",
    },
    tenpoInfoList: [],
    zoInfoList: [],
    doInfoList: [],
    itemInfoList: {
      id: "",
      telNo: "",
      itemName: "",
      makerName: "",
      recycle: "",
      costPrice: "",
      sellPrice: "",
      limitDay: "",
      category: "",
      image1Url: "",
      image2Url: "",
      image3Url: "",
      image4Url: "",
      image5Url: "",
      confirmStep: "1",
    },
    checkItem: [],
    searchData: {
      id: "",
      employeeNo: "",
      productName: "",
      makerName: "",
      statusStep: "",
      statusCode: "",
      statusCheckComplete: "0",
      statusCheckDelete: "0",
      category: "",
      createdDateFrom: null,
      createdDateTo: null,
      tenpoCode: [],
      doCode: [],
      zoCode: [],
      confirmStep: "",
      count: 0,
    },
  },
};

export const summarySlice = createSlice({
  name: "summaryCondition",
  initialState: {
    summaryCondition: initialSummaryCondition,
  },
  reducers: {
    updateRequestItemInfoList: (state, action) => {
      state.summaryCondition.requestItemInfoList = action.payload;
    },
    resetInputCheckReqestInfo: (state) => {
      state.summaryCondition.requestItemInfoList = {
        inputReqestInfoList: {
          dailyItem: "",
          importItem: "",
          BPitem: "",
        },
        checkListInfo: {
          check1: "",
          check2: "",
          check3: "",
          check4: "",
          check5: "",
          check6: "",
          check7: "",
          check8: "",
          check9: "",
        },
        tenpoInfoList: [],
        zoInfoList: [],
        doInfoList: [],
        itemInfoList: {
          id: "",
          telNo: "",
          itemName: "",
          makerName: "",
          recycle: "",
          costPrice: "",
          sellPrice: "",
          limitDay: "",
          category: "",
          image1Url: "",
          image2Url: "",
          image3Url: "",
          image4Url: "",
          image5Url: "",
          confirmStep: "1",
        },
        checkItem: [],
        searchData: {
          id: "",
          employeeNo: "",
          productName: "",
          makerName: "",
          statusStep: "",
          statusCode: "",
          statusCheckComplete: "0",
          statusCheckDelete: "0",
          category: "",
          createdDateFrom: null,
          createdDateTo: null,
          tenpoCode: [],
          doCode: [],
          zoCode: [],
          confirmStep: "",
          count: 0,
        },
      };
    },
    inputCheckReqestItemInfo: (state, action) => {
      state.summaryCondition.requestItemInfoList.inputReqestInfoList = {
        ...state.summaryCondition.requestItemInfoList.inputReqestInfoList,
        ...action.payload,
      };
    },
    addCheckListItemInfo: (state, action) => {
      state.summaryCondition.requestItemInfoList.checkListInfo = {
        ...state.summaryCondition.requestItemInfoList.checkListInfo,
        ...action.payload,
      };
    },

    addTenpoInfo: (state, action) => {
      state.summaryCondition.requestItemInfoList.tenpoInfoList.push(
        action.payload.tenpoInfo,
      );
      state.summaryCondition.requestItemInfoList.tenpoInfoList =
        state.summaryCondition.requestItemInfoList.tenpoInfoList.filter(
          (element, index, self) =>
            self.findIndex((e) => e.tenpoCode === element.tenpoCode) === index,
        );
    },
    deleteTenpoInfo: (state, action) => {
      state.summaryCondition.requestItemInfoList.tenpoInfoList =
        state.summaryCondition.requestItemInfoList.tenpoInfoList.filter(
          (info) => info.tenpoCode !== action.payload.tenpoInfo.tenpoCode,
        );
    },
    addZoInfo: (state, action) => {
      state.summaryCondition.requestItemInfoList.zoInfoList.push(
        action.payload.zoInfo,
      );
      state.summaryCondition.requestItemInfoList.zoInfoList =
        state.summaryCondition.requestItemInfoList.zoInfoList.filter(
          (element, index, self) =>
            self.findIndex((e) => e.zoCode === element.zoCode) === index,
        );
    },
    deleteZoInfo: (state, action) => {
      state.summaryCondition.requestItemInfoList.zoInfoList =
        state.summaryCondition.requestItemInfoList.zoInfoList.filter(
          (info) => info.zoCode !== action.payload.zoInfo.zoCode,
        );
    },
    addDoInfo: (state, action) => {
      state.summaryCondition.requestItemInfoList.doInfoList.push(
        action.payload.doInfo,
      );
      state.summaryCondition.requestItemInfoList.doInfoList =
        state.summaryCondition.requestItemInfoList.doInfoList.filter(
          (element, index, self) =>
            self.findIndex((e) => e.doCode === element.doCode) === index,
        );
    },
    deleteDoInfo: (state, action) => {
      state.summaryCondition.requestItemInfoList.doInfoList =
        state.summaryCondition.requestItemInfoList.doInfoList.filter(
          (info) => info.doCode !== action.payload.doInfo.doCode,
        );
    },
    inputNewItemInfo: (state, action) => {
      state.summaryCondition.requestItemInfoList.itemInfoList = {
        ...state.summaryCondition.requestItemInfoList.itemInfoList,
        ...action.payload,
      };
    },

    nocticeCheckItem: (state, action) => {
      state.summaryCondition.requestItemInfoList.checkItem =
        action.payload.checkItem;
    },
    inputSearchData: (state, action) => {
      state.summaryCondition.requestItemInfoList.searchData = {
        ...state.summaryCondition.requestItemInfoList.searchData,
        ...action.payload,
      };
    },
  },
});

//商品情報をすべて保持しているState
export const requestItemInfoList = (state: RootState) =>
  state.summaryCondition.summaryCondition.requestItemInfoList;
//申請する商品の内容を保持しているState
export const inputCheckReqestItemInfoList = (state: RootState) =>
  state.summaryCondition.summaryCondition.requestItemInfoList
    .inputReqestInfoList;
export const checkListItem = (state: RootState) =>
  state.summaryCondition.summaryCondition.requestItemInfoList.checkListInfo;
//店舗情報のみ保持しているstate
export const selectTenpoInfoList = (state: RootState) =>
  state.summaryCondition.summaryCondition.requestItemInfoList.tenpoInfoList;
//入力された商品情報を保持しているState
export const selectItemInfoList = (state: RootState) =>
  state.summaryCondition.summaryCondition.requestItemInfoList.itemInfoList;
export const selectZoInfoList = (state: RootState) =>
  state.summaryCondition.summaryCondition.requestItemInfoList.zoInfoList;
export const selectDoInfoList = (state: RootState) =>
  state.summaryCondition.summaryCondition.requestItemInfoList.doInfoList;
export const noticeCheck = (state: RootState) =>
  state.summaryCondition.summaryCondition.requestItemInfoList.checkItem;
export const searchItemData = (state: RootState) =>
  state.summaryCondition.summaryCondition.requestItemInfoList.searchData;
export default summarySlice.reducer;
export const {
  resetInputCheckReqestInfo,
  updateRequestItemInfoList,
  addTenpoInfo,
  deleteTenpoInfo,
  addZoInfo,
  deleteZoInfo,
  addDoInfo,
  deleteDoInfo,
  inputNewItemInfo,
  inputCheckReqestItemInfo,
  addCheckListItemInfo,
  nocticeCheckItem,
  inputSearchData,
} = summarySlice.actions;
