//=============================================================================
//  環境変数コード定義
//=============================================================================
const MODE = process.env.REACT_APP_MODE!;
const LOCAL_DEVELOP = "develop";
const CLOUD_DEVELOP = "cloud-dev";
const CLOUD_TEST = "cloud-test";
const CLOUD_PROD = "cloud-prod";
export const IS_AUTH_DISPLAY: boolean = [
  LOCAL_DEVELOP,
  CLOUD_DEVELOP,
  CLOUD_TEST,
].includes(MODE);

//=============================================================================
//  部署・職種コード定義
//=============================================================================
export const JOB_CATEGORY_CODE_OFC = "310";
export const JOB_CATEGORY_CODE_DM = "210";
export const JOB_CATEGORY_CODE_ZM = "120";
export const JOB_CATEGORY_CODE_ZM_JOHO = "316";
const JOB_CATEGORY_CODE_GM = "101";

/** システム企画 */
const SYSTEM_KIKAKU_DEPT_CODE = "955500";

/** OP企画 */
const OP_KIKAKU_DEPT_CODE = "900A50";
/** OP支援 */
const OP_SHIEN_DEPT_CODE = "900A20";
/** OP情報 */
const OP_JOHO_DEPT_CODE = "900A60";

/** TR権限 */
const TR_DEPT_CODES = ["954210", "954220", "954230", "954240", "954250"];
/** 総合渉外 */
const SOGO_SHOGAI_DEPT_CODES = [
  "957400",
  "957401",
  "957404",
  "957407",
  "957410",
  "957412",
  "957413",
  "957416",
  "957420",
  "957422",
  "957423",
  "957424",
  "957427",
  "957429",
  "957430",
  "957435",
  "957436",
  "957437",
  "957438",
  "957440",
  "957441",
];

/** 教育推進*/
const KYOIKU_SUISHIN_DEPT_CODE = "954202";

/** 沖縄_社長 */
const OKINAWA_PRESIDENT_DEPT_CODE = "966090";

/** 役員以上の職種コード */
const OFFICER_JOB_CODE_ARRAY = [
  "012",
  "013",
  "014",
  "015",
  "016",
  "017",
  "018",
  "019",
  "050",
  "090",
  "110",
];

//=============================================================================
//  権限
//=============================================================================
//  管理職判定
export const isMagager = (jobCategory: string) => {
  return jobCategory === "ops" || jobCategory === "gm" || jobCategory === "zm";
};

/** 教育推進TRチェック */
export const isTRDept = (departmentCodeOA: string) => {
  if (TR_DEPT_CODES.includes(departmentCodeOA)) {
    return true;
  }
  return false;
};

/**
 * ローカル環境判定。
 */
export const isLocal = () => {
  return window.location.origin.indexOf("localhost") !== -1;
};

/**
 * ZMチェック
 * @param user
 * @returns
 */
export const isZmCheck = (user: any) => {
  return isZmCheckByElement(user.jobCategoryCodeOA, user.departmentCodeOA);
};

/**
 * ZMチェック(要素)
 * @param jobCategoryCodeOA
 * @param departmentCodeOA
 * @returns
 */
export const isZmCheckByElement = (
  jobCategoryCodeOA: string,
  departmentCodeOA: string,
) => {
  const zmJobCode = [JOB_CATEGORY_CODE_ZM, JOB_CATEGORY_CODE_ZM_JOHO];
  const gmDepartmentCode = [OKINAWA_PRESIDENT_DEPT_CODE];
  if (
    zmJobCode.includes(jobCategoryCodeOA) ||
    SOGO_SHOGAI_DEPT_CODES.includes(departmentCodeOA) ||
    ([JOB_CATEGORY_CODE_GM].includes(jobCategoryCodeOA) &&
      gmDepartmentCode.includes(departmentCodeOA))
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * DMチェック
 * @param user
 * @returns
 */
export const isDmCheck = (user: any) => {
  return isDmCheckByElement(user.jobCategoryCodeOA);
};

/**
 * DMチェック(要素)
 * @param jobCategoryCodeOA
 * @returns
 */
export const isDmCheckByElement = (jobCategoryCodeOA: string) => {
  const dmJobCode = [JOB_CATEGORY_CODE_DM];
  if (dmJobCode.includes(jobCategoryCodeOA)) {
    return true;
  } else {
    return false;
  }
};

/**
 * OFCチェック
 * @param user
 * @returns
 */
export const isOfcUserCheck = (user: any) => {
  const ofcJobCode = [JOB_CATEGORY_CODE_OFC];
  if (ofcJobCode.includes(user.jobCategoryCodeOA)) {
    return true;
  } else {
    return false;
  }
};

/**
/* OFC・SOFCチェック
 * 
 * @param user 
 * @param tenpoCode 
 * @returns 
 */
export const isOfcCheck = (user: any, tenpoCode: any) => {
  let tenpoCodeSpecialList: any[] = user.tenpoCodeSpecialList;
  if (!tenpoCodeSpecialList) {
    tenpoCodeSpecialList = [];
  }

  if (
    isOfcUserCheck(user) ||
    (isDmCheck(user) && tenpoCodeSpecialList.includes(tenpoCode))
  ) {
    return true;
  } else {
    return false;
  }
};
