/* ---------------------------------------------------------------------------------- */
import React, { useState, useEffect, useCallback } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";

import { Grid, Button, Checkbox, Typography } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import Header from "../common/Header";

import { useSelector, useDispatch } from "react-redux";
import {
  inputCheckReqestItemInfoList,
  nocticeCheckItem,
  noticeCheck,
} from "../organisms/search/SummarySearchSlice";

/* ---------------------------------------------------------------------------------- */

const setVhVariable = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
window.addEventListener('resize', setVhVariable);
window.addEventListener('load', setVhVariable);

const titleArea = css`
  height: calc(var(--vh, 1vh) * 1); 
  width: 95vw;
`;

const contentsAreaAll = css`
  height: calc(var(--vh, 1vh) * 84); 
  width: 85vw;
  overflow: hidden;
`;

const contentsAreaButton = css`
  height: calc(var(--vh, 1vh) * 3); 
  width: 95vw;
`;

const center = css`
  text-align: center;
`;

const image = css`
  width: 70%;
  height: auto;
`;

const text = css`
  color: red;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

/* ---------------------------------------------------------------------------------- */
const RequestNotice: React.FC<{}> = () => {
  const windowName = "現金仕入システム";

  const navigate = useNavigate();

  const checkItem = useSelector(noticeCheck);
  const [checkedValues, setCheckedValues] = useState<any[]>(checkItem);

  const handleChangeCheck = (e: any) => {
    if (checkedValues.includes(e.target.value)) {
      setCheckedValues(
        checkedValues.filter((checkedValue) => checkedValue !== e.target.value),
      );
    } else {
      setCheckedValues([...checkedValues, e.target.value]);
    }
  };

  const dispatch = useDispatch();
  // 登録情報をStateに保存
  const saveItemList = () => {
    dispatch(
      nocticeCheckItem({
        checkItem: checkedValues,
      }),
    );
  };

  const checkRequestItem: any = useSelector(inputCheckReqestItemInfoList);

  useEffect(() => {
    if (
      checkRequestItem.dailyItem === "" &&
      checkRequestItem.importItem === "" &&
      checkRequestItem.BPitem === ""
    ) {
      // 連携データがない＝直リンク及びリロード時は業務フロートップへ遷移する。
      navigate("/request");
      return;
    }
  }, []);

  /* ---------------------------------------------------------------------------------- */
  const refs = Array.from({ length: 1 }, () =>
    React.createRef<HTMLDialogElement>(),
  );

  // ダイアログを開く処理
  const handleOpenDialog = useCallback(
    (index: number) => {
      refs[index]?.current?.showModal();
    },
    [refs],
  );

  // ダイアログを閉じる処理
  const handleCloseDialog = useCallback(
    (index: number) => {
      refs[index]?.current?.close();
    },
    [refs],
  );
  // ダイアログ内のクリックイベントハンドラーに渡す処理
  const handleClickInDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
    },
    [],
  );

  return (
    <>
      <Header windowName={windowName} />

      <Grid container alignItems="center" justifyContent="center">
        {/*全体-START*/}
        <Grid container alignItems="center" justifyContent="center">
          {/* タイトルエリア-START */}
          <Grid container css={titleArea}>
            {/* <Grid item xs={12}>
              <Typography variant="h4" css={title}>
                現金仕入フォーム
              </Typography>
            </Grid> */}
          </Grid>
          {/* タイトルエリア-END */}

          {/*  入力エリア-START */}
          <Grid container css={contentsAreaAll}>
            <Grid item xs={12}>
              <Grid css={center}>
                {/* <img
                  src={`${window.location.origin}/image/RequestNotice1.jpg`}
                  alt="説明"
                  css={image}
                /> */}
                <div
                  role="button"
                  onClick={() => handleOpenDialog(0)}
                  onKeyDown={() => handleOpenDialog(0)}
                  className="image-box"
                  tabIndex={0}
                >
                  <img
                    src={`${window.location.origin}/image/RequestNotice1.jpg`}
                    alt="説明"
                    css={image}
                    style={{ height: "50%", width: "50%" }}
                  />
                </div>
                <dialog
                  ref={refs[0]}
                  className="image-dialog"
                  onClick={() => handleCloseDialog(0)}
                  style={{ width: "80%", margin: "auto" }}
                >
                  <div onClick={handleClickInDialog} className="contents">
                    <img
                      src={`${window.location.origin}/image/RequestNotice1.jpg`}
                      alt=""
                      width="100%"
                    />
                  </div>
                </dialog>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="1"
                      onChange={handleChangeCheck}
                      checked={checkedValues.includes("1")}
                      //size="small"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: "1.5rem" } }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: "1.5rem" }}>
                      ①商品現物の表示画像を貼付 ※HPの画像は不可
                    </Typography>
                  }
                  sx={{ mb: 1 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="2"
                      onChange={handleChangeCheck}
                      checked={checkedValues.includes("2")}
                      // size="small"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: "1.5rem" } }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: "1.5rem" }}>
                      ②文字が確実に読める解像度で貼付
                    </Typography>
                  }
                  sx={{ mb: 1 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="3"
                      onChange={handleChangeCheck}
                      checked={checkedValues.includes("3")}
                      //size="small"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: "1.5rem" } }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: "1.5rem" }}>
                      ③賞味期限は、実際に印字されたものと印字箇所がわかる画像にする
                    </Typography>
                  }
                  sx={{ mb: 1 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="4"
                      onChange={handleChangeCheck}
                      checked={checkedValues.includes("4")}
                      //size="small"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: "1.5rem" } }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: "1.5rem" }}>
                      ④瓶や缶など丸い容器形状のものは分割して複数枚を貼付けする
                    </Typography>
                  }
                  sx={{ mb: 1 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="5"
                      onChange={handleChangeCheck}
                      checked={checkedValues.includes("5")}
                      //size="small"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: "1.5rem" } }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: "1.5rem" }}>
                      ⑤酒類の必要表示「未成年飲酒禁止」を確認し画像を貼付けする
                    </Typography>
                  }
                  sx={{ mb: 1 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="6"
                      onChange={handleChangeCheck}
                      checked={checkedValues.includes("6")}
                      //size="small"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: "1.5rem" } }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: "1.5rem" }}>
                      ⑥リサイクルマークの有無を確認
                    </Typography>
                  }
                  sx={{ mb: 1 }}
                />
              </FormGroup>
            </Grid>
          </Grid>
          {/*  入力エリア-END */}

          {/* ボタンエリア-START */}
          <Grid container css={contentsAreaButton}>
            <Grid container xs={12}>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  style={{ width: "90%", margin: "2px" }}
                  onClick={() => {
                    navigate("/requestCheck");
                  }}
                >
                  戻る
                </Button>
              </Grid>

              <Grid item xs={2}>
              </Grid>

              <Grid
                container
                xs={6}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography variant="caption" align="right" css={text}>
                  全ての確認に問題が無ければデータの登録ボタンが表示されます→
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Button
                  disabled={checkedValues.length < 6}
                  variant="contained"
                  style={{ width: "90%", margin: "2px" }}
                  onClick={() => {
                    saveItemList();
                    navigate("/requestInput");
                  }}
                >
                  登録チェック
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* ボタンエリア-END */}
        </Grid>
        {/*全体-END*/}
      </Grid>
    </>
  );
};
export default RequestNotice;
