/* ---------------------------------------------------------------------------------- */
import React, { useState, useCallback } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import Typography from "@mui/material/Typography";
import { Grid, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

/* ---------------------------------------------------------------------------------- */
const image = css`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

/* ---------------------------------------------------------------------------------- */

export const RequestSampleImage: React.FC<{
  openModalNumber: number; // どの項目のモーダルが開かれたか
}> = ({ openModalNumber }) => {
  let contentsElement: any;

  const [openDialogs, setOpenDialogs] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  // ダイアログを開く処理
  const handleOpenDialog = useCallback((index: number) => {
    setOpenDialogs((prev) => {
      const newOpenDialogs = [...prev];
      newOpenDialogs[index] = true;
      return newOpenDialogs;
    });
  }, []);

  // ダイアログを閉じる処理
  const handleCloseDialog = useCallback((index: number) => {
    setOpenDialogs((prev) => {
      const newOpenDialogs = [...prev];
      newOpenDialogs[index] = false;
      return newOpenDialogs;
    });
  }, []);

  // ヘルプデータの出し分け
  const sample = (openModalNumber: number) => {
    let contents: JSX.Element = <></>;

    switch (openModalNumber) {
      case 0:
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(0)}
              onKeyDown={() => handleOpenDialog(0)}
              className="image-box"
              tabIndex={0}
            >
              <img
                src={`${window.location.origin}/image/SampleInfo1.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <Dialog
              open={openDialogs[0]}
              onClose={() => handleCloseDialog(0)}
              maxWidth="xl"
              fullWidth={true}
              PaperProps={{
                sx: {
                  width: "80%",
                  maxHeight: "100%",
                },
              }}
            >
              <DialogContent>
                <img
                  src={`${window.location.origin}/image/SampleInfo1.jpg`}
                  alt=""
                  width="100%"
                />
                <IconButton
                  style={{ position: "absolute", top: 0, right: 0 }}
                  onClick={() => handleCloseDialog(0)}
                >
                  <CloseIcon fontSize="large" />
                </IconButton>
              </DialogContent>
            </Dialog>
          </>
        );
        break;
      case 1:
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(1)}
              onKeyDown={() => handleOpenDialog(1)}
              className="image-box"
              tabIndex={1}
            >
              <img
                src={`${window.location.origin}/image/SampleInfo2.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <Dialog
              open={openDialogs[1]}
              onClose={() => handleCloseDialog(1)}
              maxWidth="xl"
              fullWidth={true}
              PaperProps={{
                sx: {
                  width: "80%",
                  maxHeight: "100%",
                },
              }}
            >
              <DialogContent>
                <img
                  src={`${window.location.origin}/image/SampleInfo2.jpg`}
                  alt=""
                  width="90%"
                />
                <IconButton
                  style={{ position: "absolute", top: 0, right: 0 }}
                  onClick={() => handleCloseDialog(1)}
                >
                  <CloseIcon fontSize="large" />
                </IconButton>
              </DialogContent>
            </Dialog>
          </>
        );
        break;
      case 2:
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(2)}
              onKeyDown={() => handleOpenDialog(2)}
              className="image-box"
              tabIndex={2}
            >
              <img
                src={`${window.location.origin}/image/SampleInfo3.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <Dialog
              open={openDialogs[2]}
              onClose={() => handleCloseDialog(2)}
              maxWidth="xl"
              fullWidth={true}
              PaperProps={{
                sx: {
                  width: "80%",
                  maxHeight: "100%",
                },
              }}
            >
              <DialogContent>
                <img
                  src={`${window.location.origin}/image/SampleInfo3.jpg`}
                  alt=""
                  width="90%"
                />
                <IconButton
                  style={{ position: "absolute", top: 0, right: 0 }}
                  onClick={() => handleCloseDialog(2)}
                >
                  <CloseIcon fontSize="large" />
                </IconButton>
              </DialogContent>
            </Dialog>
          </>
        );
        break;
      case 3:
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(3)}
              onKeyDown={() => handleOpenDialog(3)}
              className="image-box"
              tabIndex={3}
            >
              <img
                src={`${window.location.origin}/image/SampleInfo4.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <Dialog
              open={openDialogs[3]}
              onClose={() => handleCloseDialog(3)}
              maxWidth="xl"
              fullWidth={true}
              PaperProps={{
                sx: {
                  width: "80%",
                  maxHeight: "100%",
                },
              }}
            >
              <DialogContent>
                <img
                  src={`${window.location.origin}/image/SampleInfo4.jpg`}
                  alt=""
                  width="90%"
                />
                <IconButton
                  style={{ position: "absolute", top: 0, right: 0 }}
                  onClick={() => handleCloseDialog(3)}
                >
                  <CloseIcon fontSize="large" />
                </IconButton>
              </DialogContent>
            </Dialog>
          </>
        );
        break;
      case 4:
        contents = (
          <>
            <div
              role="button"
              onClick={() => handleOpenDialog(4)}
              onKeyDown={() => handleOpenDialog(4)}
              className="image-box"
              tabIndex={4}
            >
              <img
                src={`${window.location.origin}/image/SampleInfo5.jpg`}
                alt="説明"
                css={image}
              />
            </div>
            <Dialog
              open={openDialogs[4]}
              onClose={() => handleCloseDialog(4)}
              maxWidth="xl"
              fullWidth={true}
              PaperProps={{
                sx: {
                  width: "80%",
                  maxHeight: "100%",
                },
              }}
            >
              <DialogContent>
                <img
                  src={`${window.location.origin}/image/SampleInfo5.jpg`}
                  alt=""
                  width="90%"
                />
                <IconButton
                  style={{ position: "absolute", top: 0, right: 0 }}
                  onClick={() => handleCloseDialog(4)}
                >
                  <CloseIcon fontSize="large" />
                </IconButton>
              </DialogContent>
            </Dialog>
          </>
        );
        break;
    }
    contentsElement = contents;
  };
  // ヘルプデータの出し分け処理呼び出し
  sample(openModalNumber);

  return (
    <>
      <Box>
        <Grid>{contentsElement}</Grid>
      </Box>
    </>
  );
};
export default RequestSampleImage;
