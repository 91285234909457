import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import summaryConditionsReducer from "../components/organisms/search/SummarySearchSlice";
import userReducer from "../features/userSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    summaryCondition: summaryConditionsReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = typeof store.dispatch;
