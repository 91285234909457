import React, { useState } from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import styled from "@emotion/styled";
import {
  IconButton,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Typography,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { auth } from "../../firebase/firebase";

const menuButton = css`
  // background-color: #d9d9d9;
  height: 50px;
  width: 50px;
  padding: 10px;
`;

const HamburgerMenu: React.FC<{}> = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const navigate = useNavigate();
  //ログイン時に取得されたユーザ情報
  const userInfo: any = useSelector(selectUser);
  //ユーザー情報の受け取り
  let logInUser = userInfo.userAuth;

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedItem(index);
    if (index === 0) {
      navigate("/requestDb");
    } else if (index === 1) {
      navigate("/request");
    }
  };

  const menuItems = [
    { name: "検索/確認", url: "/" },
    { name: "申請", url: "/" },
  ];

  const signOut = () => {
    auth
      .signOut()
      .then(() => {})
      .catch((error: any) => {
        console.log(`logout error (${error})`);
      });
  };

  const menuList = (
    <Box sx={{ width: 300 }} role="presentation">
      <Box sx={{ p: 1 }}>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="h6">現金仕入</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => setMenuOpen(false)} size={"small"}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <List>
        <ListItem key={menuItems[0].name} disablePadding>
          <ListItemButton
            sx={{ pl: 3 }}
            selected={selectedItem === 0}
            onClick={(event) => {
              handleListItemClick(event, 0);
              // window.open(menuItems[0].url + "&tenpocode=" + tenpoCode, "_blank");
            }}
          >
            <ListItemText primary={menuItems[0].name} />
          </ListItemButton>
        </ListItem>

        {logInUser === "0" && (
          <ListItem key={menuItems[1].name} disablePadding>
            <ListItemButton
              sx={{ pl: 3 }}
              selected={selectedItem === 1}
              onClick={(event) => {
                handleListItemClick(event, 1);
                // window.open(menuItems[1].url + "&tenpocode=" + tenpoCode, "_blank");
              }}
            >
              <ListItemText primary={menuItems[1].name} />
            </ListItemButton>
          </ListItem>
        )}
        {process.env.REACT_APP_MODE === "develop" ||
        process.env.REACT_APP_MODE === "cloud-test" ? (
          <>
            <ListItem
              button
              key="logout"
              onClick={() => {
                signOut();
                navigate("/login");
              }}
            >
              <ListItemText>
                <img
                  src={`${window.location.origin}/image/icons/logout_icon.png`}
                  alt=""
                  style={{ height: "20px", paddingRight: "10px" }}
                />
              </ListItemText>
              <ListItemText primary={"ログアウト"} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={userInfo.mailAddress}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={userInfo.uid}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={userInfo.jobCategory}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={JSON.stringify(userInfo.zoCode)}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={JSON.stringify(userInfo.doCode)}
                primaryTypographyProps={{
                  variant: "inherit",
                }}
              />
            </ListItem>
          </>
        ) : (
          ""
        )}
      </List>
      <Divider />
    </Box>
  );

  return (
    <>
      <IconButton onClick={() => setMenuOpen(true)} css={menuButton}>
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor={"left"}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        {menuList}
      </Drawer>
    </>
  );
};

export default HamburgerMenu;
